import React, {useCallback, useEffect, useState} from "react";

import {apiQuery, apiQueryError} from "../../Utils";
import {
	Breadcrumb,
	Button,
	Col,
	DatePicker,
	Drawer,
	Form,
	Input,
	message,
	Row, Select,
	Space,
	Table,
	Tooltip,
	Typography
} from "antd";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion, faRepeat} from "@fortawesome/free-solid-svg-icons";
import PDFModal from "../PDFModal/PDFModal";
const { Text } = Typography;

const Orders = (params) => {
	const [AISData, setAISData] = useState(false);
	const testInv = params.testInv;
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [ftIds, setFtIds] = useState([]);
	const [filters, setFilters] = useState({
		name: '',
		date: null,
		status: ''
	});
	const [modalFileData, setModalFileData] = useState(false);
	const [modalFileId, setModalFileId] = useState(false);
	const [modalFileVisible, setModalFileVisible] = useState(false);
	const [modalFileUrl, setModalFileUrl] = useState('');

	const aISDataGet = useCallback(() => {

		console.log(filters);

		apiQuery("/admin/getOrders", {
			method: 'POST',
			body: {testInv, ...filters}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)
				setAISData(data);
				const arr = data.map(el=>{return {value:el.data.status,label:el.data.status }})
				const uniqueData = Array.from(new Set( arr.map(el => JSON.stringify(el))))
					.map(el => JSON.parse(el));
				setFtIds(uniqueData)
			})
			.catch((error) => {
				setAISData(false);
				apiQueryError({error});
			})
	}, [testInv, filters])

	const modalFile = ({visible, fileData, fileId, fileUrl}) => {
		console.log(fileUrl)
		if (visible) {
			setModalFileData({...fileData})
			setModalFileId(fileId)
			setModalFileUrl(fileUrl)
		} else {
			setModalFileData(false)
			setModalFileId(false)
		}
		setModalFileVisible(visible)
	}

	const columns = [
		{
			title: ' Клиент',
			key: 'user.aLastName',
			render: (_, record) => (
				<div size="middle">
					{record?.user?.id
						?
						<a href={'/users/'+record.user.id} target="_blank" rel="noreferrer">{record.user.aisData.aLastName} {record.user.aisData.aFirstName} {record.user.aisData.aMiddleName}</a>
						:
						'-'}
				</div>
			),
		},
		{
			title: ' ID',
			dataIndex: 'data.id',
			key: 'id',
			render: (_, record) => (
				<div size="middle">
					{record.data.id}
				</div>
			),
		},
		{
			title: 'Тип',
			dataIndex: 'type',
			key: 'data.type',
			render: (_, record) => (
				<div size="middle">
					{record.data.type === 'limit' ? 'Лимитный' : 'Рыночный'}
				</div>
			),
		},
		{
			title: 'Направление',
			dataIndex: 'side',
			key: 'data.side',
			render: (_, record) => (
				<div size="middle">
					{record.data.side === 'buy' ? 'Покупка' : 'Продажа'}
				</div>
			),
		},
		{
			title: 'Тикер',
			dataIndex: 'symbol',
			key: 'data.symbol',
			render: (_, record) => (
				<div size="middle">
					{record.data.symbol}
				</div>
			),
		},
		{
			title: 'Объем',
			dataIndex: 'amount',
			key: 'amount',
			render: (_, record) => (
				<div size="middle">
					{record.data.type === "market"
						? (record.data.notional ?
								Number(Number(record.data.notional).toFixed(2))
								: (record.data.qty && record.addData.snapshotPrice
										? Number((record.addData.snapshotPrice * record.data.qty).toFixed(2))
										: 0
								)
						): record.data.type === "limit"
							? Number((record.data.limit_price * record.data.qty).toFixed(2)): 0}
				</div>
			),
		},
		{
			title: 'Объем Факт',
			dataIndex: 'amount',
			key: 'amount',
			render: (_, record) => (
				<div size="middle">
					{Number(record.data.filled_qty*record.data.filled_avg_price).toFixed(2)}
				</div>
			),
		},
		{
			title: 'Количество',
			dataIndex: 'qty',
			key: 'qty',
			render: (_, record) => (
				<div size="middle">
					{record.data.type === "market"
						? (record.data?.filled_qty || (record.data?.qty || '-'))
						: record.data.type === "limit"
							? (record.data?.filled_qty || (record.data?.qty || '-')): '-'}
				</div>
			),
		},
		{
			title: 'Количество Факт',
			dataIndex: 'filled_qty',
			key: 'filled_qty',
			render: (_, record) => (
				<div size="middle">
					{record.data?.filled_qty || '-'}
				</div>
			),
		},
		{
			title: 'Комиссия Факт',
			dataIndex: 'commission',
			key: 'commission',
			render: (_, record) => (
				<div size="middle">
					{record.data?.commission || '-'}
				</div>
			),
		},
		{
			title: 'Дата',
			key: 'createdAt',
			dataIndex: 'createdAt',
			render: (_, record) => (
				<div size="middle">
					{moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')}
				</div>
			),
		},
		{
			title: 'Поручение',
			key: 'order',
			dataIndex: 'order',
			render: (_, record) => (
				// <Space size="middle">
				// 	{record?.addData?.fileName ? <a href={record?.addData?.fileName} target="_blank" rel="noreferrer">Скачать</a> : '-'}
				// </Space>
				<Space size="middle">
					{record?.addData?.fileName ? <a onClick={()=>{modalFile({visible: true, fileData: 'asds.pdf', fileId: record._id, fileUrl: record?.addData?.fileName})}}>Открыть</a> : '-'}
				</Space>
			),
		},
		{
			title: 'Статус',
			dataIndex: 'status',
			key: 'data.status',
			// ['new', 'accepted', 'filled', 'partially_filled', 'partial_fill', 'canceled']
			render: (_, record) => (
				<div size="middle">
					{record.data.status}
					{/*{record.data.status === 'new' ? 'Ожидает' :*/}
					{/*	(record.data.status === 'accepted' ? 'Принят' :*/}
					{/*		(record.data.status === 'filled' ? 'Исполнен' :*/}
					{/*			(record.data.status === 'partially_filled' ? 'Частично исполнен' :*/}
					{/*				(record.data.status === 'partial_fill' ? 'Частично исполнен' : 'Отменен'))))}*/}
				</div>
			),
		},
		{
			title: 'Статус АИС',
			key: 'statusAIS',
			render: (_, record) => (
				<>
				<Space size="middle">
					{record.aisData?.deals?.length > 0 ? <Text type="success">Сделки созданы: {record.aisData.deals.join(',')}</Text> : <></>}
					{record.aisData.dealsError ? <>
							<Text type="danger">Ошибка при создании сделки</Text>
							<Tooltip title={record.aisData.dealsError?.msg || (record.aisData.dealsError || '')} placement="leftTop">
								<FontAwesomeIcon icon={faCircleQuestion} />
							</Tooltip>
						</>
						: <></>}
				</Space>
				<Space size="middle">
					{record.aisData.ORDER_ID ? <Text type="success">Ордер создан: {record.aisData.ORDER_ID}</Text> : <></>}
					{record.aisData.error ? <>
							<Text type="danger">Ошибка при создании ордера</Text>
							<Tooltip title={record.aisData.error?.msg || ''} placement="leftTop">
								<FontAwesomeIcon icon={faCircleQuestion} />
							</Tooltip>
						</>
						: <></>}
				</Space>
				<Space size="middle">
					{record.aisData.REJECT_ORDER_ID ? <Text type="success">Ордер отменен ордером: {record.aisData.REJECT_ORDER_ID}</Text> : <></>}
					{record.aisData.rejectOrderError ? <>
							<Text type="danger">Оишбка при создании ордера на отмену</Text>
							<Tooltip title={record.aisData.rejectOrderError.msg} placement="leftTop">
								<FontAwesomeIcon icon={faCircleQuestion} />
							</Tooltip>
						</>
						: <></>}
				</Space>
				</>
			),
		},
	];

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const onClose = () => {
		setDrawerVisible(false);
	};

	useEffect(() => {
		aISDataGet()
	}, [aISDataGet, testInv])

	const handleFilterChange = (value, key) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[key]: value,
		}));
	};

	const handleDateChange = (date, dateString) => {
		handleFilterChange(dateString, 'date');
	};

	const handleStatusChange = (date, dateString) => {
		handleFilterChange(dateString, 'date');
	};


	const clearFilters = () => {
		// Устанавливаем начальные значения для всех фильтров
		setFilters({
			name: '',
			date: null,
			status: undefined
		});

		aISDataGet({
			name: '',
			date: null,
			status: undefined
		});
	};

	const applyFilters = () => {
		aISDataGet();
		onClose();
	};

	useEffect(() => {
		aISDataGet()
	}, [aISDataGet, testInv])
	return (
		<>
			<Row justify="space-between" align="middle" style={{ margin: '16px 0' }}>
				<Col>
					<Breadcrumb style={{margin: '16px 0'}}>
						<Breadcrumb.Item>Ордера</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<Col>
					<Button type="default" onClick={showDrawer}>
						Фильтры
					</Button>
				</Col>
			</Row>
			<div
				style={{
					minHeight: 360,
				}}
			>

				<Drawer
					title="Фильтры"
					width={500}
					onClose={onClose}
					open={drawerVisible}
					bodyStyle={{ paddingBottom: 80 }}
					placement="right"
					footer={
						<Row justify="end" gutter={16}>
							<Col>
								<Button onClick={clearFilters} style={{ marginRight: 8 }}>
									Очистить
								</Button>
							</Col>
							<Col>
								<Button onClick={applyFilters} type="primary">
									Применить
								</Button>
							</Col>
						</Row>
					}
				>
					<Form layout="vertical">
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label="Имя">
									<Input
										placeholder="Введите имя"
										value={filters.name}
										onChange={(e) => handleFilterChange(e.target.value, 'name')}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label="Дата">
									<DatePicker
										style={{ width: "100%" }}
										value={filters.date ? moment(filters.date) : null}
										onChange={handleDateChange}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={24}>
								<Form.Item label="Статус">
									<Select
										placeholder="Выберите статус"
										onChange={value => handleFilterChange(value, 'status')}
										value={filters.status}
										options={ftIds}
									>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Drawer>

				<Table columns={columns} dataSource={AISData} />
				<PDFModal visible={modalFileVisible}
									fileUrl={modalFileUrl}
									fileData={{ext:'pdf'}}
									onClose={() => setModalFileVisible(false)}
				/>
			</div>
		</>
	)
}

export default Orders