import {Tag} from "antd";

const StatusBlock = ({status}) => {

  const style = {
    fontSize: 12,
    lineHeight: '16px'
  }

  if (['done'].includes(status)) return <Tag color="green" style={style}>active</Tag>
  if (['active','completed','clear','not pdl'].includes(status)) return <Tag color="green" style={style}>{status}</Tag>
  if (['pdl'].includes(status)) return <Tag color="orange" style={style}>{status}</Tag>
  if (['inactive','consider','lock'].includes(status)) return <Tag color="red" style={style}>{status}</Tag>
  if (['in progress'].includes(status)) return <Tag color="blue"style={style} >{status}</Tag>
  return <Tag style={style}>{status}</Tag>
}

export default StatusBlock;