import {Button, Input, message} from 'antd';
import {apiQuery, apiQueryError, apiRequest} from "../../Utils";
import {useEffect, useState} from "react";
const { TextArea } = Input;

const NewsTranslate = () => {

    const [headlineRu, setHeadlineRu] = useState('')
    const [authorRu, setAuthorRu] = useState('')
    const [summaryRu, setSummaryRu] = useState('')
    const [contentRu, setContentRu] = useState('')
    const [translated, setTranslated] = useState(0)
    const [notTranslated, setNotTranslated] = useState(0)
    const [translatedByDate, setTranslatedByDate] = useState([])
    const [allNewsByDate, setAllNewsByDate] = useState([])

    const getCounter = () => {
        apiRequest({
            url: "/admin/translate_counter",
            success: (data) => {
               console.log({data})
                if(data?.translated){
                    setTranslated(data?.translated)
                }
                if(data?.not_translated){
                    setNotTranslated(data?.not_translated)
                }
                if(data?.translated_by_date){
                    setTranslatedByDate(data?.translated_by_date)
                }
                if(data?.all_news_by_date){
                    setAllNewsByDate(data?.all_news_by_date)
                }
            },
            error: (error) => {
                console.error(error);
            }
        }).catch();
    }

    useEffect(() => {
        getCounter()
        getNewsTranslate()
    }, [])

    const getNewsTranslate = () => {
        console.log('save')
        apiRequest({
            url: "/admin/news_translate",
            success: (data) => {
                console.log(data, 'data')
                let headlineRu = data?.headlineRu
                let authorRu = data?.authorRu
                let summaryRu = data?.summaryRu
                let contentRu = data?.contentRu
                setHeadlineRu(headlineRu)
                setAuthorRu(authorRu)
                setSummaryRu(summaryRu)
                setContentRu(contentRu)
            },
            error: (error) => {
                console.error(error);
            }
        }).catch();
    }


    const save = () => {
        console.log('save')
        apiRequest({
            url: "/admin/news_translate",
            method: "POST",
            body: {
                headlineRu,
                authorRu,
                summaryRu,
                contentRu,
            },
            success: (data) => {
                console.log(data, 'data')

            },
            error: (error) => {
                console.error(error);
            }
        }).catch();
    }

    return (
        <>

            <div>
                Headline RU
                <TextArea
                    rows={2}
                    onChange={(e) => {
                        let value = e?.target?.value
                        setHeadlineRu(value)
                    }}
                    value={headlineRu}
                />
            </div>
            <div>
                Author RU
                <TextArea rows={1}
                          onChange={(e) => {
                              let value = e?.target?.value
                              setAuthorRu(value)
                          }}
                          value={authorRu}
                />
            </div>
            <div>
                Summary RU
                <TextArea rows={5}
                          onChange={(e) => {
                              let value = e?.target?.value
                              setSummaryRu(value)
                          }}
                          value={summaryRu}
                />
            </div>
            <div>
                Content RU
                <TextArea rows={10}
                          onChange={(e) => {
                              let value = e?.target?.value
                              setContentRu(value)
                          }}
                          value={contentRu}
                />
            </div>
            <div>
                <Button type="default" onClick={save}>
                    Save
                </Button>
            </div>

            <hr></hr>
            <div>Переведенные: {translated}</div>
            <div>Не переведенные: {notTranslated}</div>
            <div>Все:</div>
            <table border={1}>
                <tr>
                    <th>Дата</th>
                    <th>Всего</th>
                    <th>Переведено</th>
                </tr>
                {
                    allNewsByDate.map((item, index) => {
                        let obj = translatedByDate.find(o => o._id === item?._id)
                        console.log({obj})
                        return (
                            <tr key={'all_' + index}>
                                <td>{item?._id}</td>
                                <td>{item?.count}</td>
                                <td>{obj && obj?.count ? obj?.count : 0}</td>
                            </tr>
                        )
                    })
                }
            </table>
        </>
    )
}

export default NewsTranslate