import React from "react";
import {Modal} from "antd";
import Utils from "../../Utils";

import {Viewer, Worker} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function urlOpen(url) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
}

const PDFModal = ({visible, onClose, fileData, fileId, fileUrl=false}) => {

    console.log(fileUrl)

  return (
    <Modal
      open={visible}
      cancelText="Закрыть"
      okVisible={true}
      onCancel={onClose}
      okText={'Скачать файл'}
      onOk={() => {
        urlOpen(fileUrl || `https://${Utils.apiDomain()}/rest_api/admin/${fileData.mode}/${fileId}`);
        onClose()
      }}
    >
      {fileData.ext === 'pdf' && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <div style={{height: 600, marginTop: 30}}>
          <Viewer
            fileUrl={fileUrl || `https://${Utils.apiDomain()}/rest_api/admin/${fileData.mode}/${fileId}`}
            theme={{
              theme: "light",
            }}
            withCredentials={true}
            plugins={[]}
          />
        </div>
      </Worker>}
      {fileData.ext !== 'pdf' && <div style={{marginTop: 30}}>
        <img
          src={fileUrl || `https://${Utils.apiDomain()}/rest_api/admin/${fileData.mode}/${fileId}`}
          alt={''}
          width={'100%'}
        />
      </div>}

    </Modal>
  );
};

export default PDFModal;
