import React, { useEffect, useRef, useState } from "react";
import { AutoSizer, List } from "react-virtualized";
import {Input, Spin, Row, Col, Button, Modal, Form} from "antd";
import ImagePreview from "../ImagePreview";
import tickerList from "./tickers";
import { LoadingOutlined, StarOutlined, StarFilled } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import PortfolioStore from "./PortfolioStore";
import AppStore from "../App/AppStore";
import TickerStore from "./TickerStore";
import styles from './Portfolios.module.scss'

const ButtonAddDel = ({ tickerId, tickers, tickerAdd, tickerDel }) => {
  if (tickers.includes(tickerId))
    return (
      <Button onClick={() => tickerDel(tickerId)} style={{ color: "#00FFFF" }}>
        <StarFilled twoToneColor="#00FFFF" />
      </Button>
    );

  return (
    <Button onClick={() => tickerAdd(tickerId)} style={{ color: "#434343" }}>
      <StarOutlined twoToneColor="#e6f800" />
    </Button>
  );
}

const TickerItem2 = observer(({ tickerId, tickers, tickerAdd, tickerDel }) => {
  const item = TickerStore.tickerData[tickerId];

  useEffect(() => {
    TickerStore.getTicker(tickerId);
  }, [tickerId]);

  if (!item) return null;

  return (
    <Row>
      <Col flex="50px">
        <ImagePreview
          src={item.logo}
          name={item.symbol}
          iconNoPhoto={false}
          style={{ borderRadius: 50, width: 40, height: 40 }}
        />
      </Col>
      <Col flex="auto" className={styles.col_width + " pl-8"}>
        <div className={styles.ticker_desc1}>{item.symbol}</div>
        <div className={styles.ticker_desc}>{item.dataAsset?.name}</div>
      </Col>
      <Col className={styles.btn_star}>
        <ButtonAddDel tickerId={item._id} tickers={tickers} tickerAdd={tickerAdd} tickerDel={tickerDel}/>
      </Col>
    </Row>
  );
});

const ModalTickerAdd = ({tickers, tickerAdd, tickerDel}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [list, setList] = useState([]);
  const timeOutId = useRef();

  console.log({tickers, tickerAdd, tickerDel})

  useEffect(() => {
    if (isModalVisible) PortfolioStore.setFavoritesEdit(false);
  }, [isModalVisible]);

  useEffect(() => {
    setList(tickerList);
    return () => {
      clearTimeout(timeOutId.current);
    };
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase();
    setSearchValue(value);
    clearTimeout(timeOutId.current);
    timeOutId.current = setTimeout(() => {
      if (value === "") {
        return setList(tickerList);
      }
      let list_ = [];

      for (let item of tickerList) {
        if (!item) continue;
        if (item.symbol.indexOf(value) === -1) continue;
        list_.push(item);
      }
      setList(list_);
    }, 1000);
  };

  const rowRenderer = ({ index, key, style }) => {
    return (
      <div key={key} style={style} className={styles.pm_ticker_item}>
        <TickerItem2 tickerId={list[index]._id} tickers={tickers} tickerAdd={tickerAdd} tickerDel={tickerDel}/>
      </div>
    );
  };

  return (
    <>
      <Form.Item>
        <Button type="default" size={"small"} onClick={showModal}>
          Добавить символ
        </Button>
      </Form.Item>

      <Modal
        title="Добавить символ"
        open={isModalVisible}
        onCancel={handleCancel}
      >
        <div className="p-16">
          <Input
            placeholder="Поиск акции"
            className="normal_input"
            value={searchValue}
            onChange={handleChange}
          />

          <div style={{ height: AppStore.height - 202, marginTop: 20 }}>
            {isModalVisible && (
              <AutoSizer>
                {({ width }) => (
                  <List
                    className={styles.list_bl}
                    width={width}
                    height={AppStore.height - 202}
                    rowCount={list.length}
                    rowHeight={50}
                    rowRenderer={rowRenderer}
                  />
                )}
              </AutoSizer>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalTickerAdd;
