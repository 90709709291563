import {Button, Modal, Table, Tag} from "antd";
import {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Spin} from "antd/lib";
import {apiQuery, apiQueryError} from "../../Utils";
import moment from "moment";

const columns = [
  {
    title: "Менеджер",
    dataIndex: "email",
    // key: "email",
    render: (text, record, index) => {
      return (
        <a href={`/users/${record?.managerId.id}`} key={index} target={'_blank'} rel="noreferrer">
          <div style={{color: 'rgb(215, 215, 215)'}}>{record?.managerId.profileData.name}
            {' '}
            {record?.managerId.mode === 'live' && <Tag color="green" style={{fontSize: 10, height:18, padding: '2px 4px', lineHeight: '11px'}}>live</Tag>}
            {record?.managerId.mode === 'sandbox' && <Tag color="gold" style={{fontSize: 10, height:18, padding: '2px 4px', lineHeight: '11px'}}>sandbox</Tag>}
          </div>
          <div  style={{fontSize: 12, color: 'rgb(215, 215, 215)'}}>{record?.managerId.email}</div>
          <div style={{fontSize: 10, color: 'rgb(136, 136, 136)'}}>ID: {record?.managerId.id} | _ID: {record?.managerId._id}</div>
        </a>
      );
    },
    // sorter: true,
  },
  // {
  //   title: "Дата",
  //   dataIndex: "createdAt",
  //   key: "createdAt",
  //   render: (text, record, index) => {
  //     return <span>{moment(record.createdAt).format("DD.MM.YYYY")}</span>;
  //   },
  //   sorter: true,
  // },
  {
    title: "Изменения",
    dataIndex: "data",
    key: "data",
    render: (text, record, index) => {
      return <>
        {Object.keys(record.data).map((key, index) => <div key={index} style={{borderBottom: '1px dashed #333'}}>
          <div style={{fontSize: 10, color: '#999'}}>Дата: {moment(record.createdAt).format("DD.MM.YYYY HH:mm:ss")}</div>
          <div>[{key}] {record.data[key].textOld} -> {record.data[key].text}</div>
          <div><div style={{fontSize: 10, color: '#999'}}>Комментарий:</div> {record.data[key].comment}</div>
        </div>)}
      </>;
    },
    sorter: false,
  }
];

const BrokerHistory = ({userData}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [items, setItems] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const traderDataHistory = useCallback((data) => {
    setLoad(true)
    data.userId = userData._id;
    apiQuery("/admin/traderDataHistory", {
      method: "POST",
      body: data,
    })
      .then((data) => {
        setItems(data.items);
        setItemsCount(data.itemsCount);
        setLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        setLoad(false)
      });
  }, [userData])

  useEffect(() => {
    if (isModalOpen) {
      traderDataHistory({})
    } else {
      setLoad(true)
    }
  }, [isModalOpen, traderDataHistory])

  if (!userData) return;

  return (
    <>
      <Button type="primary" onClick={showModal}>
        История изменений
      </Button>
      <Modal title="История изменений"
             open={isModalOpen}
             onCancel={handleCancel}
             footer={''}
      >
        {load && <Spin/>}
        {!load && <Table
            rowKey={'_id'}
            size="small"
            dataSource={items}
            columns={columns}
            onChange={(page, filters, sorter, extra) => {
              traderDataHistory({page, filters, sorter, extra});
            }}
            pagination={{
              pageSize,
              showSizeChanger: true,
              onShowSizeChange: (current, ps) => setPageSize(ps),
              total: itemsCount,
            }}
          />
        }
      </Modal>
    </>
  );
}

export default observer(BrokerHistory);