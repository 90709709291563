import {Link, useParams} from "react-router-dom";
import {apiQuery, apiQueryError} from "../../Utils";
import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form, message, Row} from "antd";
import Input from "antd/es/input/Input";
import {useForm} from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import Upload from "antd/es/upload/Upload";
import {PlusOutlined} from "@ant-design/icons";

const Ticker = () => {

  const [form] = useForm();
  const params = useParams();
  const tickerId = params.tickerId ? params.tickerId : null;
  const [data, setData] = useState(null);
  const [fileList, setFileList] = useState([]);

  const onChangeFile      = ({fileList: newFileList}) => {
    setFileList(newFileList);
  };

  const onFinish = (values) => {
    values.logo = fileList[0] ? fileList[0]?.thumbUrl || null : null;
    console.log(values)
    apiQuery("/admin/ticker/"+tickerId, {
      method: "POST",
      body  : values,
    })
        .then((data) => {
          console.log(data);
          message.success("Сохранено");
        })
        .catch((error) => {
          apiQueryError({error});
        });
  }

  useEffect(() => {
    apiQuery('/admin/ticker/' + tickerId).then((data) => {
      console.log(data)
      setData(data)
      form.setFieldsValue({symbol: data.symbol})
      form.setFieldsValue({name: data.dataAsset.name})
      form.setFieldsValue({description: data.description})
      setFileList([{url:data.logo}])
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [tickerId, form])

  const getFormattedData = () => {
    if (!data) {
      return '{}';
    }
    const { dataAsset, createdAt, updatedAt } = data;
    const formattedData = { dataAsset, updatedAt };
    return JSON.stringify(formattedData, null, 2);
  };

  if (!data) return <p>Загрузка...</p>

  return (
      <>
        <Breadcrumb style={{margin: '16px 0'}}>
          <Breadcrumb.Item><Link to={'/tickers'}>Тикеры</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{data.symbol} ({data.dataAsset.name})</Breadcrumb.Item>
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{
            minHeight: 360,
          }}
        >

          <Form
            form={form}
            name="basic"
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            onFinish={onFinish}
          >
            <Form.Item
              label="Логотип"
              name="logo"
            >
              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={onChangeFile}
                // onPreview={onPreview}
              >
                {fileList.length === 0 && (
                  <div>
                    <PlusOutlined/>
                    <div>
                      Загрузить
                      <br/> фото
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>

            <Form.Item
              label="Тикер"
              name="symbol"
            >
              <Input disabled={true}/>
            </Form.Item>

            <Form.Item
              label="Наименование компании"
              name="name"
            >
              <Input disabled={true}/>
            </Form.Item>

            <Form.Item
              label="Описание компании"
              name="description"
            >
              <TextArea rows={8}/>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 18,
              }}
            >
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>

          <Row>
            <Col>
              <pre>
                {getFormattedData()}
              </pre>
            </Col>
          </Row>


        </div>
      </>
  )

}

export default Ticker;