import {Breadcrumb, Table, Tabs, Input, Space, Select, Tag, Radio} from "antd";
import {useCallback, useEffect, useRef, useState} from "react";
import {apiQuery, apiQueryError} from "../../Utils";
import {Link} from "react-router-dom";
import moment from "moment";
import StatusBlock from "./StatusBlock";

const {TabPane} = Tabs;
const {Search} = Input;

const columns = [
  {
    title: "",
    dataIndex: "email",
    // key: "email",
    render: (text, record, index) => {
      return (
        <Link to={`/users/${record.id}`} key={index}>
          <div style={{color: 'rgb(215, 215, 215)'}}>{record.profileData.name}
            {' '}
            {record.mode === 'live' && <Tag color="green" style={{fontSize: 10, height:18, padding: '2px 4px', lineHeight: '11px'}}>live</Tag>}
            {record.mode === 'sandbox' && <Tag color="gold" style={{fontSize: 10, height:18, padding: '2px 4px', lineHeight: '11px'}}>sandbox</Tag>}
          </div>
          <div style={{fontSize: 12, color: 'rgb(215, 215, 215)'}}>{record.email}</div>
          <div style={{fontSize: 10, color: 'rgb(136, 136, 136)'}}>ID: {record.id} | _ID: {record._id}</div>
        </Link>
      );
    },
    // sorter: true,
  },
  {
    title: "Creation date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record, index) => {
      return <span>{moment(record.createdAt).format("DD.MM.YYYY")}
        <br/>
        {moment(record.createdAt).format("HH:mm:ss")}
      </span>;
    },
    sorter: true,
  },
  {
    title: "Sumsub",
    dataIndex: "sumsub",
    key: "statusData.sumsub",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.sumsub ? 'not started' : record?.statusData.sumsub}/>;
    },
    sorter: true,
  },
  {
    title: "KYC",
    dataIndex: "kyc",
    key: "statusData.kyc",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.kyc ? 'not started' : record?.statusData.kyc}/>;
    },
    sorter: true,
  },
  {
    title: "PEP",
    dataIndex: "pdl",
    key: "statusData.pdl",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.pdl ? 'no data' : record?.statusData.pdl}/>;
    },
    sorter: true,
  },
  {
    title: "Broker form",
    dataIndex: "brokerForm",
    key: "statusData.brokerForm",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.brokerForm ? 'not started' : record?.statusData.brokerForm}/>;
    },
    sorter: true,
  },
  {
    title: "Geeko trader",
    dataIndex: "trader",
    key: "statusData.trader",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.trader ? 'not created' : record?.statusData.trader}/>;
    },
    sorter: true,
  },
  {
    title: "Trading account",
    dataIndex: "alpaca",
    key: "statusData.alpaca",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.alpaca ? 'not created' : record?.statusData.alpaca}/>;
    },
    sorter: true,
  },
  {
    title: "Broker account",
    dataIndex: "ais",
    key: "aisData",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.ais ? 'not created' : record?.statusData.ais}/>;
    },
    sorter: true,
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    render: (text, record, index) => {
      return <>
        {record.role && Object.keys(record.role).map((key) => {
          if (!record.role[key]) return null;
          return <StatusBlock status={key}/>
        })}
      </>;
    },
    sorter: true,
  },
];

const UserList = (params) => {
  const testInv = params.testInv;
  const [items, setItems] = useState({list: [], sumsub: [], kyc: [], pdl: [], brokerForm: [], trader: [], alpaca: [], ais: []});
  const [itemsCount, setItemsCount] = useState({list: 0, sumsub: 0, kyc: 0, pdl: 0, brokerForm: 0, trader: 0, alpaca: 0, ais: 0});
  const [itemsPage, setItemsPage] = useState({list: 1, sumsub: 1, kyc: 1, pdl: 1, brokerForm: 1, trader: 1, alpaca: 1, ais: 1});
  const [pageSize, setPageSize] = useState(10);
  const [filterMode, setFilterMode] = useState('email&name');
  const [filterValue, setFilterValue] = useState('');
  const filterTab = useRef({list: null, sumsub: null, kyc: null, pdl: null, brokerForm: null, trader: null, alpaca: null, ais: null});
  const t = useRef()
  const [usersFull, setUsersFull] = useState(0);
  const [usersNew, setUsersNew] = useState(0);

  const usersGet = useCallback((data = {}) => {

    console.log(data)

    clearTimeout(t.current)
    t.current = setTimeout(() => {
      const tab = data.tab;
      data.filterMode = filterMode;
      data.filterValue = filterValue;
      data.mode = testInv ?'sandbox' : 'live';
      data.filterTab = filterTab.current[tab]
      apiQuery("/admin/users", {
        method: "POST",
        body: data,
      })
        .then((data) => {
          console.log(data);
          console.log(data.userFull);
          setUsersFull(data.usersFull)
          setUsersNew(data.usersNew)
          setItems((v) => {
            const d = {...v}
            d[tab] = data.items;
            return d
          });
          setItemsCount((v) => {
            const d = {...v}
            d[tab] = data.itemsCount;
            console.log(d)
            return d
          });
        })
        .catch((error) => {
          apiQueryError({error});
        });
    }, 500)

  }, [filterMode, filterValue, testInv, filterTab]);

  const onChange = (tab) => {
    usersGet({tab, page: {
      current: 1,
      pageSize
    }})
  }

  const onChangeFilter = (tab, val) => {
    setItemsPage(v => {
      const d = {...v}
      d[tab] = 1;
      return d
    })
    filterTab.current[tab] = val;
    usersGet({tab, page: {
      current: 1,
      pageSize
    }})
  }

  const onSearch = () => {
    usersGet({tab: 'list'})
  }

  useEffect(() => {
    usersGet({tab: 'list'});
  }, [usersGet, testInv]);

  if (!items) return <>Загрузка...</>;

  return (
    <>
      <Breadcrumb style={{margin: "16px 0"}} items={[
        {title: 'Пользователи'}
      ]}/>
      <Tabs defaultActiveKey="list" onChange={onChange}>
        <TabPane tab={<>User List <Tag color="blue">{filterTab.current.list === 'newUsers' ? usersNew : usersFull}</Tag></>} key="list">
          <div className="site-layout-background" style={{minHeight: 360}}>
            <Space>
              <Space.Compact style={{marginBottom: 15}}>
                <Select value={filterMode}
                        onChange={(v) => {
                          setFilterMode(v)
                          usersGet({tab:'list'})
                        }}
                        options={[
                          {
                            value: 'email&name',
                            label: 'Email и ФИО',
                          },
                          {
                            value: 'id',
                            label: 'ID пользователя',
                          },
                          {
                            value: '_id',
                            label: 'SystemID пользователя',
                          },
                          {
                            value: 'alpacaId',
                            label: 'AlpacaID пользователя',
                          },
                          {
                            value: 'iin',
                            label: 'ИИН пользователя',
                          },
                        ]}/>
                <Search
                  placeholder="input search text"
                  allowClear
                  value={filterValue}
                  onChange={(v) => {
                    setFilterValue(v.target.value)
                  }}
                  onSearch={onSearch}
                  style={{
                    width: 304,
                  }}
                />
              </Space.Compact>

              <Radio.Group options={[
                {value: null, label: 'All users'},
                {value: 'newUsers', label: 'New users'},
              ]}
                           onChange={(e) => {onChangeFilter('list', e.target.value)}} value={filterTab.current.list}
                           style={{marginBottom: 15}}
              />
            </Space>

            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items.list}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                usersGet({page, filters, sorter, extra, tab: 'list'});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount.list,
              }}
            />
          </div>
        </TabPane>
        <TabPane tab="Sumsub" key="sumsub">
          <div className="site-layout-background" style={{minHeight: 360}}>

            <Select onChange={(e) => {onChangeFilter('sumsub', e)}}
                    value={filterTab.current.sumsub}
                    style={{marginBottom: 15, minWidth: '50%'}}
            >
              <Select.Option value={null}>Все</Select.Option>
              <Select.Option value={'not started'}>not started</Select.Option>
              <Select.Option value={'applicantReviewed'}>applicantReviewed: Проверка завершена. Содержит результат проверки.</Select.Option>
              <Select.Option value={'applicantPending'}>applicantPending: Заявитель загрузил все необходимые документы и ожидает.</Select.Option>
              <Select.Option value={'applicantCreated'}>applicantCreated: Заявитель создан.</Select.Option>
              <Select.Option value={'applicantOnHold'}>applicantOnHold: Обработка заявки приостановлена по согласованной причине.</Select.Option>
              <Select.Option value={'applicantPersonalInfoChanged'}>applicantPersonalInfoChanged: Личная информация заявителя была изменена.</Select.Option>
              <Select.Option value={'applicantPrechecked'}>applicantPrechecked: Первичная обработка данных завершена.</Select.Option>
              <Select.Option value={'applicantDeleted'}>applicantDeleted: Заявитель был удален безвозвратно.</Select.Option>
              <Select.Option value={'applicantLevelChanged'}>applicantLevelChanged: Уровень заявителя изменен.</Select.Option>
              <Select.Option value={'videoIdentStatusChanged'}>videoIdentStatusChanged: Изменен статус проверки типа видеоидентификации.</Select.Option>
              <Select.Option value={'applicantReset'}>applicantReset: Заявитель был сброшен: статус заявителя изменен на инициализированный, и все документы были установлены как неактивные.</Select.Option>
              <Select.Option value={'applicantActionPending'}>applicantActionPending: Статус действия заявителя изменен на ожидающий рассмотрения.</Select.Option>
              <Select.Option value={'applicantActionReviewed'}>applicantActionReviewed: Проверка действий заявителя завершена.</Select.Option>
              <Select.Option value={'applicantActionOnHold'}>applicantActionOnHold: Проверка действий заявителя была приостановлена по согласованной причине.</Select.Option>
              <Select.Option value={'applicantWorkflowCompleted'}>applicantWorkflowCompleted:	Рабочий процесс для заявителя завершен.</Select.Option>
            </Select>

            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items.sumsub}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                usersGet({page, filters, sorter, extra, tab: 'sumsub'});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount.sumsub,
              }}
            />
          </div>
        </TabPane>
        <TabPane tab={<>KYC <Tag color="blue">{usersNew}</Tag></>} key="kyc">
          <div className="site-layout-background" style={{minHeight: 360}}>
            <Radio.Group options={[
              {value: null, label: 'Все'},
              {value: 'not started', label: 'not started'},
              {value: 'clear', label: 'clear'},
              {value: 'consider', label: 'consider'},
              {value: 'editing', label: 'editing'},
            ]}
                         onChange={(e) => {onChangeFilter('kyc', e.target.value)}} value={filterTab.current.kyc}
                         style={{marginBottom: 15}}
            />
            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items.kyc}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                usersGet({page, filters, sorter, extra, tab: 'kyc'});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount.kyc,
              }}
            />
          </div>
        </TabPane>
        <TabPane tab="PEP" key="pdl">
          <div className="site-layout-background" style={{minHeight: 360}}>
            <Radio.Group options={[
              {value: null, label: 'Все'},
              {value: 'no data', label: 'no data'},
              {value: 'pep', label: 'pep'},
              {value: 'not pep', label: 'not pep'},
            ]}
                         onChange={(e) => {onChangeFilter('pdl', e.target.value)}} value={filterTab.current.pdl}
                         style={{marginBottom: 15}}
            />
            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items.pdl}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                usersGet({page, filters, sorter, extra, tab: 'pdl'});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount.pdl,
              }}
            />
          </div>
        </TabPane>
        <TabPane tab="Broker form" key="brokerForm">
          <div className="site-layout-background" style={{minHeight: 360}}>
            <Radio.Group options={[
                            {value: null, label: 'Все'},
                            {value: 'not started', label: 'not started'},
                            {value: 'in progress', label: 'in progress'},
                            {value: 'completed', label: 'completed'},
                          ]}
                         onChange={(e) => {onChangeFilter('brokerForm', e.target.value)}} value={filterTab.current.brokerForm}
                         style={{marginBottom: 15}}
            />
            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items.brokerForm}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                setItemsPage(v => {
                  const d = {...v}
                  d.brokerForm = page.current;
                  console.log(d)
                  return d
                })
                usersGet({page, filters, sorter, extra, tab: 'brokerForm'});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount.brokerForm,
                current: itemsPage.brokerForm
              }}
            />
          </div>
        </TabPane>
        <TabPane tab="Geeko trader" key="trader">
          <div className="site-layout-background" style={{minHeight: 360}}>
            <Radio.Group options={[
                  {value: null, label: 'Все'},
                  {value: 'not created', label: 'not created'},
                  {value: 'active', label: 'active'},
                  {value: 'inactive', label: 'inactive'},
                  {value: 'blocked', label: 'blocked'},
                ]}
                 onChange={(e) => {onChangeFilter('trader', e.target.value)}} value={filterTab.current.trader}
                 style={{marginBottom: 15}}
            />
            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items.trader}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                setItemsPage(v => {
                  const d = {...v}
                  d.trader = page.current;
                  console.log(d)
                  return d
                })
                usersGet({page, filters, sorter, extra, tab: 'trader'});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount.trader,
                current: itemsPage.trader
              }}
            />
          </div>
        </TabPane>
        <TabPane tab="Trading account" key="alpaca">
          <div className="site-layout-background" style={{minHeight: 360}}>
            <Radio.Group options={[
              {value: null, label: 'Все'},
              {value: 'not created', label: 'not created'},
              {value: 'active', label: 'active'},
              {value: 'inactive', label: 'inactive'},
              {value: 'onboarding', label: 'onboarding'},
              {value: 'submitted', label: 'submitted'},
              {value: 'submission_failed', label: 'submission_failed'},
              {value: 'action_required', label: 'action_required'},
              {value: 'account_updated', label: 'account_updated'},
              {value: 'approval_pending', label: 'approval_pending'},
              {value: 'approved', label: 'approved'},
              {value: 'rejected', label: 'rejected'},
              {value: 'account_closed', label: 'account_closed'},
            ]}
                         onChange={(e) => {onChangeFilter('alpaca', e.target.value)}} value={filterTab.current.alpaca}
                         style={{marginBottom: 15}}
            />
            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items.alpaca}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                setItemsPage(v => {
                  const d = {...v}
                  d.alpaca = page.current;
                  console.log(d)
                  return d
                })
                usersGet({page, filters, sorter, extra, tab: 'alpaca'});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount.alpaca,
                current: itemsPage.alpaca
              }}
            />
          </div>
        </TabPane>
        <TabPane tab="Broker account" key="ais">
          <div className="site-layout-background" style={{minHeight: 360}}>
            <Radio.Group options={[
              {value: null, label: 'Все'},
              {value: 'not created', label: 'not created'},
              {value: 'done', label: 'active'},
              // {value: 'inactive', label: 'inactive'},
            ]}
                         onChange={(e) => {onChangeFilter('ais', e.target.value)}} value={filterTab.current.ais}
                         style={{marginBottom: 15}}
            />
            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items.ais}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                setItemsPage(v => {
                  const d = {...v}
                  d.ais = page.current;
                  console.log(d)
                  return d
                })
                usersGet({page, filters, sorter, extra, tab: 'ais'});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount.ais,
                current: itemsPage.ais
              }}
            />
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default UserList;
