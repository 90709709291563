import {useCallback, useEffect, useMemo, useState} from "react";
import {
  Alert, Badge,
  Breadcrumb,
  Button, Card,
  Checkbox,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Form,
  Input, message,
  Modal,
  Row,
  Select, Space,
  Spin, Switch,
  Table,
  Tabs, Tag, TreeSelect, Upload, Tooltip
} from "antd";
import {CaretRightOutlined, PlusOutlined, WarningOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faXmark, faCircle, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import {Link, useNavigate, useParams} from "react-router-dom";
import Utils, {apiDomain, apiQuery, apiQueryError} from "../../Utils";
import ImagePreview from "../ImagePreview";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import {observer} from "mobx-react-lite";
import AlpacaStore from "./AlpacaStore";
import StatusBlock from "./StatusBlock";
import FileIcon from "../ImagePreview/FileIcon";
import userStore from "../Auth/userStore";
import ConfirmPass from "../ConfirmPass/ConfirmPass";
import ConfirmPass2 from "../ConfirmPass/ConfirmPass2";
import BrokerFormEdit from "./BrokerFormEdit";
import BrokerHistory from "./BrokerHistory";
import PDFModal from "../PDFModal/PDFModal";
import ActionsHistory from "../ConfirmPass/ActionsHistory";
import ConfirmPassSwitch from "../ConfirmPass/ConfirmPassSwitch";

const {Option} = Select;
const { TextArea } = Input;

const typeData = {
  applicantReviewed: "Проверка завершена. Содержит результат проверки.",
  applicantPending: "Заявитель загрузил все необходимые документы и ожидает.",
  applicantCreated: "Заявитель создан.",
  applicantOnHold: "Обработка заявки приостановлена по согласованной причине.",
  applicantPersonalInfoChanged: "Личная информация заявителя была изменена.",
  applicantPrechecked: "Первичная обработка данных завершена.",
  applicantDeleted: "Заявитель был удален безвозвратно.",
  videoIdentStatusChanged: "Изменен статус проверки типа видеоидентификации.",
  applicantReset:
    "Заявитель был сброшен: статус заявителя изменен на инициализированный, и все документы были установлены как неактивные.",
  applicantActionPending:
    "Статус действия заявителя изменен на ожидающий рассмотрения.",
  applicantActionReviewed: "Проверка действий заявителя завершена.",
  applicantActionOnHold:
    "Проверка действий заявителя была приостановлена по согласованной причине.",
};

const ratingBalls = {
  investmentExp : {'Нет': 0, 'Менее 1 года': 1, '1-5 лет': 2, 'Более 5 лет': 3},
  digitalAssets : {'Нет': 0, 'Менее 1 года': 1, '1-5 лет': 2, 'Более 5 лет': 3},
  uifs : {'Нет': 0, 'Менее 1 года': 1, '1-5 лет': 2, 'Более 5 лет': 3},
  products : {'Нет': 0, 'Менее 1 года': 1, '1-5 лет': 2, 'Более 5 лет': 3},
  investmentServices : {'Прочее': 0, 'Депозиты': 1, 'Брокерское обслуживание': 2, 'Доверительное управление': 3},
  firstThought : {
    'Я беспокоюсь, что могу остаться ни с чем': 0,
    'Я понимаю, что это неотъемлемая часть процесса инвестирования': 1,
    'Я вижу возможность получить большие доходы': 2,
    'Я думаю о том, как захватывающе инвестировать': 3
  },
  numDealsYear : {'0-50': 1, '51-100': 2, '101-500': 3, '>500': 4},
  eduFinancialServices : {
    'Нет': 0,
    'Да, высшее образование в области финансов или экономики': 2,
    'Да, сдал профессиональный экзамен или прошел профессиональные курсы (экзамен на брокера, профессиональное образование для регистрации инвестиционного брокера, связанного агента и т.д.)': 3,
  },
  expFinancialServices : {'Прочее': 1, 'Нет': 0, 'Брокерская компания': 3, 'Банковская сфера': 2},
  investmentKnowledge : {
    'Я очень мало знаю об инвестициях и финансовых рынках': 0,
    'Я обладаю средним уровнем знаний об инвестициях и финансовых рынках': 1,
    'Я обладаю обширными знаниями в области инвестиций, разбираюсь в различных инвестиционных продуктах и внимательно слежу за финансовыми рынками': 3,
  }
}

const RatingBallsStatus = ({ball}) => {
  let color = 'green';
  let text = 'Низкий / Консервативный риск-профиль';
  let title = 'Для меня приоритетом является сохранение капитала и некоторая маржинальная прибыль, и я готов(-а) принять низкие риски. Взамен я понимаю, что буду получать низкую доходность. Капитальная стоимость инвестиционных продуктов, потенциально подходящих для такого инвестора, может упасть ниже первоначальных вложений. Ожидается, что в нормальных рыночных условиях колебания будут минимальными (хотя это и не гарантируется), и я спокойно отношусь к такому уровню колебаний.';
  if (ball >= 12) {
    color = 'gold';
    text = 'Средний / Умеренный риск-профиль';
    title = 'Я хочу получить потенциал умеренного роста капитала и готов(-а) принять средние риски (при нормальных рыночных условиях). Я понимаю, что должен(-на) принимать умеренный риск на вложенный капитал.';
  }
  if (ball >= 18) {
    color = 'red';
    text = 'Высокий / Агрессивный риск-профиль';
    title = 'Я готов(-а) принять на себя очень высокие риски, чтобы максимизировать потенциальный доход. Я понимаю, что могу потерять значительную часть или весь мой капитал. От меня даже могут потребовать возместить убытки сверх моего капитала. Я понимаю, что стоимость моих инвестиций может упасть до нуля.';
  }
  return <Tooltip title={title}><Tag color={color}>{text} [ {ball} ]</Tag></Tooltip>
}

const RatingBallStatus = ({section, value}) => {
  const ball = ratingBalls[section][value] || 0;
  let color = 'blue';
  return <Tag color={color} style={{marginLeft: 5}}>{ball}</Tag>
}

const columns = [
  {
    title: "Дата",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record, index) => {
      return (
        <>
          <span style={{fontSize: 12}}>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
        </>
      );
    },
  },
  {
    title: "Тип",
    dataIndex: "type",
    key: "type",
    render: (text, record, index) => {
      return (
        <>
          <div>{record.data.type}</div>
          <span style={{fontSize: 12}}>{typeData[record.data.type]}</span>
        </>
      );
    },
  },
  {
    title: "Статус",
    dataIndex: "reviewStatus",
    key: "reviewStatus",
    render: (text, record, index) => {
      return <>{record.data.reviewStatus}</>;
    },
  },
  {
    title: "Результат",
    dataIndex: "reviewResult",
    key: "reviewResult",
    render: (text, record, index) => {
      return <>{JSON.stringify(record.data.reviewResult)}</>;
    },
  },
];

const uploadAlpaca = {
  'noStatus': <></>,
  'loaded': <Tag color="green">Загружен</Tag>,
  'notLoaded': <Tag color="orange">Не загружен</Tag>,
}

const DocumentFileUpload = ({userData, documentsGet}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const beforeUpload = async (file, fileList) => {
    try {
      fileList[0].base64File = await convertFileToBase64(file);
      setFileList(fileList)
    } catch (error) {
      console.error('Ошибка при загрузке файла:', error);
      message.error('Ошибка при загрузке файла');
    }

    return false; // Отменяем стандартную загрузку, так как мы обработали ее сами
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit()
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    values.file = fileList[0] ? fileList[0]?.base64File || null : null;
    values.userId = userData._id;
    apiQuery("/admin/uploadDopFile", {
      method: "POST",
      body  : values,
    })
      .then((data) => {
        if (data?.error) return message.error(data?.error.msg)
        message.success("Сохранено");
        setIsModalOpen(false);
        documentsGet({page: 1})
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }

  useEffect(() => {
    form.resetFields()
  }, [isModalOpen])

  return (
    <>
      <Button onClick={showModal} style={{marginBottom: 15}}>
        Загрузить документ
      </Button>
      <Modal title="Загрузить документ" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            marginTop: 20
          }}
        >
          <Form.Item label="Файл" name={'file'}
                     rules={[
                       {
                         required: true,
                         message: 'Прикрепите файл',
                       },
                     ]}>
            <Upload
              accept="application/pdf, image/png, image/jpeg"
              maxCount={1}
              listType="picture-card"
              fileList={fileList}
              beforeUpload={beforeUpload}
              onRemove={()=>setFileList([])}
            >
              {fileList.length === 0 && (
                <div>
                  <PlusOutlined/>
                  <div>
                    Загрузить
                    <br/> файл
                  </div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item label="Тип файла" name={'docType'}
                     rules={[
                       {
                         required: true,
                         message: 'Выберите тип файла',
                       },
                     ]}>
            <Select>
              <Select.Option value="ID_CARD">ID_CARD (Удостоверение личности)</Select.Option>
              <Select.Option value="PASSPORT">PASSPORT</Select.Option>
              <Select.Option value="SELFIE">SELFIE</Select.Option>
              <Select.Option value="w8ben">w8ben</Select.Option>
              <Select.Option value="address_verification">address_verification</Select.Option>
              <Select.Option value="FILE">FILE</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Комментарий" name={'docComment'}>
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

const InputComment = observer(({k, comment, commentValue}) => {
  if (!!comment[k])
    return <Form.Item label={'Comment'} name={`comments[${k}]`} rules={[{required: true}]}
                      initialValue={commentValue && commentValue[k] ? commentValue[k] : ''}
    >
      <Input/>
    </Form.Item>
  return null;
})

const ModalAlpacaUserProfile = observer(({
                                           userData, isReg, title = '', cb = () => {
  }
                                         }) => {

  const [form2] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const data = AlpacaStore.data;
  const [comment, setComment] = useState({});

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // setIsModalVisible(false);
    form2.submit()
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const alpacaUserProfile = useCallback(() => {
    setComment({})

    AlpacaStore.alpacaUserProfile({
      userId: userData._id, cb: (data) => {
        form2.setFieldValue('enabled_assets', data?.data?.enabled_assets);

        for (let key of Object.keys(data.data.contact)) {
          const d = {};
          if (key === 'street_address')
            d[`contact.${key}`] = data.data.contact[key][0];
          else
            d[`contact.${key}`] = data.data.contact[key];
          form2.setFieldsValue(d)
        }

        for (let key of Object.keys(data.data.identity)) {
          const d = {};
          if (key === 'funding_source')
            d[`identity.${key}`] = data.data.identity[key][0];
          else
            d[`identity.${key}`] = data.data.identity[key];
          form2.setFieldsValue(d)
        }

        for (let key of Object.keys(data.data.disclosures)) {
          const d = {};
          if (key === 'employment_status')
            d[`disclosures.${key}`] = data.data.disclosures[key].toLowerCase();
          else
            d[`disclosures.${key}`] = data.data.disclosures[key];
          form2.setFieldsValue(d)
        }
      }
    })
  }, [userData, form2])

  const validateASCII32_126 = (_, value) => {
    const regex = /^(?:(?!["';<=>\\])[\x20-\x7E])+$/;

    if (!regex.test(value)) {
      return Promise.reject('Только латинские символы (ASCII 32-126).');
    }

    return Promise.resolve();
  };

  const onFinish = (values) => {
    values.userId = userData._id;
    console.log(values)

    apiQuery("/admin/alpacaUserProfileSave", {
      method: 'POST',
      body: values,
    })
      .then((data) => {
        if (data.error) return message.error(data.error.msg + (data.error?.errorText ? ` [${data.error?.errorText}]`:''))
        setIsModalVisible(false);
        message.success('Запрос отправлен')
        alpacaUserProfile()
        cb()
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }

  const inputOnChange = (val, s) => {

    let valOld = null;
    for (let d of s.split('.')) {
      if (!valOld)
        valOld = data.data[d];
      else {
        if (d === 'funding_source' || d === 'street_address') {
          console.log({d, valOld})
          valOld = valOld[d][0] ? valOld[d][0] : valOld[d];
          continue;
        }
        if (d === 'employment_status') {
          valOld = valOld[d].toLowerCase();
          continue;
        }
        valOld = valOld[d];
      }
    }
    const v = {};
    v[s] = val !== valOld;
    setComment({...comment, ...v})
  }

  useEffect(() => {
    if (!isModalVisible) return;
    alpacaUserProfile()
  }, [isModalVisible, alpacaUserProfile])

  return <>
    <Button type="primary" onClick={showModal}>
      {title}
    </Button>
    <Modal title={title}
           visible={isModalVisible}
           okText={'Сохранить'}
           onOk={handleOk}
           onCancel={handleCancel}
           width={800}
           footer={<>
             <Button onClick={handleCancel}>Закрыть</Button>
             <ConfirmPass btnText={'Сохранить'} onFinish={() => form2.submit()}/>
           </>}
    >
      {data === null && <>Loading...</>}
      {data === false && <>Нет данных</>}
      {data && <Form
        form={form2}
        // layout="vertical"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
      >
        <Form.Item name="enabled_assets"
                   label={'enabled_assets'}
                   rules={[{required: true}]}
        >
          <Select mode={"multiple"} onChange={(e) => inputOnChange(e, "enabled_assets")}>
            <Option value={'us_equity'}>us_equity</Option>
            {/*<Option value={'crypto'}>crypto</Option>*/}
          </Select>
        </Form.Item>
        <InputComment k={"identity.funding_source"} comment={comment}/>

        <Collapse
          bordered={false}
          defaultActiveKey={['1', '2', '3']}
          expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
        >
          <Collapse.Panel key={'1'} header={'Contact'}>

            <Form.Item name="contact.email_address"
                       label={'email_address'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "contact.email_address")}/>
            </Form.Item>
            <InputComment k={'contact.email_address'} comment={comment}/>
            <Divider/>

            <Form.Item name="contact.phone_number"
                       label={'phone_number'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "contact.phone_number")}/>
            </Form.Item>
            <InputComment k={"contact.phone_number"} comment={comment}/>
            <Divider/>

            <Form.Item name="contact.street_address"
                       label={'street_address'}
                       rules={[{required: true}, { validator: validateASCII32_126 }]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "contact.street_address")}/>
            </Form.Item>
            <InputComment k={"contact.street_address"} comment={comment}/>
            <Divider/>

            <Form.Item name="contact.city"
                       label={'city'}
                       rules={[{required: true}, { validator: validateASCII32_126 }]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "contact.city")}/>
            </Form.Item>
            <InputComment k={"contact.city"} comment={comment}/>
            <Divider/>

            <Form.Item name="contact.state"
                       label={'state'}
                       rules={[{required: true}, { validator: validateASCII32_126 }]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "contact.state")}/>
            </Form.Item>
            <InputComment k={"contact.state"} comment={comment}/>
            <Divider/>

            <Form.Item name="contact.postal_code"
                       label={'postal_code'}
                       rules={[{required: true}, { validator: validateASCII32_126 }]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "contact.postal_code")}/>
            </Form.Item>
            <InputComment k={"contact.postal_code"} comment={comment}/>
            <Divider/>

            {!isReg && <><Form.Item name="contact.ip_address"
                                    label={'ip_address'}
                                    rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "contact.ip_address")} readOnly/>
            </Form.Item>
              <InputComment k={"contact.ip_address"} comment={comment}/></>}

          </Collapse.Panel>

          <Collapse.Panel key={'2'} header={'Identity'}>

            <Form.Item name="identity.given_name"
                       label={'given_name'}
                       rules={[{required: true}, { validator: validateASCII32_126 }]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.given_name")}/>
            </Form.Item>
            <InputComment k={"identity.given_name"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.family_name"
                       label={'family_name'}
                       rules={[{required: true}, { validator: validateASCII32_126 }]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.family_name")}/>
            </Form.Item>
            <InputComment k={"identity.family_name"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.date_of_birth"
                       label={'date_of_birth'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.date_of_birth")}/>
            </Form.Item>
            <InputComment k={"identity.date_of_birth"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.country_of_tax_residence"
                       label={'country_of_tax_residence'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.country_of_tax_residence")}/>
            </Form.Item>
            <InputComment k={"identity.country_of_tax_residence"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.country_of_citizenship"
                       label={'country_of_citizenship'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.country_of_citizenship")}/>
            </Form.Item>
            <InputComment k={"identity.country_of_citizenship"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.funding_source"
                       label={'funding_source'}
                       rules={[{required: true}]}
            >
              <Select mode={"multiple"} onChange={(e) => inputOnChange(e, "identity.funding_source")}>
                <Option value={'employment_income'}>Employment income</Option>
                <Option value={'investments'}>Investments</Option>
                <Option value={'inheritance'}>Inheritance</Option>
                <Option value={'business_income'}>Business income</Option>
                <Option value={'savings'}>Savings</Option>
                <Option value={'family'}>Family</Option>
              </Select>
            </Form.Item>
            <InputComment k={"identity.funding_source"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.annual_income_min"
                       label={'annual_income_min'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.annual_income_min")}/>
            </Form.Item>
            <InputComment k={"identity.annual_income_min"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.annual_income_max"
                       label={'annual_income_max'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.annual_income_max")}/>
            </Form.Item>
            <InputComment k={"identity.annual_income_max"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.liquid_net_worth_min"
                       label={'liquid_net_worth_min'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.liquid_net_worth_min")}/>
            </Form.Item>
            <InputComment k={"identity.liquid_net_worth_min"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.liquid_net_worth_max"
                       label={'liquid_net_worth_max'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.liquid_net_worth_max")}/>
            </Form.Item>
            <InputComment k={"identity.liquid_net_worth_max"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.total_net_worth_min"
                       label={'total_net_worth_min'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.total_net_worth_min")}/>
            </Form.Item>
            <InputComment k={"identity.total_net_worth_min"} comment={comment}/>
            <Divider/>

            <Form.Item name="identity.total_net_worth_max"
                       label={'total_net_worth_max'}
                       rules={[{required: true}]}
            >
              <Input onChange={(e) => inputOnChange(e.target.value, "identity.total_net_worth_max")}/>
            </Form.Item>
            <InputComment k={"identity.total_net_worth_max"} comment={comment}/>

          </Collapse.Panel>

          <Collapse.Panel key={'3'} header={'Disclosures'}>

            <Form.Item name="disclosures.is_control_person"
                       label={'is_control_person'}
            >
              <Checkbox onChange={(e) => inputOnChange(e.target.checked, "disclosures.is_control_person")}/>
            </Form.Item>
            <InputComment k={"disclosures.is_control_person"} comment={comment}/>
            <Divider/>

            <Form.Item name="disclosures.is_affiliated_exchange_or_finra"
                       label={'is_affiliated_exchange_or_finra'}
            >
              <Checkbox
                onChange={(e) => inputOnChange(e.target.checked, "disclosures.is_affiliated_exchange_or_finra")}/>
            </Form.Item>
            <InputComment k={"disclosures.is_affiliated_exchange_or_finra"} comment={comment}/>
            <Divider/>

            <Form.Item name="disclosures.is_politically_exposed"
                       label={'is_politically_exposed'}
            >
              <Checkbox onChange={(e) => inputOnChange(e.target.checked, "disclosures.is_politically_exposed")}/>
            </Form.Item>
            <InputComment k={"disclosures.is_politically_exposed"} comment={comment}/>
            <Divider/>

            <Form.Item name="disclosures.immediate_family_exposed"
                       label={'immediate_family_exposed'}
            >
              <Checkbox onChange={(e) => inputOnChange(e.target.checked, "disclosures.immediate_family_exposed")}/>
            </Form.Item>
            <InputComment k={"disclosures.immediate_family_exposed"} comment={comment}/>

          </Collapse.Panel>
        </Collapse>
      </Form>}
    </Modal>
  </>
})

const Role = observer(({userData}) => {
  const [form] = Form.useForm()
  const [checkedAdmin, setCheckedAdmin] = useState(!!userData?.role?.admin)
  const [checkedWorker, setCheckedWorker] = useState(!!userData?.role?.worker)
  const [permissions, setPermissions] = useState({})

  const roleUpdate = (role, status) => {

    if (role === 'admin') setCheckedAdmin(status)
    if (role === 'worker') setCheckedWorker(status)

    apiQuery("/admin/roleUpdate", {
      method: 'POST',
      body: {userId: userData._id, role, status}
    })
      .then((data) => {
        if (data?.error) return message.error(data.error.msg)
        userStore.authCheck()
      })
      .catch((error) => {
        apiQueryError({error});
      })
  }

  const permissionsList = () => {
    apiQuery("/admin/permissions/list", {
      method: 'POST',
      body: {managerId: userData._id}
    })
      .then((data) => {
        if (data?.error) return message.error(data.error.msg)
        setPermissions(data)
      })
      .catch((error) => {
        apiQueryError({error});
      })
  }

  const permissionUpdate = (permission, status) => {
    apiQuery("/admin/permissions/update", {
      method: 'POST',
      body: {managerId: userData._id, permission, status}
    })
      .then((data) => {
        if (data?.error) return message.error(data.error.msg)
        permissionsList()
      })
      .catch((error) => {
        apiQueryError({error});
      })
  }

  useEffect(() => {
    setCheckedAdmin(!!userData?.role?.admin)
    setCheckedWorker(!!userData?.role?.worker)
    permissionsList()
  },[userData])

  return <>
    <Form
      form={form}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
    >
      <Form.Item label="Администратор" name='admin'>
        <ConfirmPassSwitch disabled={userStore.userData._id === userData._id} checked={checkedAdmin} onFinish={(e) => {
          roleUpdate('admin', e)
        }}/>
      </Form.Item>
      <Form.Item label="Сотрудник компании Geekoinvest" name={'worker'}>
        <ConfirmPassSwitch checked={checkedWorker} onFinish={(e) => {
          roleUpdate('worker', e)
        }}/>
      </Form.Item>
    </Form>

    {checkedAdmin && <div>
      <Divider/>
      <h4>Права администратора</h4>
      <Form
        form={form}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
      >
        {permissions?.permissions && permissions?.permissions.map((item) => <Form.Item label={item}>
          <ConfirmPassSwitch checked={permissions?.userPermissions.includes(item)}
                             disabled={!permissions?.permissionsUpdate}
                             onFinish={(e) => {
                               permissionUpdate(item, e)
                             }}/>
        </Form.Item>)}
      </Form>
    </div>}
  </>
})

const AlpacaProfile = observer(({userData}) => {

  const data = AlpacaStore.data;

  useEffect(() => {
    if (!userData) return;
    AlpacaStore.alpacaUserProfile({userId: userData._id})
  }, [userData])

  if (!data) return <Spin/>

  return <div>
    {/*<Onfido userId={userData?._id}/>*/}
    {!data.isRegistration && <>
      <ModalAlpacaUserProfile userData={userData} isReg={data.isRegistration}
                              title={'Зарегистрировать в Alpaca'}/>
    </>}

    {!!data.isRegistration && <>

      {data.data?.kyc_results?.summary === "fail" && <Alert message={`${data.data?.kyc_results?.summary}! ${data.data?.kyc_results?.additional_information}`} type="error" style={{margin: '15px 0'}}/>}

      <Space>
        <ModalAlpacaUserProfile userData={userData} isReg={data.isRegistration}
                                title={'Редактировать пользователя в Alpaca'}/>
        <ActionsHistory userData={userData} mode={'alpacaForm'}/>
      </Space>

      <Collapse
        style={{marginTop: 15}}
        bordered={false}
        defaultActiveKey={['1', '2']}
        expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
      >
        <Collapse.Panel key={'1'} header={'Contact'}>
          <Descriptions>
            {Object.keys(data.data.contact).map((key) => <Descriptions.Item
              label={key}>{data.data.contact[key]}</Descriptions.Item>)}
          </Descriptions>
        </Collapse.Panel>
        <Collapse.Panel key={'2'} header={'Identity'}>
          <Descriptions>
            {Object.keys(data.data.identity).map((key) => data.data.identity[key] ?
              <Descriptions.Item label={key}>{data.data.identity[key]}</Descriptions.Item> : null)}
          </Descriptions>
        </Collapse.Panel>
        <Collapse.Panel key={'3'} header={'Disclosures'}>
          <Descriptions>
            {Object.keys(data.data.disclosures).map((key) => data.data.disclosures[key] !== null ? <Descriptions.Item
              label={key}>{([true, false].includes(data.data.disclosures[key]) ? (data.data.disclosures[key] ?
              <span><FontAwesomeIcon icon={faCheck}/></span> : <span><FontAwesomeIcon
                icon={faXmark}/></span>) : data.data.disclosures[key])}</Descriptions.Item> : null)}
          </Descriptions>
        </Collapse.Panel>
        <Collapse.Panel key={'5'} header={'Trusted contact'}>
          <Descriptions>
            {data?.data?.trusted_contact && <>
              <Descriptions.Item label={'Имя'}>{data?.data?.trusted_contact?.given_name}</Descriptions.Item>
              <Descriptions.Item label={'Фамилия'}>{data?.data?.trusted_contact?.family_name}</Descriptions.Item>
              <Descriptions.Item label={'Email'}>{data?.data?.trusted_contact?.email_address}</Descriptions.Item>
              <Descriptions.Item label={'Телефон'}>{data?.data?.trusted_contact?.phone_number}</Descriptions.Item>
            </>}
          </Descriptions>
        </Collapse.Panel>
        <Collapse.Panel key={'4'} header={'Documents'}>
          <Descriptions>
            {data?.data?.documents && data?.data?.documents.map((doc) => <Descriptions.Item label={doc.document_type}>
              <a href={doc.content} target={'_blank'} rel="noreferrer"><ImagePreview src={doc.content}/></a>
            </Descriptions.Item>)}
          </Descriptions>
        </Collapse.Panel>

      </Collapse>
    </>}

  </div>
})

const CIPStatus = ({status}) => {
  if (status === 'clear') return <span style={{color: "#5cb028"}}>{status}</span>
  if (status === 'complete') return <span style={{color: "#5cb028"}}>{status}</span>
  return <span style={{color: "#ff2020"}}>{status}</span>
}

const CIPModal = ({userData, btnTitle, cb = () => {}}) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [CIPData, setCIPData] = useState(null);
  const [load, setLoad] = useState(null);
  const [error, setError] = useState({});

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const alpacaCIPDataGet = useCallback(() => {
    setCIPData(null);
    apiQuery("/admin/alpacaCipCollect", {
      method: 'POST',
      body: {userId: userData._id}
    })
      .then((data) => {
        if (data.error) {
          setIsModalVisible(false);
          return message.error(data.error.msg)
        }
        setError(data.errorData)
        delete data.errorData;
        setCIPData(data);

        if (data.provider_name) form.setFieldsValue({provider_name: data.provider_name});

        for (let key of Object.keys(data.kyc)) {
          let d = {};
          d[`kyc.${key}`] = data.kyc[key];
          form.setFieldsValue(d);
        }

        if (data.document) {
          for (let key of Object.keys(data.document)) {
            if (key === 'data_comparison_breakdown') {
              for (let key2 of Object.keys(data.document[key])) {
                let d = {};
                d[`document.${key}.${key2}`] = data.document[key][key2];
                form.setFieldsValue(d);
              }
            }
            if (key === 'visual_authenticity') {
              for (let key2 of Object.keys(data.document[key])) {
                let d = {};
                d[`document.${key}.${key2}`] = data.document[key][key2];
                form.setFieldsValue(d);
              }
            }
            let d = {};
            d[`document.${key}`] = data.document[key];
            form.setFieldsValue(d);
          }
        }

        for (let key of Object.keys(data.photo)) {
          let d = {};
          if (typeof data.photo[key] === 'object') {
            for (let key2 of Object.keys(data.photo[key])) {
              d[`photo.${key}.${key2}`] = data.photo[key][key2]?.result ? data.photo[key][key2].result : data.photo[key][key2];
            }
          } else {
            d[`photo.${key}`] = data.photo[key]?.result ? data.photo[key].result : data.photo[key];
          }
          form.setFieldsValue(d);
        }

        for (let key of Object.keys(data.watchlist)) {
          let d = {};
          d[`watchlist.${key}`] = data.watchlist[key];
          form.setFieldsValue(d);
        }

        form.setFieldValue('kyc.approved_by', userStore.userData.profileData.name);
      })
      .catch((error) => {
        setCIPData(false);
        apiQueryError({error});
      })
  }, [userData, form])

  const onFinishCIPToAlpaca = (values) => {
    console.log(values)
    setLoad(true)
    values.userId = userData._id;
    apiQuery("/admin/alpacaCipSave", {
      method: 'POST',
      body: values
    })
      .then((data) => {
        setLoad(false)
        if (data.error) return message.error(data.error.msg);
        console.log(data)
        message.success('Данные отправлены');
        setIsModalVisible(false);
        cb()
      })
      .catch((error) => {
        setLoad(false)
        apiQueryError({error});
      })
  }

  const errorView = (key) => {
    if (!error[key]) return null;
    return <div style={{
      color: "red",
      fontSize: 12,
      marginBottom: 15
    }}>{error[key].join(', ')}</div>
  }

  useEffect(() => {
    if (isModalVisible) alpacaCIPDataGet()
  }, [isModalVisible, alpacaCIPDataGet])

  return <>
    <Button type="primary"
            onClick={showModal}
    >
      {btnTitle}
    </Button>

    <Modal title={'CIP form'}
           visible={isModalVisible}
           okText={'Сохранить и отправить'}
           onCancel={handleCancel}
           width={800}
           confirmLoading={load}
           footer={<>
             <Button onClick={handleCancel}>Закрыть</Button>
             <ConfirmPass2 btnText={'Сохранить'}
                           btnType={'primary'}
                           mode={'cipForm'}
                           fieldCommentVisible={true}
                           fieldFileVisible={true}
                           onFinish={(data) => {
                             form.setFieldValue('actionData', data)
                             form.submit()
                           }}/>
           </>}
    >
      {CIPData === null && <><Spin/> Идет сбор данных...</>}
      {CIPData === false && <>Нет данных</>}
      {CIPData && <Form
        form={form}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        onFinish={onFinishCIPToAlpaca}
        scrollToFirstError={true}
      >
        <Form.Item name="actionData" hidden={true}>
          <Input readOnly={true}/>
        </Form.Item>

        <Card size="small" title="KYC" style={{marginBottom: 15}}>
          <Form.Item label={'risk_level'} name="kyc.risk_level">
            <Select disabled={true}>
              <Option value="unknown">Unknown</Option>
              <Option value="low">Low</Option>
              <Option value="medium">Medium</Option>
              <Option value="high">High</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'applicant_name'}
            name="kyc.applicant_name"
            rules={[{required: true}]}
          >
            <Input readOnly={true}/>
          </Form.Item>

          <Form.Item
            label={'email_address'}
            name="kyc.email_address"
            rules={[{required: true}]}
          >
            <Input readOnly={true}/>
          </Form.Item>

          <Form.Item
            label={'nationality'}
            name="kyc.nationality"
            rules={[{required: true}]}
          >
            <Select disabled={true}>
              <Option value="KAZ">KAZ</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'date_of_birth'}
            name="kyc.date_of_birth"
            rules={[{required: true}]}
          >
            <Input readOnly={true}/>
          </Form.Item>

          <Form.Item
            label={'address'}
            name="kyc.address"
            rules={[{required: true}]}
          >
            <Input readOnly={true}/>
          </Form.Item>

          <Form.Item
            label={'postal_code'}
            name="kyc.postal_code"
            rules={[{required: true}]}
          >
            <Input readOnly={true}/>
          </Form.Item>

          <Form.Item
            label={'country_of_residency'}
            name="kyc.country_of_residency"
            rules={[{required: true}]}
          >
            <Select disabled={true}>
              <Option value="KAZ">KAZ</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'approval_status'}
            name="kyc.approval_status"
            rules={[{required: true}]}
          >
            <Select>
              <Option value="approved">approved</Option>
              <Option value="rejected"><span style={{color: "#ff2020"}}>rejected</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'approved_by'}
            name="kyc.approved_by"
            rules={[{required: true}]}
          >
            {/*<Select>*/}
            {/*  <Option value="Zhansaya Oryngazina">Zhansaya Oryngazina</Option>*/}
            {/*</Select>*/}
            <Input/>
          </Form.Item>

          <Form.Item
            label={'approved_reason'}
            name="kyc.approved_reason"
            rules={[{required: true}]}
          >
            <Input/>
          </Form.Item>

        </Card>

        <Card size="small" title="Document" style={{marginBottom: 15}}>

          <Form.Item
            label={'result'}
            name="document.result"
            rules={[{required: true}]}
            help={errorView("document.result")}
          >
            <Select>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'status'}
            name="document.status"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'created_at'}
            name="document.created_at"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'first_name'}
            name="document.first_name"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'last_name'}
            name="document.last_name"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'gender'}
            name="document.gender"
            rules={[{required: true}]}
          >
            <Select disabled={true}>
              <Option value="man">man</Option>
              <Option value="woman">woman</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'date_of_birth'}
            name="document.date_of_birth"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'date_of_expiry'}
            name="document.date_of_expiry"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'issuing_country'}
            name="document.issuing_country"
            rules={[{required: true}]}
          >
            <Select disabled={true}>
              <Option value="KAZ">KAZ</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'nationality'}
            name="document.nationality"
            rules={[{required: true}]}
          >
            <Select disabled={true}>
              <Option value="KAZ">KAZ</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'document_numbers'}
            name="document.document_numbers"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'document_type'}
            name="document.document_type"
            rules={[{required: true}]}
          >
            <Select disabled={true}>
              <Option value="national_id_card">national_id_card</Option>
              <Option value="passport">passport</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'age_validation'}
            name="document.age_validation"
            rules={[{required: true}]}
            help={errorView("document.age_validation")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'compromised_document'}
            name="document.compromised_document"
            rules={[{required: true}]}
            help={errorView("document.compromised_document")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison'}
            name="document.data_comparison"
            rules={[{required: true}]}
            help={errorView("document.data_comparison")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison_breakdown.first_name'}
            name="document.data_comparison_breakdown.first_name"
            rules={[{required: true}]}
            help={errorView("document.data_comparison_breakdown.first_name")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison_breakdown.last_name'}
            name="document.data_comparison_breakdown.last_name"
            rules={[{required: true}]}
            help={errorView("document.data_comparison_breakdown.last_name")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison_breakdown.date_of_birth'}
            name="document.data_comparison_breakdown.date_of_birth"
            rules={[{required: true}]}
            help={errorView("document.data_comparison_breakdown.date_of_birth")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison_breakdown.date_of_expiry'}
            name="document.data_comparison_breakdown.date_of_expiry"
            rules={[{required: true}]}
            help={errorView("document.data_comparison_breakdown.date_of_expiry")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison_breakdown.document_numbers'}
            name="document.data_comparison_breakdown.document_numbers"
            rules={[{required: true}]}
            help={errorView("document.data_comparison_breakdown.document_numbers")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison_breakdown.document_type'}
            name="document.data_comparison_breakdown.document_type"
            rules={[{required: true}]}
            help={errorView("document.data_comparison_breakdown.document_type")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison_breakdown.gender'}
            name="document.data_comparison_breakdown.gender"
            rules={[{required: true}]}
            help={errorView("document.data_comparison_breakdown.gender")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'data_comparison_breakdown.issuing_country'}
            name="document.data_comparison_breakdown.issuing_country"
            rules={[{required: true}]}
            help={errorView("document.data_comparison_breakdown.issuing_country")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'image_integrity'}
            name="document.image_integrity"
            rules={[{required: true}]}
            help={errorView("document.image_integrity")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'visual_authenticity.digital_tampering'}
            name="document.visual_authenticity.digital_tampering"
            rules={[{required: true}]}
            help={errorView("document.visual_authenticity.digital_tampering")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'visual_authenticity.face_detection'}
            name="document.visual_authenticity.face_detection"
            rules={[{required: true}]}
            help={errorView("document.visual_authenticity.face_detection")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'visual_authenticity.fonts'}
            name="document.visual_authenticity.fonts"
            rules={[{required: true}]}
            help={errorView("document.visual_authenticity.fonts")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'visual_authenticity.original_document_present'}
            name="document.visual_authenticity.original_document_present"
            rules={[{required: true}]}
            help={errorView("document.visual_authenticity.original_document_present")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'visual_authenticity.picture_face_integrity'}
            name="document.visual_authenticity.picture_face_integrity"
            rules={[{required: true}]}
            help={errorView("document.visual_authenticity.picture_face_integrity")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'visual_authenticity.security_features'}
            name="document.visual_authenticity.security_features"
            rules={[{required: true}]}
            help={errorView("document.visual_authenticity.security_features")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'visual_authenticity.template'}
            name="document.visual_authenticity.template"
            rules={[{required: true}]}
            help={errorView("document.visual_authenticity.template")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'police_record'}
            name="document.police_record"
            rules={[{required: true}]}
            help={errorView("document.police_record")}
          >
            <Select disabled={true}>
              <Option value="complete"><span style={{color: "#5cb028"}}>complete</span></Option>
              <Option value="withdrawn"><span style={{color: "#ff2020"}}>withdrawn</span></Option>
            </Select>
          </Form.Item>

        </Card>

        <Card size="small" title="Photo" style={{marginBottom: 15}}>

          <Form.Item
            label={'result'}
            name="photo.result"
            rules={[{required: true}]}
            help={errorView("photo.result")}
          >
            <Select>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="status"
            name="photo.status"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label="created_at"
            name="photo.created_at"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'face_comparison'}
            name="photo.face_comparison"
            rules={[{required: true}]}
            help={errorView("photo.face_comparison")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'image_integrity'}
            name="photo.image_integrity"
            rules={[{required: true}]}
            help={errorView("photo.image_integrity")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'visual_authenticity'}
            name="photo.visual_authenticity"
            rules={[{required: true}]}
            help={errorView("photo.visual_authenticity")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

        </Card>

        <Card size="small" title="Watchlist" style={{marginBottom: 15}}>
          <Form.Item
            label="result"
            name="watchlist.result"
            rules={[{required: true}]}
            help={errorView("watchlist.result")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="status"
            name="watchlist.status"
            rules={[{required: true}]}
          >
            <Input readOnly/>
          </Form.Item>

          <Form.Item
            label={'politically_exposed_person'}
            name="watchlist.politically_exposed_person"
            rules={[{required: true}]}
            help={errorView("watchlist.politically_exposed_person")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'sanction'}
            name="watchlist.sanction"
            rules={[{required: true}]}
            help={errorView("watchlist.sanction")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'adverse_media'}
            name="watchlist.adverse_media"
            rules={[{required: true}]}
            help={errorView("watchlist.adverse_media")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'monitored_lists'}
            name="watchlist.monitored_lists"
            rules={[{required: true}]}
            help={errorView("watchlist.monitored_lists")}
          >
            <Select disabled={true}>
              <Option value="clear"><span style={{color: "#5cb028"}}>clear</span></Option>
              <Option value="consider"><span style={{color: "#ff2020"}}>consider</span></Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="records"
            name="watchlist.records"
            // rules={[{ required: true }]}
          >
            <Input.TextArea readOnly/>
          </Form.Item>

        </Card>

      </Form>}
    </Modal>
  </>
}

const CIP = ({userData}) => {

  const [CIPData, setCIPData] = useState(false);
  const alpacaCIPDataGet = useCallback(() => {
    apiQuery("/admin/alpacaCipView", {
      method: 'POST',
      body: {userId: userData._id}
    })
      .then((data) => {
        if (data?.error) return message.error(data.error.msg)
        setCIPData(data);
      })
      .catch((error) => {
        setCIPData(false);
        apiQueryError({error});
      })
  }, [userData])

  useEffect(() => {
    alpacaCIPDataGet()
  }, [alpacaCIPDataGet])

  if (CIPData === false) return <Spin/>
  if (CIPData === null) return <>
    <CIPModal userData={userData} btnTitle={'Открыть счет'} cb={() => {
      alpacaCIPDataGet()
    }}/>
  </>

  console.log(CIPData)

  return <>

    <Space style={{marginBottom: 15}}>
      <CIPModal userData={userData} btnTitle={'Открыть CIP'} cb={() => {
        alpacaCIPDataGet()
      }}/>
      <ActionsHistory userData={userData} mode={'cipForm'}/>
    </Space>

    <p>Дата изменения: {moment(CIPData.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>
    <Collapse
      bordered={false}
      defaultActiveKey={['2']}
      expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
    >
      <Collapse.Panel key={'1'} header={'Provider'}>
        <Descriptions>
          <Descriptions.Item label="provider_name">
            {CIPData.provider_name.join(', ')}
          </Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
      <Collapse.Panel key={'2'} header={'KYC'}>
        <Descriptions>
          <Descriptions.Item label="risk_level">{CIPData.kyc.risk_level}</Descriptions.Item>
          <Descriptions.Item label="applicant_name">{CIPData.kyc.applicant_name}</Descriptions.Item>
          <Descriptions.Item label="email_address">{CIPData.kyc.email_address}</Descriptions.Item>
          <Descriptions.Item label="nationality">{CIPData.kyc.nationality}</Descriptions.Item>
          <Descriptions.Item label="date_of_birth">{CIPData.kyc.date_of_birth}</Descriptions.Item>
          <Descriptions.Item span={3} label="address">{CIPData.kyc.address}</Descriptions.Item>
          <Descriptions.Item label="postal_code">{CIPData.kyc.postal_code}</Descriptions.Item>
          <Descriptions.Item label="country_of_residency">{CIPData.kyc.country_of_residency}</Descriptions.Item>
          <Descriptions.Item label="approval_status">{CIPData.kyc.approval_status}</Descriptions.Item>
          <Descriptions.Item label="approved_by">{CIPData.kyc.approved_by}</Descriptions.Item>
          <Descriptions.Item label="approved_reason">{CIPData.kyc.approved_reason}</Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
      <Collapse.Panel key={'3'} header={'Document'}>
        <Descriptions>
          <Descriptions.Item label="result"><CIPStatus status={CIPData.document.result}/></Descriptions.Item>
          <Descriptions.Item label="status"><CIPStatus status={CIPData.document.status}/></Descriptions.Item>
          <Descriptions.Item label="created_at">{CIPData.document.created_at}</Descriptions.Item>
          <Descriptions.Item label="first_name">{CIPData.document.first_name}</Descriptions.Item>
          <Descriptions.Item label="last_name">{CIPData.document.last_name}</Descriptions.Item>
          <Descriptions.Item label="gender">{CIPData.document.gender}</Descriptions.Item>
          <Descriptions.Item label="date_of_birth">{CIPData.document.date_of_birth}</Descriptions.Item>
          <Descriptions.Item label="date_of_expiry">{CIPData.document.date_of_expiry}</Descriptions.Item>
          <Descriptions.Item label="issuing_country">{CIPData.document.issuing_country}</Descriptions.Item>
          <Descriptions.Item label="nationality">{CIPData.document.nationality}</Descriptions.Item>
          <Descriptions.Item label="document_numbers">{CIPData.document.document_numbers}</Descriptions.Item>
          <Descriptions.Item label="document_type">{CIPData.document.document_type}</Descriptions.Item>
          <Descriptions.Item label="age_validation"><CIPStatus status={CIPData.document.age_validation}/></Descriptions.Item>
          <Descriptions.Item label="compromised_document"><CIPStatus status={CIPData.document.compromised_document}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison"><CIPStatus status={CIPData.document.data_comparison}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison_breakdown.first_name"><CIPStatus status={CIPData.document.data_comparison_breakdown.first_name}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison_breakdown.last_name"><CIPStatus status={CIPData.document.data_comparison_breakdown.last_name}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison_breakdown.date_of_birth"><CIPStatus status={CIPData.document.data_comparison_breakdown.date_of_birth}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison_breakdown.date_of_expiry"><CIPStatus status={CIPData.document.data_comparison_breakdown.date_of_expiry}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison_breakdown.document_numbers"><CIPStatus status={CIPData.document.data_comparison_breakdown.document_numbers}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison_breakdown.document_type"><CIPStatus status={CIPData.document.data_comparison_breakdown.document_type}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison_breakdown.gender"><CIPStatus status={CIPData.document.data_comparison_breakdown.gender}/></Descriptions.Item>
          <Descriptions.Item label="data_comparison_breakdown.issuing_country"><CIPStatus status={CIPData.document.data_comparison_breakdown.issuing_country}/></Descriptions.Item>
          <Descriptions.Item label="image_integrity"><CIPStatus status={CIPData.document.image_integrity}/></Descriptions.Item>
          <Descriptions.Item label="visual_authenticity.digital_tampering"><CIPStatus status={CIPData.document.visual_authenticity.digital_tampering}/></Descriptions.Item>
          <Descriptions.Item label="visual_authenticity.face_detection"><CIPStatus status={CIPData.document.visual_authenticity.face_detection}/></Descriptions.Item>
          <Descriptions.Item label="visual_authenticity.fonts"><CIPStatus status={CIPData.document.visual_authenticity.fonts}/></Descriptions.Item>
          <Descriptions.Item label="visual_authenticity.original_document_present"><CIPStatus status={CIPData.document.visual_authenticity.original_document_present}/></Descriptions.Item>
          <Descriptions.Item label="visual_authenticity.picture_face_integrity"><CIPStatus status={CIPData.document.visual_authenticity.picture_face_integrity}/></Descriptions.Item>
          <Descriptions.Item label="visual_authenticity.security_features"><CIPStatus status={CIPData.document.visual_authenticity.security_features}/></Descriptions.Item>
          <Descriptions.Item label="visual_authenticity.template"><CIPStatus status={CIPData.document.visual_authenticity.template}/></Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
      <Collapse.Panel key={'4'} header={'Photo'}>
        <Descriptions>
          <Descriptions.Item label="result"><CIPStatus status={CIPData.photo.result}/></Descriptions.Item>
          <Descriptions.Item label="status"><CIPStatus status={CIPData.photo.status}/></Descriptions.Item>
          <Descriptions.Item label="created_at">{CIPData.photo.created_at}</Descriptions.Item>
          <Descriptions.Item label="face_comparison"><CIPStatus status={CIPData.photo.face_comparison}/></Descriptions.Item>
          <Descriptions.Item label="image_integrity"><CIPStatus status={CIPData.photo.image_integrity}/></Descriptions.Item>
          <Descriptions.Item label="visual_authenticity"><CIPStatus status={CIPData.photo.visual_authenticity}/></Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
      <Collapse.Panel key={'5'} header={'Watchlist'}>
        <Descriptions>
          <Descriptions.Item label="result"><CIPStatus status={CIPData?.watchlist?.result}/></Descriptions.Item>
          <Descriptions.Item label="status"><CIPStatus status={CIPData?.watchlist?.status}/></Descriptions.Item>
          <Descriptions.Item label="politically_exposed_person"><CIPStatus status={CIPData?.watchlist?.politically_exposed_person}/></Descriptions.Item>
          <Descriptions.Item label="sanction"><CIPStatus status={CIPData?.watchlist?.sanction}/></Descriptions.Item>
          <Descriptions.Item label="adverse_media"><CIPStatus status={CIPData?.watchlist?.adverse_media}/></Descriptions.Item>
          <Descriptions.Item label="monitored_lists"><CIPStatus status={CIPData?.watchlist?.monitored_lists}/></Descriptions.Item>
          <Descriptions.Item label="records">{CIPData?.watchlist?.records}</Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  </>
}

const AISModal = ({userData, cb = () => {}}) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AISData, setAISData] = useState(null);
  const [load, setLoad] = useState(null);
  const [error, setError] = useState({});

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit()
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const aisGetSettlements = (val)=>{

    console.log(val)

    apiQuery("/admin/aisGetSettlements", {
      method: 'POST',
      body: {countryId: val}
    })
        .then((data) => {

          console.log(data)

          // if (data.error) {
          //   setIsModalVisible(false);
          //   return message.error(data.error.msg)
          // }
          // setError(data.errorData)
          // delete data.errorData;
          setAISData(oldData =>{
            // oldData.settlements = data

            // return oldData
            return {...oldData, settlements: data}
          });

          // if (data.provider_name) form.setFieldsValue({provider_name: data.provider_name});

          // for (let key of Object.keys(data.kyc)) {
          //   let d = {};
          //   d[`kyc.${key}`] = data.kyc[key];
          //   form.setFieldsValue(d);
          // }
          //
          // if (data.document) {
          //   for (let key of Object.keys(data.document)) {
          //     if (key === 'data_comparison_breakdown') {
          //       for (let key2 of Object.keys(data.document[key])) {
          //         let d = {};
          //         d[`document.${key}.${key2}`] = data.document[key][key2];
          //         form.setFieldsValue(d);
          //       }
          //     }
          //     if (key === 'visual_authenticity') {
          //       for (let key2 of Object.keys(data.document[key])) {
          //         let d = {};
          //         d[`document.${key}.${key2}`] = data.document[key][key2];
          //         form.setFieldsValue(d);
          //       }
          //     }
          //     if (key === 'image_integrity_breakdown') {
          //       for (let key2 of Object.keys(data.document[key])) {
          //         let d = {};
          //         d[`document.${key}.${key2}`] = data.document[key][key2];
          //         form.setFieldsValue(d);
          //       }
          //     }
          //     let d = {};
          //     d[`document.${key}`] = data.document[key];
          //     form.setFieldsValue(d);
          //   }
          // }
          //
          // for (let key of Object.keys(data.photo)) {
          //   let d = {};
          //   if (typeof data.photo[key] === 'object') {
          //     for (let key2 of Object.keys(data.photo[key])) {
          //       d[`photo.${key}.${key2}`] = data.photo[key][key2]?.result ? data.photo[key][key2].result : data.photo[key][key2];
          //     }
          //   } else {
          //     d[`photo.${key}`] = data.photo[key]?.result ? data.photo[key].result : data.photo[key];
          //   }
          //   form.setFieldsValue(d);
          // }
          //
          // for (let key of Object.keys(data.watchlist)) {
          //   let d = {};
          //   d[`watchlist.${key}`] = data.watchlist[key];
          //   form.setFieldsValue(d);
          // }

        })
        .catch((error) => {
          setAISData(false);
          apiQueryError({error});
        })
  }

  const setECONOMICS_SECTOR_CODE = (aNaturalPersonBool)=>{
    let d = {};
    if(aNaturalPersonBool!==1 && aNaturalPersonBool!=='1'){
      d['aECONOMICS_SECTOR_CODE'] = '';
    }
    else {
      d['aECONOMICS_SECTOR_CODE'] = '9';
    }
    form.setFieldsValue(d);

  }

  const aISDataGet = useCallback(() => {
    setAISData(null);
    apiQuery("/admin/aisDataCollect", {
      method: 'POST',
      body: {userId: userData._id}
    })
        .then((data) => {

          console.log(data)

          if (data.error) {
            setIsModalVisible(false);
            return message.error(data.error.msg)
          }
          setError(data.errorData)
          delete data.errorData;
          setAISData(data);

          if (data.provider_name) form.setFieldsValue({provider_name: data.provider_name});

          for (let key of Object.keys(data.aisData)) {
            let d = {};
            d[key] = data.aisData[key];
            form.setFieldsValue(d);

            if(key==='aCountryID' && data.aisData[key]!==''){
              aisGetSettlements(data.aisData[key])
            }
            if(key==='aNaturalPersonBool' && data.aisData[key]!==''){
              setTimeout(()=>{setECONOMICS_SECTOR_CODE(data.aisData[key])},100)
            }
          }
          //
          // if (data.document) {
          //   for (let key of Object.keys(data.document)) {
          //     if (key === 'data_comparison_breakdown') {
          //       for (let key2 of Object.keys(data.document[key])) {
          //         let d = {};
          //         d[`document.${key}.${key2}`] = data.document[key][key2];
          //         form.setFieldsValue(d);
          //       }
          //     }
          //     if (key === 'visual_authenticity') {
          //       for (let key2 of Object.keys(data.document[key])) {
          //         let d = {};
          //         d[`document.${key}.${key2}`] = data.document[key][key2];
          //         form.setFieldsValue(d);
          //       }
          //     }
          //     if (key === 'image_integrity_breakdown') {
          //       for (let key2 of Object.keys(data.document[key])) {
          //         let d = {};
          //         d[`document.${key}.${key2}`] = data.document[key][key2];
          //         form.setFieldsValue(d);
          //       }
          //     }
          //     let d = {};
          //     d[`document.${key}`] = data.document[key];
          //     form.setFieldsValue(d);
          //   }
          // }
          //
          // for (let key of Object.keys(data.photo)) {
          //   let d = {};
          //   if (typeof data.photo[key] === 'object') {
          //     for (let key2 of Object.keys(data.photo[key])) {
          //       d[`photo.${key}.${key2}`] = data.photo[key][key2]?.result ? data.photo[key][key2].result : data.photo[key][key2];
          //     }
          //   } else {
          //     d[`photo.${key}`] = data.photo[key]?.result ? data.photo[key].result : data.photo[key];
          //   }
          //   form.setFieldsValue(d);
          // }
          //
          // for (let key of Object.keys(data.watchlist)) {
          //   let d = {};
          //   d[`watchlist.${key}`] = data.watchlist[key];
          //   form.setFieldsValue(d);
          // }

        })
        .catch((error) => {
          setAISData(false);
          apiQueryError({error});
        })
  }, [userData, form])

  const onFinishAisData = (values) => {
    setLoad(true)
    console.log(values)
    values.userId = userData._id;
    apiQuery("/admin/aisDataSave", {
      method: 'POST',
      body: values
    })
        .then((data) => {
          setLoad(false)
          if (data.error) return message.error(data.error.msg);
          console.log(data)
          message.success('Данные отправлены');
          setIsModalVisible(false);
          cb()
        })
        .catch((error) => {
          setLoad(false)
          apiQueryError({error});
        })
  }

  const errorView = (key) => {
    if (!error[key]) return null;
    return <div style={{
      color: "red",
      fontSize: 12,
      marginBottom: 15
    }}>{error[key].join(', ')}</div>
  }

  useEffect(() => {
    if (isModalVisible) aISDataGet()
  }, [isModalVisible, aISDataGet])

  return <>
    <Button type="primary"
            style={{marginBottom: 15}}
            onClick={showModal}
    >
      Открыть анкету
    </Button>

    <Modal title={'Ais form'}
           visible={isModalVisible}
           okText={'Сохранить и отправить'}
           onOk={handleOk}
           onCancel={handleCancel}
           width={800}
           confirmLoading={load}
    >
      {AISData === null && <><Spin/> Идет сбор данных...</>}
      {AISData === false && <>Нет данных</>}
      {AISData && <Form
          form={form}
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinishAisData}
          scrollToFirstError={true}
      >
        <Card size="small" title="" style={{marginBottom: 15}}>
          <Form.Item label={'Лицо'} name="aNaturalPersonBool" rules={[{required: true}]}>
            <Select onChange={setECONOMICS_SECTOR_CODE}>
              <Option value="0">Юр лицо</Option>
              <Option value="1">Физ лицо</Option>
            </Select>
          </Form.Item>
          <Form.Item label={'Резидент'} name="aResidentBool" rules={[{required: true}]}>
            <Select>
              <Option value="0">Не резидент</Option>
              <Option value="1">Резидент</Option>
            </Select>
          </Form.Item>

          <Form.Item
              label={'Фамилия'}
              name="aLastName"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Имя'}
              name="aFirstName"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Отчество'}
              name="aMiddleName"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Название Юр лица'}
              name="aJuridicalName"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Дата рождения'}
              name="aBorn"
          >
            <Input/>
          </Form.Item>

          <Form.Item label={'Тип документа'} name="aDocumentType" rules={[{required: true}]}>
            <Select options={AISData.documentsTypes}>
            </Select>
          </Form.Item>

          <Form.Item
              label={'Серия документа'}
              name="aDocumentSeries"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Номер документа'}
              name="aDocumentNumber"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Дата выдачи'}
              name="aDocumentGivedDate"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Кем выдано'}
              name="aDocumentGivedBy"
              rules={[{required: true}]}
          >
            <Select options={AISData.documentGrantAuthorities}>
            </Select>
          </Form.Item>
          <Form.Item
              label={'Кем выдано'}
              name="aDocumentGivedByText"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Документ действует до'}
              name="aDocumentEndDate"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'ИИН'}
              name="aIIN"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Страна проживания'}
              name="aCountryID"
              rules={[{required: true}]}
          >
            <Select options={AISData.countries} onChange={aisGetSettlements}>
            </Select>
          </Form.Item>

          <Form.Item
              label={'Населённый пункт'}
              name="aSettlementID"
          >
            <TreeSelect treeDefaultExpandAll treeData={AISData.settlements}>
            </TreeSelect>
          </Form.Item>

          <Form.Item
              label={'Название населённого пункта'}
              name="aSettlementText"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Тип населённого пункта '}
              name="aSettlementTypeID"
          >
            <Select>
              <Option value="1">СТРАНА</Option>
              <Option value="2">ОБЛАСТЬ</Option>
              <Option value="3">РАЙОН</Option>
              <Option value="4">ГОРОД</Option>
              <Option value="5">ПОСЁЛОК</Option>
              <Option value="6">СЕЛО</Option>
              <Option value="7">ИНОЕ</Option>
              <Option value="8">АУЛ</Option>
              <Option value="9">СТАНЦИЯ</Option>
              <Option value="10">РАЗЪЕЗД</Option>
              <Option value="11">УЧАСТОК</Option>
              <Option value="12">ЛЕСНОЕ ХОЗЯЙСТВО</Option>
              <Option value="13">ФЕРМА</Option>
              <Option value="14">ОТДЕЛЕНИЕ</Option>
              <Option value="15">ТОЧКА</Option>
              <Option value="16">КРЕСТЬЯНСКОЕ ХОЗЯЙСТВО</Option>

            </Select>
          </Form.Item>

          <Form.Item
              label={'Почтовый индекс'}
              name="aAddr_Index"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Адрес'}
              name="aAddress"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Телефон'}
              name="aPhones"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Мобильный телефон'}
              name="aMobilePhones"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Е-майл'}
              name="aEmail"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Банк, в котором открыт внешний счёт'}
              name="aBankID"
              rules={[{required: true}]}
          >
            <Select options={AISData.bankList}>
            </Select>
          </Form.Item>

          <Form.Item
              label={'IBAN (для иностранных банков) ИИК для банков РК'}
              name="aIBAN"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Пол'}
              name="aSex"
              rules={[{required: true}]}
          >
            <Select>
              <Option value="1">муж</Option>
              <Option value="2">жен</Option>
            </Select>
          </Form.Item>
          <Form.Item
              label={'Фактический адрес'}
              name="aACTUAL_ADDRESS"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Фактический адрес (индекс)'}
              name="aACTUAL_ADDRESS_INDEX"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Гражданство'}
              name="aCITIZEN_COUNTRY_ID"
              rules={[{required: true}]}
          >
            <Select options={AISData.countries}>

            </Select>
          </Form.Item>

          <Form.Item
              label={'Код сектора экономики'}
              name="aECONOMICS_SECTOR_CODE"
              rules={[{required: true}]}
          >
            <Select>
              <Option value="1">Центральное правительство</Option>
              <Option value="2">Региональные и местные органы управления</Option>
              <Option value="3">Центральные (национальные) банки</Option>
              <Option value="4">Другие депозитные организации</Option>
              <Option value="5">Другие финансовые организации</Option>
              <Option value="6">Государственные нефинансовые организации</Option>
              <Option value="7">Негосударственные нефинансовые организации</Option>
              <Option value="8">Некоммерческие организацииб обслуживающие домашние хозяйства</Option>
              <Option value="9">Домашние хозяйства</Option>
            </Select>
          </Form.Item>
          <Form.Item
              label={'Налоговые льготы'}
              name="aTAXPRIVS"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Форма собственности'}
              name="aOWNERSHIP_PATTERN_ID"
              rules={[{required: true}]}
          >
            <Select>
              <Option value="8">ФИЗИЧЕСКОЕ ЛИЦО</Option>
              <Option value="9">ОТКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО</Option>
              <Option value="10">ЗАКРЫТОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО</Option>
              <Option value="11">ОТКРЫТОЕ НАРОДНОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО</Option>
              <Option value="12">ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ</Option>
              <Option value="13">ТОВАРИЩЕСТВО С ДОПОЛНИТЕЛЬНОЙ ОТВЕТСТВЕННОСТЬЮ</Option>
              <Option value="14">РЕСПУБЛИКАНСКОЕ ГОСУДАРСТВЕННОЕ ПРЕДПРИЯТИЕ</Option>
              <Option value="15">ГОСУДАРСТВЕННОЕ ПРЕДПРИЯТИЕ</Option>
              <Option value="16">ПРОИЗВОДСТВЕННЫЙ КООПЕРАТИВ</Option>
              <Option value="17">ОРГАНИЗАЦИИ СО СТАТУСОМ SUPRANATIONAL</Option>
              <Option value="18">КОМАНДИТНОЕ ТОВАРИЩЕСТВО</Option>
              <Option value="19">ОБЪЕДИНЕНИЕ ЮРИДИЧЕСКИХ ЛИЦ</Option>
              <Option value="20">АКЦИОНЕРНОЕ ОБЩЕСТВО</Option>
              <Option value="21">АКЦИОНЕРНОЕ ОБЩЕСТВО (НАРОДНОЕ ОБЩЕСТВО)</Option>
              <Option value="22">ДОЧЕРНИЙ БАНК</Option>
              <Option value="23">ЗАКР.ПАЕВЫЙ ИНВЕСТ.ФОНД РИСКОВОГО ИНВЕСТИРОВАНИЯ</Option>
              <Option value="24">ОТКРЫТЫЙ ПАЕВЫЙ ИНСВЕСТИЦИОННЫЙ ФОНД</Option>
              <Option value="25">ИНТЕРВАЛЬНЫЙ ПАЕВЫЙ ИНВЕСТИЦИОННЫЙ ФОНД</Option>
              <Option value="26">ЗАКРЫТЫЙ ПАЕВЫЙ ИНСВЕСТИЦИОННЫЙ ФОНД</Option>
              <Option value="27">НЕКОММЕРЧЕСКОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО</Option>
              <Option value="28">МАЛОЕ ЧАСТНОЕ ПРЕДПРИЯТИЕ</Option>
              <Option value="29">РЕСПУБЛИКАНСКОЕ УНИТАРНОЕ ПРЕДПРИЯТИЕ</Option>
              <Option value="30">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ</Option>
              <Option value="31">ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ</Option>
              <Option value="32">РЕСПУБЛИКАНСКОЕ ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ</Option>
              <Option value="33">ЗАКРЫТЫЙ ПАЕВОЙ ИНВЕСТИЦИОННЫЙ ФОНД РЕНТНЫЙ</Option>
              <Option value="34">ПУБЛИЧНОЕ АКЦИОНЕРНОЕ ОБЩЕСТВО</Option>
              <Option value="35">ДОЧЕРНЯЯ ОРГАНИЗАЦИЯ</Option>
              <Option value="36">ЗАКРЫТЫЙ ПАЕВОЙ ИНВЕСТИЦИОННЫЙ ФОНД НЕДВИЖИМОСТИ</Option>
              <Option value="37">МЕЖДУНАРОДНАЯ ОРГАНИЗАЦИЯ</Option>
              <Option value="38">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ</Option>
              <Option value="39">НЕИЗВЕСТНО</Option>
              <Option value="40">ЮРИДИЧЕСКОЕ ЛИЦО</Option>

            </Select>
          </Form.Item>

          <Form.Item
              label={'Вид держателя в ЦД '}
              name="aHOLDER_TYPE_IN_CD_ID"
              rules={[{required: true}]}
          >
            <Select>
              <Option value="1">ФИЗИЧЕСКОЕ ЛИЦО</Option>
              <Option value="2">ПЕНСИОННЫЙ ФОНД</Option>
              <Option value="3">БАНК</Option>
              <Option value="4">СТРАХОВАЯ ОРГАНИЗАЦИЯ В ОТРАСЛИ "ОБЩЕЕ СТРАХОВАНИЕ"</Option>
              <Option value="5">БРОКЕР-ДИЛЕР</Option>
              <Option value="6">НОМИНАЛЬНЫЙ ДЕРЖАТЕЛЬ</Option>
              <Option value="7">ПРОЧИЕ ЛИЦЕНЗИАТЫ РЫНКА ЦЕННЫХ БУМАГ</Option>
              <Option value="8">ПРОЧИЕ ЮРИДИЧЕСКИЕ ЛИЦА</Option>
              <Option value="9">НЕ РАСКРЫТА ИНФОРМАЦИЯ</Option>
              <Option value="10">ИНВЕСТИЦИОННЫЙ ФОНД</Option>
              <Option value="11">ИНОЕ</Option>
              <Option value="12">ПЕНСИОННЫЙ ФОНД (СОБСТВЕННЫЕ АКТИВЫ)</Option>
              <Option value="13">ПЕНСИОННЫЙ ФОНД (КОНСЕРВАТИВНЫЙ ИНВЕСТИЦИОННЫЙ ПОРТФЕЛЬ)</Option>
              <Option value="14">ПЕНСИОННЫЙ ФОНД (УМЕРЕННЫЙ ИНВЕСТИЦИОННЫЙ ПОРТФЕЛЬ)</Option>
              <Option value="15">СТРАХОВАЯ ОРГАНИЗАЦИЯ В ОТРАСЛИ "СТРАХОВАНИЕ ЖИЗНИ"</Option>
              <Option value="16">ПЕНСИОННЫЙ ФОНД (ИНВЕСТИЦИОННЫЙ ПОРТФЕЛЬ)</Option>

            </Select>
          </Form.Item>

          <Form.Item
              label={'Тип субсчета в ЦД'}
              name="aCD_Account_Type_ID"
              rules={[{required: true}]}
          >
            <Select>
              <Option value="1">СУБСЧЕТ ДЕРЖАТЕЛЯ</Option>
              <Option value="2">СУБСЧЕТ ЭМИТЕНТА ДЛЯ УЧЕТА ОБЪЯВЛЕННЫХ ЦЕННЫХ БУМАГ</Option>
              <Option value="3">СУБСЧЕТ ЭМИТЕНТА ДЛЯ УЧЕТА ВЫКУПЛЕННЫХ ЦЕННЫХ БУМАГ</Option>
              <Option value="4">УПРАВЛЯЮЩИЙ ИНВЕСТ. ПОРТФЕЛЕМ</Option>
              <Option value="5">СУБСЧЕТ НОМИНАЛЬНОГО ДЕРЖАТЕЛЯ</Option>
              <Option value="6">СУБСЧЕТ ДЕПОНЕНТА</Option>
              <Option value="7">СУБСЧЕТ ДЕПОНЕНТА ДЛЯ УЧЕТА ОБЪЯВЛЕННЫХ ЦЕННЫХ БУМАГ</Option>
              <Option value="8">СУБСЧЕТ ДЕПОНЕНТА ДЛЯ УЧЕТА ВЫКУПЛЕННЫХ СОБСТВЕННЫХ ЦЕННЫХ БУМАГ</Option>

            </Select>
          </Form.Item>
          <Form.Item
              label={'Банк, в котором открыт внешний счёт'}
              name="a58OBankID"
              rules={[{required: true}]}
          >
            <Select options={AISData.bankList}>

            </Select>
          </Form.Item>
          <Form.Item
              label={'IBAN (для иностранных банков) ИИК для банков РК'}
              name="a58OIBAN"
          >
            <Input/>
          </Form.Item>

        </Card>

      </Form>}
    </Modal>
  </>
}
const AISMTOModal = ({userData, cb = () => {}}) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [AISData, setAISData] = useState(null);
  const [load, setLoad] = useState(null);
  const [error, setError] = useState({});

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit()
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const setRecipientData = (value)=>{

    const ft = AISData. allFtIds.find(el=>el.FT_ID===Number(value));
    let d = {};

    d['vRecipientBank'] = ft.HOLDING_PLACE
    // d['vBik'] = ft.HOLDING_PLACE
    d['vRecipientIic'] = ft.IBAN
    d['vCardAccount'] = ft.IBAN


    // let d = {};
    // if(aNaturalPersonBool!==1 && aNaturalPersonBool!=='1'){
    //   d['aECONOMICS_SECTOR_CODE'] = '';
    // }
    // else {
    //   d['aECONOMICS_SECTOR_CODE'] = '9';
    // }
    form.setFieldsValue(d);

  }

  const aISDataGet = useCallback(() => {
    setAISData(null);
    apiQuery("/admin/aisMTODataCollect", {
      method: 'POST',
      body: {userId: userData._id}
    })
        .then((data) => {

          console.log(data)

          if (data.error) {
            setIsModalVisible(false);
            return message.error(data.error.msg)
          }
          setError(data.errorData)
          delete data.errorData;
          setAISData(data);

          if (data.provider_name) form.setFieldsValue({provider_name: data.provider_name});

          for (let key of Object.keys(data.aisData)) {
            let d = {};
            d[key] = data.aisData[key];
            form.setFieldsValue(d);


          }
        })
        .catch((error) => {
          setAISData(false);
          apiQueryError({error});
        })
  }, [userData, form])

  const onFinishAisData = (values) => {
    setLoad(true)
    console.log(values)
    values.userId = userData._id;
    apiQuery("/admin/aisMTODataSave", {
      method: 'POST',
      body: values
    })
        .then((data) => {
          setLoad(false)
          if (data.error) return message.error(data.error.msg);
          console.log(data)
          message.success('Данные отправлены');
          setIsModalVisible(false);
          cb()
        })
        .catch((error) => {
          setLoad(false)
          apiQueryError({error});
        })
  }

  const errorView = (key) => {
    if (!error[key]) return null;
    return <div style={{
      color: "red",
      fontSize: 12,
      marginBottom: 15
    }}>{error[key].join(', ')}</div>
  }

  useEffect(() => {
    if (isModalVisible) aISDataGet()
  }, [isModalVisible, aISDataGet])

  return <>
    <Button type="primary"
            style={{marginBottom: 15}}
            onClick={showModal}
    >
      Открыть перевод средств
    </Button>

    <Modal title={'Перевод средств в Аис'}
           visible={isModalVisible}
           okText={'Сохранить и отправить'}
           onOk={handleOk}
           onCancel={handleCancel}
           width={800}
           confirmLoading={load}
    >
      {AISData === null && <><Spin/> Идет сбор данных...</>}
      {AISData === false && <>Нет данных</>}
      {AISData && <Form
          form={form}
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinishAisData}
          scrollToFirstError={true}
      >
        <Card size="small" title="" style={{marginBottom: 15}}>


          <Form.Item
              label={'ID клиента'}
              name="p_userId"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'Дата поручения'}
              name="vMTO_Date"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'код валюты'}
              name="vCurrency"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'сумма списания'}
              name="vAmount"
          >
            <Input/>
          </Form.Item>

          <Form.Item label={'Исходящий счет'} name="vFT_ID" rules={[{required: true}]}>
            <Select  options={AISData.nonZeroFtIdsOpts}>
            </Select>
          </Form.Item>
          <Form.Item
              label={'Счет получателя'}
              name="vTo_FT_ID"
          >
            <Select options={AISData.allFtIdsOpts} onChange={setRecipientData}>
            </Select>
          </Form.Item>

          <Form.Item
              label={'ФИО получателя'}
              name="vRecipient"
          >
            <Input/>
          </Form.Item>


          <Form.Item
              label={'Банк получателя'}
              name="vRecipientBank"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'BIK банка получателя'}
              name="vBik"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'ИИК/расчетный счет'}
              name="vRecipientIic"
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Л/счет (Картсчет) физ.лица'}
              name="vCardAccount"
          >
            <Input/>
          </Form.Item>

          <Form.Item
              label={'ИИН'}
              name="vRecipientIIN"
          >
            <Input/>
          </Form.Item>




        </Card>

      </Form>}
    </Modal>
  </>
}

const AIS = ({userData}) => {

  const [AISData, setAISData] = useState(false);
  const aISDataGet = useCallback(() => {
    apiQuery("/admin/aisDataView", {
      method: 'POST',
      body: {userId: userData._id}
    })
        .then((data) => {

          console.log(data, 'data')

          if (data?.error) return message.error(data.error.msg)
          setAISData(data);
        })
        .catch((error) => {
          setAISData(false);
          apiQueryError({error});
        })
  }, [userData])

  useEffect(() => {
    aISDataGet()
  }, [aISDataGet])

  if (AISData === false) return <Spin/>
  if (AISData === null) return <>
    <AISModal userData={userData} cb={() => {
      aISDataGet()
    }}/>
  </>

  console.log(AISData)

  console.log(AISData.documentsTypes.find(el=>el.value===AISData.aisData.aDocumentType))

  return <>
    <Space>
      <AISModal userData={userData} cb={() => {
        aISDataGet()
      }}/>
      <AISMTOModal userData={userData} cb={() => {
        aISDataGet()
      }}/>
      <ActionsHistory userData={userData} mode={'aisForm'} style={{marginBottom: 15}}/>
    </Space>

    <p>Дата изменения: {AISData?.aisData?.updatedAt ? moment(AISData.aisData.updatedAt).format('YYYY-MM-DD HH:mm:ss') : '-'}</p>
    <p>Менеджер: {AISData?.manager?.email || '-'}</p>
    <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
    >
      <Collapse.Panel key={'1'} header={''}>
        <Descriptions>
          <Descriptions.Item span={3} label="Ошибка">{AISData.aisData.error || '-'}</Descriptions.Item>
          <Descriptions.Item span={3} label="Номер заявки в Аис">{AISData.aisData.aisTicketId || '-'}</Descriptions.Item>
          <Descriptions.Item label="Номер договора">{AISData.aisData.aContractNumber}</Descriptions.Item>
          <Descriptions.Item label="Дата договора">{AISData.aisData.aContractDate}</Descriptions.Item>
          <Descriptions.Item label="Лицо">{AISData.aisData.aNaturalPersonBool==1 ? 'Физ лицо' : 'Юр лицо'}</Descriptions.Item>
          <Descriptions.Item label="Резидент">{AISData.aisData.aResidentBool ==1 ? 'Резидент' : 'Не резидент'}</Descriptions.Item>
          <Descriptions.Item label="Фамилия">{AISData.aisData.aLastName}</Descriptions.Item>
          <Descriptions.Item label="Имя">{AISData.aisData.aFirstName}</Descriptions.Item>
          <Descriptions.Item label="Отчество">{AISData.aisData.aMiddleName}</Descriptions.Item>
          <Descriptions.Item label="Название Юр лица">{AISData.aisData.aJuridicalName}</Descriptions.Item>
          <Descriptions.Item label="Дата рождения">{AISData.aisData.aBorn}</Descriptions.Item>
          <Descriptions.Item label="Тип документа">{AISData.documentsTypes.find(el=>el.value===AISData.aisData.aDocumentType).label}</Descriptions.Item>
          <Descriptions.Item label="Серия документа">{AISData.aisData.aDocumentSeries}</Descriptions.Item>
          <Descriptions.Item label="Номер документа">{AISData.aisData.aDocumentNumber}</Descriptions.Item>
          <Descriptions.Item label="Дата выдачи">{AISData.aisData.aDocumentGivedDate}</Descriptions.Item>
          <Descriptions.Item label="Кем выдано">{AISData.documentGrantAuthorities.find(el=>el.value===AISData.aisData.aDocumentGivedBy).label}</Descriptions.Item>
          <Descriptions.Item label="Кем выдано">{AISData.aisData.aDocumentGivedByText}</Descriptions.Item>
          <Descriptions.Item label="Документ действует до">{AISData.aisData.aDocumentEndDate}</Descriptions.Item>
          <Descriptions.Item label="ИИН">{AISData.aisData.aIIN}</Descriptions.Item>
          <Descriptions.Item label="Страна проживания">{AISData.countries.find(el=>el.value===AISData.aisData.aCountryID).label}</Descriptions.Item>
          <Descriptions.Item label="Населённый пункт">{AISData.settlements.find(el=>el.value===AISData.aisData.aSettlementID)?.label || ''}</Descriptions.Item>
          <Descriptions.Item label="Название населённого пункта">{AISData.aisData.aSettlementText}</Descriptions.Item>
          <Descriptions.Item label="Тип населённого пункта">{AISData.settlementsTypes[AISData.aisData.aSettlementTypeID]}</Descriptions.Item>
          <Descriptions.Item label="Почтовый индекс">{AISData.aisData.aAddr_Index}</Descriptions.Item>
          <Descriptions.Item span={3} label="Адрес">{AISData.aisData.aAddress}</Descriptions.Item>
          <Descriptions.Item label="Телефон">{AISData.aisData.aPhones}</Descriptions.Item>
          <Descriptions.Item label="Мобильный телефон">{AISData.aisData.aMobilePhones}</Descriptions.Item>
          <Descriptions.Item label="Е-майл">{AISData.aisData.aEmail}</Descriptions.Item>
          <Descriptions.Item label="Банк, в котором открыт внешний счёт">{AISData.bankList.find(el=>el.value===AISData.aisData.aBankID)?.label || ''}</Descriptions.Item>
          <Descriptions.Item label="IBAN (для иностранных банков) ИИК для банков РК">{AISData.aisData.aIBAN}</Descriptions.Item>
          <Descriptions.Item label="Пол">{AISData.aisData.aSex==1 ? 'Муж' :'Жен'}</Descriptions.Item>
          <Descriptions.Item span={3} label="Фактический адрес">{AISData.aisData.aACTUAL_ADDRESS}</Descriptions.Item>
          <Descriptions.Item label="Фактический адрес (индекс)">{AISData.aisData.aACTUAL_ADDRESS_INDEX}</Descriptions.Item>
          <Descriptions.Item label="Гражданство">{AISData.countries.find(el=>el.value===AISData.aisData.aCITIZEN_COUNTRY_ID)?.label || ''}</Descriptions.Item>
          <Descriptions.Item label="Код сектора экономики">{AISData.ECONOMICS_SECTOR_CODES[AISData.aisData.aECONOMICS_SECTOR_CODE]}</Descriptions.Item>
          <Descriptions.Item label="Налоговые льготы">{AISData.aisData.aTAXPRIVS}</Descriptions.Item>
          <Descriptions.Item label="Форма собственности">{AISData.OWNERSHIP_PATTERN_IDs[AISData.aisData.aOWNERSHIP_PATTERN_ID]}</Descriptions.Item>
          <Descriptions.Item label="Вид держателя в ЦД">{AISData.HOLDER_TYPE_IN_CD_IDs[AISData.aisData.aHOLDER_TYPE_IN_CD_ID]}</Descriptions.Item>
          <Descriptions.Item label="Номер документа-основания">{AISData.aisData.aBase_Document_Number}</Descriptions.Item>
          <Descriptions.Item label="Дата документа-основания">{AISData.aisData.aBase_Document_Date}</Descriptions.Item>
          <Descriptions.Item label="Тип субсчета в ЦД">{AISData.CD_Account_Type_IDs[AISData.aisData.aCD_Account_Type_ID]}</Descriptions.Item>
          <Descriptions.Item label="Банк, в котором открыт внешний счёт">{AISData.bankList.find(el=>el.value===AISData.aisData.a58OBankID)?.label || ''}</Descriptions.Item>
          <Descriptions.Item label="IBAN (для иностранных банков) ИИК для банков РК">{AISData.aisData.a58OIBAN}</Descriptions.Item>
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  </>
}
const AlpacaOrders = ({userData}) => {

  const [AISData, setAISData] = useState(false);
  const aISDataGet = useCallback(() => {
    apiQuery("/admin/getAlpacaOrders", {
      method: 'POST',
      body: {userId: userData._id}
    })
        .then((data) => {

          console.log(data, 'data')

          if (data?.error) return message.error(data.error.msg)
          setAISData(data);
        })
        .catch((error) => {
          setAISData(false);
          apiQueryError({error});
        })
  }, [userData])

  const putOrderToAis = (orderId, symbol)=>{
    apiQuery("/admin/aisPutOrder", {
      method: 'POST',
      body: {orderId, userId: userData._id, symbol}
    })
        .then((data) => {

          console.log(data, 'data')

          if (data?.error) return message.error(data.error.msg)
          // setAISData(data);
          message.success('Ордер создан');

          putInDeal(orderId, symbol, data.result.replace('ORDER_ID=', ''))

        })
        .catch((error) => {
          // setAISData(false);
          apiQueryError({error});
        })
  }
  const putInDeal = (orderId, symbol, aisOrderId)=>{
    message.success('Создаю сделку');
    apiQuery("/admin/aisPutInDeal", {
      method: 'POST',
      body: {orderId, userId: userData._id, symbol, aisOrderId}
    })
        .then((data) => {

          console.log(data, 'data')

          if (data?.error) return message.error(data.error.msg)
          // setAISData(data);
          message.success('Сделка создана');
        })
        .catch((error) => {
          // setAISData(false);
          apiQueryError({error});
        })
  }

  useEffect(() => {
    aISDataGet()
  }, [aISDataGet])

  if (AISData === false) return <Spin/>
  if (AISData === null) return <></>
  const columns = [
    {
      title: ' Тикер',
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: 'Направление',
      dataIndex: 'side',
      key: 'side',
    },
    {
      title: 'Объем',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Количество',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Дата',
      key: 'order_date',
      dataIndex: 'order_date',
    },
    {
      title: 'Действие',
      key: 'action',
      render: (_, record) => (
          <Space size="middle">
            <a onClick={()=>{putOrderToAis(record.order_id, record.symbol)}}>Загрузить в аис</a>
          </Space>
      ),
    },
  ];

  return <>

    <Table columns={columns} dataSource={AISData} />
  </>
}

const User = ({mode}) => {
  const navigate = useNavigate();
  const params = useParams();
  const userId = params.userId ? params.userId : null;
  const [userData, setUserData] = useState(null);
  const [traderData, setTraderData] = useState(false);
  const [sumsubWebhookData, setSumsubWebhookData] = useState(false);
  const [agreementsData, setAgreementsData] = useState(false);
  const [documentsData, setDocumentsData] = useState(false);
  const [documentsNoLoad, setDocumentsNoLoad] = useState(0);
  const [docLoad, setDocLoad] = useState(false);
  const [docUpLoad, setDocUpLoad] = useState(false);
  const [userBlockLoad, setUserBlockLoad] = useState(false);
  const [traderLockLoad, setTraderLockLoad] = useState(false);
  const [pdlToggleLoad, setPdlToggleLoad] = useState(false);
  const [modalFileVisible, setModalFileVisible] = useState(false);
  const [modalFileData, setModalFileData] = useState(false);
  const [modalFileId, setModalFileId] = useState(false);

  const modalFile = ({visible, mode, fileData, fileId}) => {
    if (visible) {
      setModalFileData({mode, ...fileData})
      setModalFileId(fileId)
    } else {
      setModalFileData(false)
      setModalFileId(false)
    }
    setModalFileVisible(visible)
  }

  const userGet = useCallback(() => {
    apiQuery("/admin/user/" + userId)
      .then((data) => {
        setUserData(data.userData);
        const modeUrl = {'live': 'Prod', 'sandbox':'Test'}
        if (!mode || mode !== data.userData.mode) navigate(`/users${modeUrl[data.userData.mode]}/${data.userData.id}`)
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }, [userId])

  const traderDataGet = useCallback(() => {
    apiQuery("/admin/traderData/" + userData._id)
      .then((data) => {
        setTraderData(data);
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }, [userData])

  const agreementsGet = useCallback(({page = 1}) => {
    apiQuery("/admin/agreements/list", {
      method: 'POST',
      body: {
        page,
        userId: userData._id
      }
    })
      .then((data) => {
        setAgreementsData(data);
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }, [userData])

  const documentsNoUploadCountGet = useCallback(() => {
    apiQuery("/admin/documentsNoUploadCount", {
      method: 'POST',
      body: {
        userId: userData._id
      }
    })
      .then((data) => {
        if (data?.error) return message.error(data?.error.msg)
        setDocumentsNoLoad(data.count)
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }, [userData])
  const documentsGet = useCallback(({page = 1}) => {
    apiQuery("/admin/documents/list", {
      method: 'POST',
      body: {
        page,
        userId: userData._id
      }
    })
      .then((data) => {
        setDocumentsData(data);
        documentsNoUploadCountGet()
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }, [userData])
  const documentsLoad = useCallback(() => {
    setDocLoad(true)
    apiQuery("/admin/documents/load", {
      method: 'POST',
      body: {
        userId: userData._id
      }
    })
      .then(() => {
        documentsGet({});
        agreementsGet({});
        setDocLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        setDocLoad(false)
      });
  }, [userData, documentsGet])
  const documentsUpload = useCallback(() => {
    setDocUpLoad(true)
    apiQuery("/admin/alpacaDocumentUpload", {
      method: 'POST',
      body: {
        userId: userData._id
      }
    })
      .then((data) => {
        if (data?.error) {
          setDocUpLoad(false)
          return message.error(data.error.msg)
        }
        documentsGet({});
        setDocUpLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        setDocUpLoad(false)
      });
  }, [userData, documentsGet])
  const documentsStatusUpload = useCallback(({id, status}) => {
    console.log('documentsStatusUpload', {id, status, userData})
    apiQuery("/admin/documentsStatusUpload", {
      method: 'POST',
      body: {
        userId: userData._id,
        id,
        status
      }
    })
      .then((data) => {
        if (data?.error) {
          return message.error(data.error.msg)
        }
        documentsGet({});
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }, [userData, documentsGet])

  const docColumns = useMemo(() => [
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => {
        return (
          <>
            <div>{record.docType}</div>
          </>
        );
      },
    },
    {
      title: "Файл",
      dataIndex: "file",
      key: "file",
      render: (text, record, index) => {
        return (
          <div style={{cursor: "pointer"}} onClick={() => {
            modalFile({visible: true, mode: 'documentFile', fileData: record.fileData, fileId: record._id})
          }}>
            {['png', 'jpg', 'jpeg'].includes(record.fileData.ext) &&
              <ImagePreview src={`https://${Utils.apiDomain()}/rest_api/admin/documentFile/${record._id}`}/>}
            {!['png', 'jpg', 'jpeg'].includes(record.fileData.ext) && <FileIcon ext={record.fileData.ext} style={{
              fontSize: 30,
              border: 0,
              boxShadow: 'none',
              width: 'inherit',
              height: 'inherit',
              backgroundColor: 'transparent'
            }}/>}
          </div>
        );
      },
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => {
        return (
          <>
            <span style={{fontSize: 12}}>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
          </>
        );
      },
    },
    {
      title: "Загружен в Alpaca",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record, index) => {
        return (
          <>
            {uploadAlpaca[record.uploadAlpaca]}
          </>
        );
      },
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      render: (text, record, index) => {
        return (
          <>
            {record.docComment}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        return (
          <>
            {record.docStatus === 'noStatus' && <></>}
            {record.docStatus === 'confirmationRequest' && <Space>
              <ConfirmPass btnType={'default'} btnText={'Подтвердить'} onFinish={() => documentsStatusUpload({id: record._id, status: 'confirmed'})}/>
              <ConfirmPass btnType={'default'} danger={true} btnText={'Отклонить'} onFinish={() => documentsStatusUpload({id: record._id, status: 'canceled'})}/>
            </Space>}
            {record.docStatus === 'confirmed' && <Tag color="green">Подтвержден</Tag>}
            {record.docStatus === 'canceled' && <Tag color="red">Отклонен</Tag>}
          </>
        );
      },
    },
  ], [userData, documentsStatusUpload]);

  const agrColumns = useMemo(() => [
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => {
        return (
          <>
            <div>{record.docType}</div>
          </>
        );
      },
    },
    {
      title: "Файл",
      dataIndex: "file",
      key: "file",
      render: (text, record, index) => {
        return (
          <>
            <div style={{cursor: "pointer"}} onClick={() => {
              modalFile({visible: true, mode: 'agreementFile', fileData: record.fileData, fileId: record._id})
            }}>
              {['png', 'jpg', 'jpeg'].includes(record.fileData.ext) &&
                <ImagePreview src={`https://${Utils.apiDomain()}/rest_api/admin/agreementFile/${record._id}`}/>}
              {!['png', 'jpg', 'jpeg'].includes(record.fileData.ext) && <FileIcon ext={record.fileData.ext} style={{
                fontSize: 30,
                border: 0,
                boxShadow: 'none',
                width: 'inherit',
                height: 'inherit',
                backgroundColor: 'transparent'
              }}/>}
            </div>
          </>
        );
      },
    },
    {
      title: "Дата",
      dataIndex: "signDate",
      key: "signDate",
      render: (text, record, index) => {
        return (
          <>
            {!record.signDate && <>Нет отметки</>}
            {!!record.signDate && <span style={{fontSize: 12}}>{moment(record.signDate).format('YYYY-MM-DD HH:mm:ss')}</span>}
          </>
        );
      },
    },
  ],[]);

  const sumsubWebhookGet = useCallback(({page = 1}) => {
    apiQuery("/admin/sumsubWebhook/list", {
      method: 'POST',
      body: {
        page,
        userId: userData._id
      }
    })
      .then((data) => {
        setSumsubWebhookData(data);
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }, [userData])

  const onTraderLock = (blocked, data) => {
    setTraderLockLoad(true)
    apiQuery("/admin/traderLock", {
      method: 'POST',
      body: {blocked, userId: userData._id, data}
    })
      .then((data) => {
        if (data?.error) return message.error(data.error.msg)
        userGet()
        setTraderLockLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        console.error(error);
        setTraderLockLoad(false)
      });
  }

  const onUserBlock= (block, data) => {
    setUserBlockLoad(true)
    apiQuery("/admin/userBlock", {
      method: 'POST',
      body: {block, userId: userData._id, data}
    })
      .then((data) => {
        if (data?.error) return message.error(data.error.msg)
        userGet()
        setUserBlockLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        console.error(error);
        setUserBlockLoad(false)
      });
  }

  const onBrokerFormBlock= (block, data) => {
    setUserBlockLoad(true)
    apiQuery("/admin/brokerFormBlock", {
      method: 'POST',
      body: {block, userId: userData._id, data}
    })
      .then((data) => {
        if (data?.error) return message.error(data.error.msg)
        userGet()
        setUserBlockLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        console.error(error);
        setUserBlockLoad(false)
      });
  }

  const onPdl = (type, data) => {
    setPdlToggleLoad(true)
    apiQuery("/admin/pdlToggle", {
      method: 'POST',
      body: {type, userId: userData._id, data}
    })
      .then((data) => {
        if (data?.error) return message.error(data.error.msg)
        userGet()
        setPdlToggleLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        console.error(error);
        setPdlToggleLoad(false)
      });
  }

  const onTabsChange = (tab) => {
    if (tab === '2') sumsubWebhookGet({})
    if (tab === '5') documentsGet({})
    if (tab === '8') agreementsGet({})
  }

  useEffect(() => {
    userGet()
  }, [userGet, userId]);

  useEffect(() => {
    if (userData) {
      traderDataGet()
      documentsNoUploadCountGet()
    }
  }, [userData, traderDataGet, documentsNoUploadCountGet])

  if (!userData) return <p>Загрузка...</p>;


  const items = [
    {
      key: '1',
      label: <>
        {!traderData && <FontAwesomeIcon icon={faCircle} color={'#6b6b6b'}/>}
        {traderData && traderData?.error && <FontAwesomeIcon icon={faTriangleExclamation} color={'#cb5400'}/>}
        {traderData && traderData.status === 0 && <FontAwesomeIcon icon={faPenToSquare} color={'#00adcb'}/>}
        {traderData && traderData.status === 1 && <FontAwesomeIcon icon={faCheck} color={'#618f00'}/>}
        {' '}Broker Form
      </>,
      children: <>
        {traderData === false && <Spin/>}
        {!traderData && <Alert message={'Пользователь не приступал к заполнению формы'} showIcon type="warning"/>}
        {traderData && !traderData?.error && <>
          {traderData.status === 0 && <>
            <Alert message={'Данные в процесе заполнения пользователем'} showIcon type="info" style={{marginBottom: 15}}/>
            <ConfirmPass2 btnText={'Отклонить заявку'}
                          btnType={'default'}
                          mode={'brokerForm'}
                          danger={true}
                          fieldCommentVisible={true}
                          fieldFileVisible={false}
                          onFinish={(data) => {onBrokerFormBlock(true, data)}}/>
          </>}
          {traderData.status === 3 && <Alert message={'Заявка на верификацию личности отклонена'}
                                             showIcon
                                             type="warning"
                                             style={{marginBottom: 15}}
          />}
          {traderData.status === 1 && <Space style={{marginBottom:15}}>
            <BrokerFormEdit userData={userData} onSuccess={() => {traderDataGet()}}/>
            {/*<BrokerHistory userData={userData}/>*/}
            <ActionsHistory userData={userData} mode={'brokerForm'}/>
            <ConfirmPass2 btnText={'Отклонить заявку'}
                          btnType={'default'}
                          mode={'brokerForm'}
                          danger={true}
                          fieldCommentVisible={true}
                          fieldFileVisible={false}
                          onFinish={(data) => {onBrokerFormBlock(true, data)}}/>
          </Space>}
          <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
          >
            <Collapse.Panel key={'1'} header={'Личный профайл'}>
              <Descriptions>
                <Descriptions.Item
                    label="Имя">{traderData?.data?.questionnaire?.personalProfile?.name}</Descriptions.Item>
                <Descriptions.Item
                    label="Фамилия">{traderData?.data?.questionnaire?.personalProfile?.surname}</Descriptions.Item>
                <Descriptions.Item
                    label="Отчество">{traderData?.data?.questionnaire?.personalProfile?.patronymic}</Descriptions.Item>
                <Descriptions.Item
                    label="Email">{traderData?.data?.questionnaire?.personalProfile?.email}</Descriptions.Item>
                <Descriptions.Item
                    label="Номер телефона">{traderData?.data?.questionnaire?.personalProfile?.phoneNumber}</Descriptions.Item>
                <Descriptions.Item
                    label="Гражданство">{traderData?.data?.questionnaire?.personalProfile?.citizenship}</Descriptions.Item>
                <Descriptions.Item
                    label="Дата рождения">{traderData?.data?.questionnaire?.personalProfile?.dob}</Descriptions.Item>
                <Descriptions.Item
                    label="ИИН">{traderData?.data?.questionnaire?.personalProfile?.iin}</Descriptions.Item>
                <Descriptions.Item
                    label="Вид документа">{traderData?.data?.questionnaire?.personalProfile?.docType}</Descriptions.Item>
                <Descriptions.Item
                    label="Номер документа">{traderData?.data?.questionnaire?.personalProfile?.docNumber}</Descriptions.Item>
                <Descriptions.Item
                    label="Дата выдачи">{traderData?.data?.questionnaire?.personalProfile?.dateOfIssue}</Descriptions.Item>
                <Descriptions.Item
                    label="Кем выдано">{traderData?.data?.questionnaire?.personalProfile?.issueAuthority}</Descriptions.Item>
                <Descriptions.Item
                    label="Срок действия">{traderData?.data?.questionnaire?.personalProfile?.validity}</Descriptions.Item>
                <Descriptions.Item
                    label="Страна">{traderData?.data?.questionnaire?.personalProfile?.country}</Descriptions.Item>
                <Descriptions.Item
                    label="Город">{traderData?.data?.questionnaire?.personalProfile?.city}</Descriptions.Item>
                <Descriptions.Item
                    label="Адрес проживания">{traderData?.data?.questionnaire?.personalProfile?.addressOfResidence}</Descriptions.Item>
                <Descriptions.Item
                    label="Дом/Квартира">{traderData?.data?.questionnaire?.personalProfile?.houseApartment}</Descriptions.Item>
                <Descriptions.Item
                    label="Почтовый индекс">{traderData?.data?.questionnaire?.personalProfile?.postcode}</Descriptions.Item>
                <Descriptions.Item
                    label="Полный адресс">{!userData?.addressFull?.error ? userData?.addressFull : ''}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
            <Collapse.Panel key={'2'} header={'Финансовый профайл'}>
              <Descriptions>
                <Descriptions.Item
                    label="Годовой доход">{traderData?.data?.questionnaire?.financialProfile?.annualIncome}</Descriptions.Item>
                <Descriptions.Item
                    label="Чистые активы">{traderData?.data?.questionnaire?.financialProfile?.netAssets}</Descriptions.Item>
                <Descriptions.Item
                    label="Ликвидные активы">{traderData?.data?.questionnaire?.financialProfile?.liquidAssets}</Descriptions.Item>
                <Descriptions.Item
                    label="Источник финансирования" span={3}>
                  {traderData?.data?.questionnaire?.financialProfile?.sourceOfFinancing.map((item, index) =>
                      <span key={index}>{item === 'Прочее' ? `${item} (${traderData?.data?.questionnaire?.financialProfile?.sourceOfFinancingOther})` : item}{index < traderData?.data?.questionnaire?.financialProfile?.sourceOfFinancing.length-1 && <>,&nbsp;</>}</span>)}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Занятость" span={3}>{traderData?.data?.questionnaire?.financialProfile?.employment}</Descriptions.Item>
              </Descriptions>

              {['employed', 'selfEmployed'].includes(traderData?.data?.questionnaire?.financialProfile?.employment) && <Descriptions>
                <Descriptions.Item
                    label="Наименование организации">{traderData?.data?.questionnaire?.financialProfile?.nameOfTheOrganization}</Descriptions.Item>
                <Descriptions.Item
                    label="Адрес организации">{traderData?.data?.questionnaire?.financialProfile?.organizationAddress}</Descriptions.Item>
                <Descriptions.Item
                    label="Должность">{traderData?.data?.questionnaire?.financialProfile?.job}</Descriptions.Item>
              </Descriptions>}
            </Collapse.Panel>
            <Collapse.Panel key={'3'} header={<>Публичное должностное лицо (ПДЛ) {!traderData?.data?.questionnaire?.publicOfficial?.pdlCheck && <WarningOutlined style={{color: '#ff2a2a', fontSize: 19}}/>}</>}>
              {traderData?.data?.questionnaire?.publicOfficial?.pdl && <>
                <Space>
                  {!traderData?.data?.questionnaire?.publicOfficial?.pdlCheck && <ConfirmPass2 btnText={'Принять'}
                                btnType={'default'}
                                mode={'pdl'}
                                danger={true}
                                loading={pdlToggleLoad}
                                fieldCommentVisible={true}
                                fieldFileVisible={true}
                                fieldFileRequired={true}
                                onFinish={(data) => {onPdl('check', data)}}/>}
                  <ConfirmPass2 btnText={'Изменить'}
                                btnType={'default'}
                                mode={'pdl'}
                                danger={true}
                                loading={pdlToggleLoad}
                                fieldCommentVisible={true}
                                fieldFileVisible={true}
                                fieldFileRequired={true}
                                onFinish={(data) => {onPdl('toggle', data)}}/>
                  <ActionsHistory userData={userData} mode={'pdl'}/>
                </Space>
              </>}
              {traderData?.data?.questionnaire?.publicOfficial?.pdl === 'nobody' && <h4>Не является ПДЛ</h4>}
              {traderData?.data?.questionnaire?.publicOfficial?.pdl === 'me' && <>
                <h4>Является ПДЛ</h4>
                {traderData?.data?.questionnaire?.publicOfficial?.me && traderData?.data?.questionnaire?.publicOfficial?.me.map((item, index) => <Descriptions key={index}>
                  <Descriptions.Item
                      label="Название организации">{item?.nameOfTheOrganization}</Descriptions.Item>
                  <Descriptions.Item
                      label="Адрес организации">{item?.organizationAddress}</Descriptions.Item>
                  <Descriptions.Item
                      label="Должность">{item?.job}</Descriptions.Item>
                </Descriptions>)}
              </>}
              {traderData?.data?.questionnaire?.publicOfficial?.pdl === 'family' && <>
                <h4>Члены семьи являются ПДЛ</h4>
                {traderData?.data?.questionnaire?.publicOfficial?.family.map((item, index) => <Descriptions key={index}>
                  <Descriptions.Item
                      label="Степень родства либо статус по отношению к ПДЛ">{item.relationship}</Descriptions.Item>
                  <Descriptions.Item
                      label="ФИО ПДЛ">{item.fullName}</Descriptions.Item>
                  <Descriptions.Item
                      label="Название организации">{item.nameOfTheOrganization}</Descriptions.Item>
                  <Descriptions.Item
                      label="Адрес организации">{item.organizationAddress}</Descriptions.Item>
                  <Descriptions.Item
                      label="Должность">{item.job}</Descriptions.Item>
                </Descriptions>)}
              </>}
            </Collapse.Panel>
            <Collapse.Panel key={'4'} header={<>Определение риск аппетита {userData?.addData?.financialRating !== undefined && <RatingBallsStatus ball={userData.addData.financialRating || 0}/>}</>}>
              <Descriptions>
                <Descriptions.Item span={3}
                                   label="С каким инвестиционными услугами Вы знакомы?">
                  {traderData?.data?.questionnaire?.investmentExperience?.investmentServices.map((item, index) =>
                      <span key={index}>{item === 'Прочее' ? `${item} (${traderData?.data?.questionnaire?.investmentExperience?.investmentServicesOther})` : item}<RatingBallStatus section={'investmentServices'} value={item}/>{index < traderData?.data?.questionnaire?.investmentExperience?.investmentServices.length-1 && <>,&nbsp;</>}</span>)}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Акции и ETF">
                  {traderData?.data?.questionnaire?.investmentExperience?.investmentExp}
                  <RatingBallStatus section={'investmentExp'} value={traderData?.data?.questionnaire?.investmentExperience?.investmentExp}/>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Цифровые активы">
                  {traderData?.data?.questionnaire?.investmentExperience?.digitalAssets}
                  <RatingBallStatus section={'digitalAssets'} value={traderData?.data?.questionnaire?.investmentExperience?.digitalAssets}/>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Паевые инвестиционные фонды (ПИФы)">
                  {traderData?.data?.questionnaire?.investmentExperience?.uifs}
                  <RatingBallStatus section={'uifs'} value={traderData?.data?.questionnaire?.investmentExperience?.uifs}/>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Производные ценные продукты/продукты с кредитным плечом">
                  {traderData?.data?.questionnaire?.investmentExperience?.products}
                  <RatingBallStatus section={'products'} value={traderData?.data?.questionnaire?.investmentExperience?.products}/>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Количество инвестиционных сделок за последний год">
                  {traderData?.data?.questionnaire?.investmentExperience?.numDealsYear}
                  <RatingBallStatus section={'numDealsYear'} value={traderData?.data?.questionnaire?.investmentExperience?.numDealsYear}/>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Имеете ли Вы какое-либо образование или квалификацию в области финансовых услуг?">
                  {traderData?.data?.questionnaire?.investmentExperience?.eduFinancialServices}
                  <RatingBallStatus section={'eduFinancialServices'} value={traderData?.data?.questionnaire?.investmentExperience?.eduFinancialServices}/>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Имеете ли Вы какой-либо опыт работы в области финансовых услуг?">
                  {traderData?.data?.questionnaire?.investmentExperience?.expFinancialServices  === 'Прочее' ? `Прочее (${traderData?.data?.questionnaire?.investmentExperience?.expFinancialServicesOther})` : traderData?.data?.questionnaire?.investmentExperience?.expFinancialServices}
                  <RatingBallStatus section={'expFinancialServices'} value={traderData?.data?.questionnaire?.investmentExperience?.expFinancialServices}/>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Какое утверждение лучше всего описывает Ваши знания об инвестициях?">
                  {traderData?.data?.questionnaire?.investmentExperience?.investmentKnowledge}
                  <RatingBallStatus section={'investmentKnowledge'} value={traderData?.data?.questionnaire?.investmentExperience?.investmentKnowledge}/>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Когда вы слышите слово риск в контексте ваших финансов, какая первая мысль приходит на ум?">
                  {traderData?.data?.questionnaire?.investmentExperience?.firstThought}
                  <RatingBallStatus section={'firstThought'} value={traderData?.data?.questionnaire?.investmentExperience?.firstThought}/>
                </Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
            <Collapse.Panel key={'5'} header={'Дополнительная информация'}>
              <Descriptions>
                <Descriptions.Item span={3}
                                   label="Тип номера налогоплательщика">{traderData?.data?.questionnaire?.additionalInformation?.numberType}</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Вы или кто-либо из членов вашей непосредственной семьи являетесь должностным лицом или
            владеет 10% ...">{traderData?.data?.questionnaire?.additionalInformation?.relation1934US ? 'Да':'Нет'}</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Вы или кто-либо из членов вашей непосредственной семьи связаны или работают в фондовой
              бирже ...">{traderData?.data?.questionnaire?.additionalInformation?.relationFinanceUS ? 'Да':'Нет'}</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Каковы цель и характер деловых отношений с Geeko Invest Ltd.?">
                  {traderData?.data?.questionnaire?.purpose?.geekoAims.map((item, index) =>
                      <span key={index}>{item === 'Прочее' ? `${item} (${traderData?.data?.questionnaire?.purpose?.geekoAimsOther})` : item}{index < traderData?.data?.questionnaire?.purpose?.geekoAims.length-1 && <>,&nbsp;</>}</span>)}
                </Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
            {!!traderData?.data?.sign && <Collapse.Panel key={'6'} header={'Подпись'}>
              <img src={traderData?.data?.sign} width={80}/>
            </Collapse.Panel>}
            <Collapse.Panel key={'7'} header={'Контакты доверенных лиц'}>
              <Descriptions>
                <Descriptions.Item span={3}
                                   label="Имя">{traderData?.data?.trustedPerson?.name}</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Фамилия">{traderData?.data?.trustedPerson?.surname}</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Email">{traderData?.data?.trustedPerson?.email}</Descriptions.Item>
                <Descriptions.Item span={3}
                                   label="Телефон">{traderData?.data?.trustedPerson?.phone}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          </Collapse>

        </>}
      </>
    }, {
      key: '2',
      label: 'Sumsub profile',
      children: <>
        {!traderData?.data?.sumSub && <Alert message={'Нет данных sumsub'} showIcon type="warning"/>}
        {traderData?.data?.sumSub && <>
          <table style={{marginBottom: 20}}>
            <tr>
              <td>SumsubID:</td>
              <td>{traderData?.data?.sumSub?.applicantId ?
                  <a
                      href={`https://cockpit.sumsub.com/checkus#/applicant/${traderData?.data?.sumSub?.applicantId}/basicInfo`}
                      target={'_blank'} rel="noreferrer">
                    {traderData?.data?.sumSub?.applicantId}</a> : '-'}</td>
            </tr>
          </table>

          <Table dataSource={sumsubWebhookData.items}
                 columns={columns}
                 size="small"
                 onChange={(page, filters, sorter, extra) => {
                   console.log({page, filters, sorter, extra})
                   sumsubWebhookGet({page, filters, sorter, extra});
                 }}
                 pagination={{
                   pageSize: 10,
                   showSizeChanger: false,
                   total: sumsubWebhookData.itemsCount,
                 }}
          />
        </>}
      </>
    }, {
      key: '5',
      label: <>Documents <Badge count={documentsNoLoad} style={{
        backgroundColor: '#faad14',
      }}/></>,
      children: <>
        <Space>
          {[1].includes(userStore.userData.id) && <Button onClick={documentsLoad}
                                                          loading={docLoad}
                                                          style={{marginBottom: 15}}
          >Загрузить документы</Button>}
          <Button onClick={documentsUpload}
                  loading={docUpLoad}
                  style={{marginBottom: 15}}
          >Отправить незагруженые в Alpaca</Button>
          <DocumentFileUpload userData={userData} documentsGet={documentsGet}/>
        </Space>

        <Table dataSource={documentsData.items}
               columns={docColumns}
               size="small"
               onChange={(page, filters, sorter, extra) => {
                 console.log({page, filters, sorter, extra})
                 documentsGet({page, filters, sorter, extra});
               }}
               pagination={{
                 pageSize: 10,
                 showSizeChanger: false,
                 total: documentsData.itemsCount,
               }}
        />
      </>
    }, {
      key: '3',
      label: 'Alpaca profile',
      children: <>
        {!userData?.alpacaData?.accountId &&
        <Alert message={'Пользователь еще не зарегистрирован в Alpaca'} showIcon type="warning"
               style={{marginBottom: 15}}/>}
        {(!traderData || traderData.status === 0) &&
        <Alert message={'Пользователь еще не заполнил Broker form'} showIcon type="warning"
               style={{marginBottom: 15}}/>}
        {traderData?.status === 1 && !traderData?.data?.questionnaire?.publicOfficial?.pdlCheck &&
          <Alert message={'Проверьте раздел ПДЛ'} showIcon type="warning"
                 style={{marginBottom: 15}}/>}
        {traderData?.data?.questionnaire?.publicOfficial?.pdlCheck && traderData && traderData.status === 1 && <AlpacaProfile userData={userData}/>}
      </>
    }, {
      key: '4',
      label: 'Открытие счета',
      children: <>
        {!userData?.alpacaData?.accountId &&
        <Alert message={'Пользователь еще не зарегистрирован в Alpaca'} showIcon type="warning"
               style={{marginBottom: 15}}/>}
        {userData?.alpacaData?.accountId && <CIP userData={userData}/>}
      </>
    },
    {
      key: '6',
      label: 'Ais',
      children: <>
        {/*{!userData?.alpacaData?.accountId &&*/}
        {/*<Alert message={'Пользователь еще не зарегистрирован в Alpaca'} showIcon type="warning"*/}
        {/*       style={{marginBottom: 15}}/>}*/}
        {/*{userData?.alpacaData?.accountId && <AIS userData={userData}/>}*/}
        <AIS userData={userData}/>
      </>
    },
    // {
    //   key: '7',
    //   label: 'Alpaca orders',
    //   children: <>
    //     {/*{!userData?.alpacaData?.accountId &&*/}
    //     {/*<Alert message={'Пользователь еще не зарегистрирован в Alpaca'} showIcon type="warning"*/}
    //     {/*       style={{marginBottom: 15}}/>}*/}
    //     {/*{userData?.alpacaData?.accountId && <AIS userData={userData}/>}*/}
    //     <AlpacaOrders userData={userData}/>
    //   </>
    // },
    {
      key: '8',
      label: 'Agreements',
      children: <>
        <Space>
          <Button onClick={documentsLoad}
                  loading={docLoad}
                  style={{marginBottom: 15}}
          >Загрузить документы</Button>
        </Space>

        <Table dataSource={agreementsData.items}
               columns={agrColumns}
               size="small"
               onChange={(page, filters, sorter, extra) => {
                 console.log({page, filters, sorter, extra})
                 agreementsGet({page, filters, sorter, extra});
               }}
               pagination={{
                 pageSize: 10,
                 showSizeChanger: false,
                 total: agreementsData.itemsCount,
               }}
        />
      </>
    }, {
      key: '9',
      label: 'Role',
      children: <Role userData={userData}/>
    }
  ]

  // if(Utils.apiDomain()==='app2.geekoinvest.kz'){
  //   items.push(
  //       {
  //         key: '6',
  //         label: 'Ais',
  //         children: <>
  //           {/*{!userData?.alpacaData?.accountId &&*/}
  //           {/*<Alert message={'Пользователь еще не зарегистрирован в Alpaca'} showIcon type="warning"*/}
  //           {/*       style={{marginBottom: 15}}/>}*/}
  //           {/*{userData?.alpacaData?.accountId && <AIS userData={userData}/>}*/}
  //           <AIS userData={userData}/>
  //         </>
  //       }
  //   )
  // }


  return (
    <>
      <Breadcrumb style={{margin: "16px 0"}} items={[
        {title: <Link to={"/users"}>Пользователи</Link>},
        {title: <>Пользователь ID: {userId}</>},
      ]}>
      </Breadcrumb>

      <div>
        <Row>
          <Col flex={'140px'}>
            <ImagePreview src={`https://${Utils.apiDomain()}/rest_api/admin/documentFile/${userData?.profileData?.avatar}`} width={120} height={120} style={{borderRadius: 8}}/>
          </Col>
          <Col flex={'auto'}>
            <div style={{fontSize: 16, color: 'rgb(215, 215, 215)'}}>{userData?.profileData?.name}</div>
            <div style={{fontSize: 14, color: 'rgb(215, 215, 215)'}}>{userData.email}</div>

            <div style={{fontSize: 12, color: 'rgb(136, 136, 136)'}}>
              <table style={{fontSize: 12, color: 'rgb(136, 136, 136)'}}>
                <tbody>
                <tr>
                  <td>ID:</td>
                  <td>{userData.id}</td>
                </tr>
                <tr>
                  <td>SystemID:</td>
                  <td>{userData._id}</td>
                </tr>
                <tr>
                  <td>SumsubID:</td>
                  <td>{traderData?.data?.sumSub?.applicantId ?
                    <a
                      href={`https://cockpit.sumsub.com/checkus#/applicant/${traderData?.data?.sumSub?.applicantId}/basicInfo`}
                      target={'_blank'} rel="noreferrer">
                      {traderData?.data?.sumSub?.applicantId}</a> : '-'}</td>
                </tr>
                <tr>
                  <td>AlpacaID:</td>
                  <td>{userData?.alpacaData?.accountId ?
                    <a href={`https://broker-app.alpaca.markets/accounts/${userData?.alpacaData?.accountId}`}
                       target={'_blank'} rel="noreferrer">
                      {userData?.alpacaData?.accountId}</a> : '-'}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col flex={'200px'}>
            <h3 style={{marginTop: 5, marginBottom: 5}}>Status</h3>
            <table style={{fontSize: 12, color: 'rgb(136, 136, 136)', borderRight: '1px solid #333'}}>
              <thead/>
              <tbody>
              <tr>
                <td>KYC:</td>
                <td><StatusBlock status={!userData?.statusData?.kyc ? 'not started' : userData?.statusData.kyc}/></td>
              </tr>
              <tr>
                <td>Broker form:</td>
                <td><StatusBlock
                  status={!userData?.statusData?.brokerForm ? 'not started' : userData?.statusData.brokerForm}/></td>
              </tr>
              <tr>
                <td>Geeko trader:</td>
                <td><StatusBlock status={!userData?.statusData?.trader ? 'not created' : userData?.statusData.trader}/>
                </td>
              </tr>
              <tr>
                <td>Trading account:</td>
                <td><StatusBlock status={!userData?.statusData?.alpaca ? 'not created' : userData?.statusData.alpaca}/>
                </td>
              </tr>
              <tr>
                <td>Broker account:</td>
                <td><StatusBlock status={!userData?.statusData?.ais ? 'not created' : userData?.statusData.ais}/>
                </td>
              </tr>
              </tbody>
            </table>
          </Col>
          <Col flex={'170px'}>
            <p>
              <ConfirmPass2 btnText={!userData?.blocking ? 'Заблокировать пользователя' : 'Разблокировать пользователя'}
                            btnType={'default'}
                            mode={'blocking'}
                            danger={!userData?.blocking}
                            loading={userBlockLoad}
                            fieldCommentVisible={true}
                            fieldFileVisible={true}
                            onFinish={(data) => {onUserBlock(!userData?.blocking, data)}}/>
            </p>
            {(userData?.role?.trader === false || userData?.role?.trader === true) && <p>
              <ConfirmPass2 btnText={userData?.role?.trader ? 'Заблокировать торговлю' : 'Разблокировать торговлю'}
                            btnType={'default'}
                            mode={'blocking'}
                            danger={userData?.role?.trader}
                            loading={traderLockLoad}
                            fieldCommentVisible={true}
                            fieldFileVisible={true}
                            onFinish={(data) => onTraderLock(userData?.role?.trader, data)}/>
            </p>}
            <ActionsHistory userData={userData} mode={'blocking'}/>
          </Col>
        </Row>
      </div>

      <Tabs defaultActiveKey="1" onChange={onTabsChange} items={items}/>


      <PDFModal visible={modalFileVisible}
                fileData={modalFileData}
                fileId={modalFileId}
                onClose={() => setModalFileVisible(false)}
      />
    </>
  );
};

export default observer(User);