import {Breadcrumb, Button, Form, Modal, Input, message, Select} from "antd";
import {apiQuery, apiQueryError} from "../../Utils";
import {useState} from "react";

const Accrual = (params) => {

	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [AISData, setAISData] = useState(null);
	const [load, setLoad] = useState(null);
	const [error, setError] = useState({});
	const [ftIds, setFtIds] = useState([]);
	const testInv = params.testInv

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit()
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onFinish = (values) => {
		// values.userId = userData._id;
		console.log(values)
		//
		apiQuery("/admin/putFlow", {
			method: 'POST',
			body: {testInv, ...values},
		})
			.then((data) => {

				console.log(data)
				if (data.error) return message.error(data.error.msg)
				setIsModalVisible(false);
				message.success('Запрос отправлен')
				form.resetFields()
				// alpacaUserProfile()
				// cb()
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const searchClientProfile = (iin)=>{


		if(iin.length!==12){
			return
		}

		apiQuery("/admin/getClientProfile", {
			method: 'POST',
			body: {testInv, iin},
		})
			.then((data) => {

				console.log(data)

				if (data.error) return message.error(data.error.msg)
				if (!data) return message.error("Клиент не найден")
				form.setFieldValue('pContrLN', data?.user?.aisData?.aLastName);
				form.setFieldValue('pContrFN', data?.user?.aisData?.aFirstName);
				form.setFieldValue('pContrMN', data?.user?.aisData?.aMiddleName);

				const arr = data?.ftIds.map(el=>{return {value:el.FT_ID,label:el.HOLDING_PLACE+' '+el.CURRENCY+' '+el.IBAN }})

				setFtIds(arr)

				// form.setFieldValue('pFTID', data?.aisData?.enabled_assets);
				// cb()
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	return (
		<>
			<Breadcrumb style={{margin: '16px 0'}}>
				<Breadcrumb.Item>Пополнение</Breadcrumb.Item>
			</Breadcrumb>
			<div
				style={{
					minHeight: 360,
				}}
			>
				<Button type="primary"
				        style={{marginBottom: 15}}
				        onClick={showModal}
				>
					Открыть форму
				</Button>
				<Modal title={'Пополнение средств'}
				       visible={isModalVisible}
				       okText={'Сохранить и отправить'}
				       onOk={handleOk}
				       onCancel={handleCancel}
				       width={800}
				       confirmLoading={load}
				       >
				<Form
					form={form}
					// layout="vertical"
					labelCol={{
						span: 8,
					}}
					wrapperCol={{
						span: 16,
					}}
					onFinish={onFinish}
				>
					<Form.Item
						label={'ИИН'}
						name="pContrIIN"
						rules={[{required: true}]}
					>
						<Input onChange={(e)=>searchClientProfile(e.target.value)}/>
					</Form.Item>
					<Form.Item
						label={'Фамилия'}
						name="pContrLN"
						rules={[{required: true}]}
					>
						<Input/>
					</Form.Item>

					<Form.Item
						label={'Имя'}
						name="pContrFN"
						rules={[{required: true}]}
					>
						<Input/>
					</Form.Item>

					<Form.Item
						label={'Отчество'}
						name="pContrMN"
						rules={[{required: true}]}
					>
						<Input/>
					</Form.Item>
					<Form.Item
						label={'БИК банка контрагента'}
						name="pContrBank"
						rules={[{required: true}]}
					>
						<Input/>
					</Form.Item>
					<Form.Item
						label={'IBAN банка контрагента'}
						name="pContrAccount"
						rules={[{required: true}]}
					>
						<Input/>
					</Form.Item>
					<Form.Item
						label={'Сумма $'}
						name="pCredit"
						rules={[{required: true}]}
					>
						<Input/>
					</Form.Item>
					<Form.Item label={'Счет'} name="pFTID" rules={[{required: true}]}>
						<Select options={ftIds}>
						</Select>
					</Form.Item>
				</Form>

				</Modal>
			</div>
		</>
	)
}

export default Accrual;