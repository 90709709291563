import {configure, makeAutoObservable} from "mobx";
import {apiRequest} from "../../Utils";

configure({enforceActions: "never"})

class TickerStore {

  tickerData = {};
  tickerSignal = {};
  tickerRows = []; // список символов

  constructor() {
    makeAutoObservable(this)
  }

  setTicker(tickerId, data){
    this.tickerData[tickerId] = data;
    this.tickerData[tickerId].loadingTime = new Date().getTime();
    console.log(this.tickerData[tickerId])
  }

  setTickerSignal(tickerId, data){
    this.tickerSignal[tickerId] = data;
  }

  getTicker(tickerId, cache = true) {

    if (!this.tickerData[tickerId]) this.tickerData[tickerId] = {load: true};
    if (this.tickerData[tickerId].loadingTime && cache){
      const t = new Date().getTime()-120000;
      if (this.tickerData[tickerId].loadingTime > t ) return;
    }

    apiRequest({
      url: "/private/v1/ticker/"+tickerId,
      success: (data) => {
        if (!data) return this.tickerData[tickerId] = null;
        this.tickerData[tickerId] = data;
        this.tickerData[tickerId].loadingTime = new Date().getTime();
      }
    }).catch()
  }
}

export default new TickerStore()