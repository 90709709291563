import { configure, makeAutoObservable } from "mobx";
import { apiQueryError, apiRequest } from "../../Utils";

configure({ enforceActions: "never" });

class PortfolioStore {
  collectionData = {};

  favoritesEdit = false;
  favoritesRows = null;

  collectionMyEdit = false;
  collectionMyRows = null;

  collectionGeekoRows = null;

  constructor() {
    makeAutoObservable(this);
  }

  /*
  Загрузка данных коллекции
   */
  getCollection(collectionId, cache = true) {
    if (!this.collectionData[collectionId])
      this.collectionData[collectionId] = { load: true };
    if (this.collectionData[collectionId].loadingTime && cache) {
      const t = new Date().getTime() - 120000;
      if (this.collectionData[collectionId].loadingTime > t) return;
    }

    apiRequest({
      url: "/private/v1/portfolio/" + collectionId,
      success: (data) => {
        this.collectionData[collectionId] = data;
        this.collectionData[collectionId].loadingTime = new Date().getTime();
      },
    }).catch();
  }

  /*
  Включить/выключить редактирование избранного
   */
  setFavoritesEdit(v) {
    this.favoritesEdit = v;
  }
  /*
  Включить/выключить редактирование колекций
  */
  setCollectionMyEdit(v) {
    this.collectionMyEdit = v;
  }

  /*
  Загрузка избранного
   */
  favoritesLoad() {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/favorites",
        success: (data) => {
          const rows = [];
          for (let d of data) {
            rows.push(d.tickerId);
          }
          this.favoritesRows = rows;
          resolve(data);
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }
  /*
  Удаление символа из избранного
   */
  favoritesDel(tickerId) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/favorites/" + tickerId,
        method: "DELETE",
        success: () => {
          this.favoritesRows = this.favoritesRows?.filter(
            (tId) => tId !== tickerId
          );
          resolve();
          this.favoritesLoad().catch();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }
  /*
  Добавление символа в избранное
   */
  favoritesSet(tickerId) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/favorites",
        method: "POST",
        body: {
          tickerId,
        },
        success: () => {
          this.favoritesLoad().catch();
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  /*
  Загруска коллекций пользователя
   */
  collectionMyLoad() {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio",
        success: (data) => {
          const rows = [];
          for (let d of data) {
            this.collectionData[d._id] = d;
            this.collectionData[d._id].loadingTime = new Date().getTime();
            rows.push(d._id);
          }

          this.collectionMyRows = rows;
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  collectionSet(data) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio",
        method: "POST",
        body: data,
        success: (data) => {
          this.collectionMyLoad().catch();
          resolve(data);
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  collectionDel(collectionId) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio",
        method: "DELETE",
        body: {
          id: collectionId,
        },
        success: () => {
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  collectionTickerSet(collectionId, tickerId) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/ticker",
        method: "POST",
        body: {
          collectionId,
          tickerId,
        },
        success: () => {
          this.getCollection(collectionId, false);
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  collectionTickerDel(collectionId, tickerId) {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/ticker",
        method: "DELETE",
        body: {
          collectionId,
          tickerId,
        },
        success: () => {
          this.getCollection(collectionId, false);
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  /*
  Загруска коллекций гико
   */
  collectionGeekoLoad() {
    return new Promise((resolve) => {
      apiRequest({
        url: "/private/v1/portfolio/geeko",
        success: (data) => {
          const rows = [];
          for (let d of data) {
            this.collectionData[d._id] = d;
            this.collectionData[d._id].loadingTime = new Date().getTime();
            rows.push(d._id);
          }

          this.collectionGeekoRows = rows;
          resolve();
        },
        error: (error) => {
          apiQueryError({ error });
          resolve();
        },
      }).catch();
    });
  }

  ModalSearchVisible = false;

  //Modal Search
  openModalSearch() {
    this.ModalSearchVisible = true;
  }

  closeModalSearch() {
    this.ModalSearchVisible = false;
  }
}

export default new PortfolioStore();
