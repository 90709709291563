import {Breadcrumb, Table, Tabs, Input, Space, Select, Tag} from "antd";
import {useCallback, useEffect, useRef, useState} from "react";
import {apiQuery, apiQueryError} from "../../Utils";
import {Link} from "react-router-dom";
import moment from "moment";
import StatusBlock from "./StatusBlock";

const {TabPane} = Tabs;
const {Search} = Input;

const columns = [
  {
    title: "",
    dataIndex: "email",
    // key: "email",
    render: (text, record, index) => {
      return (
        <Link to={`/users/${record.id}`} key={index}>
          <div style={{color: 'rgb(215, 215, 215)'}}>{record.profileData.name}
            {' '}
            {record.mode === 'live' && <Tag color="green" style={{fontSize: 10, height:18, padding: '2px 4px', lineHeight: '11px'}}>live</Tag>}
            {/*{record.mode === 'sandbox' && <Tag color="gold" style={{fontSize: 10, height:18, padding: '2px 4px', lineHeight: '11px'}}>sandbox</Tag>}*/}
          </div>
          <div style={{fontSize: 12, color: 'rgb(215, 215, 215)'}}>{record.email}</div>
          <div style={{fontSize: 10, color: 'rgb(136, 136, 136)'}}>ID: {record.id} | _ID: {record._id}</div>
        </Link>
      );
    },
    // sorter: true,
  },
  {
    title: "Creation date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record, index) => {
      return <span>{moment(record.createdAt).format("DD.MM.YYYY")}</span>;
    },
    sorter: true,
  },
  {
    title: "KYC",
    dataIndex: "kyc",
    key: "statusData.kyc",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.kyc ? 'not started' : record?.statusData.kyc}/>;
    },
    sorter: true,
  },
  {
    title: "Broker form",
    dataIndex: "brokerForm",
    key: "statusData.brokerForm",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.brokerForm ? 'not started' : record?.statusData.brokerForm}/>;
    },
    sorter: true,
  },
  {
    title: "Trader",
    dataIndex: "trader",
    key: "statusData.trader",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.trader ? 'not created' : record?.statusData.trader}/>;
    },
    sorter: true,
  },
  {
    title: "Alpaca",
    dataIndex: "alpaca",
    key: "statusData.alpaca",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.statusData?.alpaca ? 'not created' : record?.statusData.alpaca}/>;
    },
    sorter: true,
  },
  {
    title: "Ais",
    dataIndex: "ais",
    key: "aisData",
    render: (text, record, index) => {
      return <StatusBlock status={!record?.aisData?.aIIN ? 'not created' : (record?.aisData?.aisTicketId ? 'completed' : 'in progress')}/>;
    },
    sorter: true,
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    render: (text, record, index) => {
      return <>
        {record.role && Object.keys(record.role).map((key) => {
          if (!record.role[key]) return null;
          return <StatusBlock status={key}/>
        })}
      </>;
    },
    sorter: true,
  },
];

const columns2 = [
  {
    title: "Сreation date",
    //dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record, index) => {
      return <span>{moment(record.createdAt).format("DD.MM.YYYY")}</span>;
    },
    sorter: true,
  },

  {
    title: "Name",
    dataIndex: "displayName",
    key: "displayName",
  },

  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Approved by",
    dataIndex: "Approved",
    key: "Approved",
  },
  {
    title: "Confirmation",
    dataIndex: "confirmation",
    // key: "email",
    render: (text, record, index) => {
      return (
        <Link to="/" className="confirm" key={index}>
          Confirm
        </Link>
      );
    },
    sorter: true,
  },
];

const columns3 = [
  {
    title: "Сreation date",
    //dataIndex: "createdAt",
    key: "createdAt",
    render: (text, record, index) => {
      return <span>{moment(record.createdAt).format("DD.MM.YYYY")}</span>;
    },
    sorter: true,
  },

  {
    title: "Name",
    dataIndex: "displayName",
    key: "displayName",
  },

  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Approved by",
    dataIndex: "Approved",
    key: "Approved",
  },
  {
    title: "Confirmmed by",
    dataIndex: "confirmmedby",
    key: "confirmmedby",
  },
];

const UserLive = () => {
  const [items, setItems] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterMode, setFilterMode] = useState('email&name');
  const [filterValue, setFilterValue] = useState('');
  const t = useRef();

  const usersGet = useCallback((data = {}) => {

    clearTimeout(t.current)
    t.current = setTimeout(() => {
      data.filterMode = filterMode;
      data.filterValue = filterValue;
      data.mode = 'live';
      console.log(data);
      apiQuery("/admin/users", {
        method: "POST",
        body: data,
      })
        .then((data) => {
          // const items = data.items.filter(item => item.mode == 'live');
          console.log(data);
          setItems(data.items);
          setItemsCount(data.itemsCount);
        })
        .catch((error) => {
          apiQueryError({error});
        });
    }, 500)

  }, [filterMode, filterValue]);

  const onSearch = () => {
    usersGet()
  }

  useEffect(() => {
    usersGet();
  }, [usersGet]);

  if (!items) return <>Загрузка...</>;

  // const filteredItems = items.filter(item => item.mode === 'live');

  return (
    <>
      <Breadcrumb style={{margin: "16px 0"}} items={[
        {title: 'Пользователи'}
      ]}/>
      <Tabs defaultActiveKey="1">
        <TabPane tab="User List" key="1">
          <div className="site-layout-background" style={{minHeight: 360}}>
            <Space.Compact style={{marginBottom: 15}}>
              <Select value={filterMode}
                      onChange={(v) => {
                        setFilterMode(v)
                        usersGet()
                      }}
                      options={[
                        {
                          value: 'email&name',
                          label: 'Email и ФИО',
                        },
                        {
                          value: 'id',
                          label: 'ID пользователя',
                        },
                        {
                          value: '_id',
                          label: 'SystemID пользователя',
                        },
                        {
                          value: 'alpacaId',
                          label: 'AlpacaID пользователя',
                        },
                      ]}/>
              <Search
                placeholder="input search text"
                allowClear
                value={filterValue}
                onChange={(v) => {
                  setFilterValue(v.target.value)
                }}
                onSearch={onSearch}
                style={{
                  width: 304,
                }}
              />
            </Space.Compact>

            <Table
              rowKey={'_id'}
              size="small"
              dataSource={items}
              columns={columns}
              onChange={(page, filters, sorter, extra) => {
                console.log({page, filters, sorter, extra})
                usersGet({page, filters, sorter, extra});
              }}
              pagination={{
                pageSize,
                showSizeChanger: true,
                onShowSizeChange: (current, ps) => setPageSize(ps),
                total: itemsCount,
              }}
            />
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

export default UserLive;
