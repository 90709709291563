import React from 'react';
import { Form, DatePicker, Select, Button, Input } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

const CommissionsForm = ({ onFinish }) => {
  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item name="dateRange" label="Период">
        <RangePicker />
      </Form.Item>
      <Form.Item name="commissionType" label="Тип комиссии">
        <Select placeholder="Выберите тип комиссии">
          <Option value="transfer">За перевод</Option>
          <Option value="deal">За сделку</Option>
        </Select>
      </Form.Item>
      <Form.Item name="iins" label="ИИНы">
        <Input placeholder="Введите ИИНы, разделенные запятой, если необходимо" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Выгрузить отчет</Button>
      </Form.Item>
    </Form>
  );
};

export default CommissionsForm;