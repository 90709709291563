import {Button, Collapse, DatePicker, Divider, Form, Input, Modal, Select} from "antd";
import {useCallback, useEffect, useState} from "react";
import dayjs from "dayjs";
import {observer} from "mobx-react-lite";
import {Spin} from "antd/lib";
import {apiQuery, apiQueryError} from "../../Utils";
import {CaretRightOutlined} from "@ant-design/icons";
import ConfirmPass from "../ConfirmPass/ConfirmPass";
import ConfirmPass2 from "../ConfirmPass/ConfirmPass2";

const FormItemInput = ({valueOld = '', value = {}, onChange}) => {
  const [text, setText] = useState('');
  const [comment, setComment] = useState('');
  const [edit, setEdit] = useState(false);

  const triggerChange = (changedValue) => {
    onChange?.({
      textOld: valueOld,
      text,
      comment,
      edit,
      ...value,
      ...changedValue,
    });
  };

  const onTextChange = (e) => {
    setText(e.target.value)
    setEdit(valueOld !== e.target.value)
    triggerChange({
      text: e.target.value,
      edit: valueOld !== e.target.value
    });
  }

  const onCommentChange = (e) => {
    setComment(e.target.value)
    triggerChange({
      comment: e.target.value,
    });
  }

  return <>
    <Input value={value.text || text} onChange={onTextChange}/>

    {edit && <div>
      <span style={{display: "block", fontSize: 11, marginTop: 5}}>Комментарий</span>
      <Input value={value.comment || comment} onChange={onCommentChange}/>
    </div>
    }

    {/*<Divider style={{marginBottom: 0}}/>*/}
  </>
}
const FormItemSelect = ({valueOld = '', value = {}, options = [], onChange}) => {
  const [text, setText] = useState('');
  const [comment, setComment] = useState('');
  const [edit, setEdit] = useState(false);

  const triggerChange = (changedValue) => {
    onChange?.({
      textOld: valueOld,
      text,
      comment,
      edit,
      ...value,
      ...changedValue,
    });
  };

  const onTextChange = (v) => {
    setText(v)
    setEdit(valueOld !== v)
    triggerChange({
      text: v,
      edit: valueOld !== v
    });
  }

  const onCommentChange = (e) => {
    setComment(e.target.value)
    triggerChange({
      comment: e.target.value,
    });
  }

  return <>
    <Select value={value.text || text} options={options} onChange={onTextChange}/>

    {edit && <div>
      <span style={{display: "block", fontSize: 11, marginTop: 5}}>Комментарий</span>
      <Input value={value.comment || comment} onChange={onCommentChange}/>
    </div>
    }

    {/*<Divider style={{marginBottom: 0}}/>*/}
  </>
}
const FormItemDatePicker = ({valueOld = '', value = {}, onChange, datePickerProps}) => {
  const [text, setText] = useState(null);
  const [comment, setComment] = useState('');
  const [edit, setEdit] = useState(false);

  const triggerChange = (changedValue) => {
    onChange?.({
      textOld: valueOld,
      text,
      comment,
      edit,
      ...value,
      ...changedValue,
    });
  };

  const onTextChange = (date, dateString) => {
    if (dateString === '') dateString = null;
    setText(dateString)
    setEdit(valueOld !== dateString)
    triggerChange({
      text: dateString,
      edit: valueOld !== dateString
    });
  }

  const onCommentChange = (e) => {
    setComment(e.target.value)
    triggerChange({
      comment: e.target.value,
    });
  }

  const t = value.text || text;

  return <>
    <DatePicker
      value={!t ? null : dayjs(t)} onChange={onTextChange}
      {...datePickerProps}
    />

    {edit && <div>
      <span style={{display: "block", fontSize: 11, marginTop: 5}}>Комментарий</span>
      <Input value={value.comment || comment} onChange={onCommentChange}/>
    </div>
    }

    {/*<Divider style={{marginBottom: 0}}/>*/}
  </>
}

const BrokerFormEdit = ({userData, onSuccess}) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [traderData, setTraderData] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit()
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const validate = (rule, value) => {
    if (value.text === '') {
      return Promise.reject('Заполните поле');
    }
    if (value.edit && value?.comment === '') {
      return Promise.reject('Заполните поле комментарий');
    }
    return Promise.resolve();
  };

  const traderDataGet = useCallback(() => {
    setLoad(true)
    setTraderData(false)
    apiQuery("/admin/traderData/" + userData._id)
      .then((data) => {
        setTraderData(data);

        form.setFieldValue('name', {text: data?.data?.questionnaire?.personalProfile?.name})
        form.setFieldValue('surname', {text: data?.data?.questionnaire?.personalProfile?.surname})
        form.setFieldValue('patronymic', {text: data?.data?.questionnaire?.personalProfile?.patronymic})
        form.setFieldValue('phoneNumber', {text: data?.data?.questionnaire?.personalProfile?.phoneNumber})
        form.setFieldValue('citizenship', {text: data?.data?.questionnaire?.personalProfile?.citizenship})
        form.setFieldValue('country', {text: data?.data?.questionnaire?.personalProfile?.country})
        form.setFieldValue('city', {text: data?.data?.questionnaire?.personalProfile?.city})
        form.setFieldValue('addressOfResidence', {text: data?.data?.questionnaire?.personalProfile?.addressOfResidence})
        form.setFieldValue('houseApartment', {text: data?.data?.questionnaire?.personalProfile?.houseApartment})
        form.setFieldValue('postcode', {text: data?.data?.questionnaire?.personalProfile?.postcode})
        form.setFieldValue('dob', {text: data?.data?.questionnaire?.personalProfile?.dob})

        setLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        setLoad(false)
      });
  }, [userData, form])

  const onFinish = (values) => {
    setConfirmLoading(true)
    apiQuery("/admin/traderDataSave", {
      method: 'POST',
      body: {
        userId: userData._id,
        values,
      }
    })
      .then((data) => {
        setConfirmLoading(false)
        setIsModalOpen(false);
        onSuccess()
      })
      .catch((error) => {
        apiQueryError({error});
        setConfirmLoading(false)
      });
  }

  useEffect(() => {
    if (isModalOpen) {
      traderDataGet()
    } else {
      setLoad(true)
    }
  }, [isModalOpen, traderDataGet])

  if (!userData) return;

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Редактировать данные
      </Button>

      <Modal title="Редактировать данные"
             open={isModalOpen}
             onCancel={handleCancel}
             confirmLoading={confirmLoading}
             footer={<>
               <Button onClick={handleCancel}>Закрыть</Button>
               <ConfirmPass2 btnText={'Сохранить'}
                             btnType={'default'}
                             mode={'brokerForm'}
                             danger={true}
                             fieldCommentVisible={false}
                             fieldFileVisible={false}
                             onFinish={(data) => {
                               form.setFieldValue('actionData', data)
                               form.submit()
                             }}/>
             </>}
      >
        {load && <Spin/>}
        {!load && <>
          {!traderData && <p>Нет данных</p>}
          {traderData && <Form form={form} onFinish={onFinish}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
            >
              <Collapse.Panel key={'1'} header={'Личный профайл'}>

                <Form.Item name="actionData" hidden={true}>
                  <Input readOnly={true}/>
                </Form.Item>

                <Form.Item
                  name="name"
                  label="Имя"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemInput valueOld={traderData?.data?.questionnaire?.personalProfile?.name}/>
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="surname"
                  label="Фамилия"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemInput valueOld={traderData?.data?.questionnaire?.personalProfile?.surname}/>
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="patronymic"
                  label="Отчество"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemInput valueOld={traderData?.data?.questionnaire?.personalProfile?.patronymic}/>
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="phoneNumber"
                  label="Номер телефона"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemInput valueOld={traderData?.data?.questionnaire?.personalProfile?.phoneNumber}/>
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="citizenship"
                  label="Гражданство"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemSelect valueOld={traderData?.data?.questionnaire?.personalProfile?.citizenship}
                                  options={[{value: 'KAZ', label: 'Казахстан'}]}
                  />
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="dob"
                  label="Дата рождения"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemDatePicker valueOld={traderData?.data?.questionnaire?.personalProfile?.dob}
                                      format={"YYYY-MM-DD"}
                  />
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="country"
                  label="Страна"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemSelect valueOld={traderData?.data?.questionnaire?.personalProfile?.country}
                                  options={[{value: 'KAZ', label: 'Казахстан'}]}
                  />
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="city"
                  label="Город"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemInput valueOld={traderData?.data?.questionnaire?.personalProfile?.city}/>
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="addressOfResidence"
                  label="Адрес проживания"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemInput valueOld={traderData?.data?.questionnaire?.personalProfile?.addressOfResidence}/>
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="houseApartment"
                  label="Дом/Квартира"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemInput valueOld={traderData?.data?.questionnaire?.personalProfile?.houseApartment}/>
                </Form.Item>

                <Divider/>

                <Form.Item
                  name="postcode"
                  label="Почтовый индекс"
                  rules={[{required: true},{validator: validate}]}
                >
                  <FormItemInput valueOld={traderData?.data?.questionnaire?.personalProfile?.postcode}/>
                </Form.Item>

              </Collapse.Panel>
            </Collapse>
          </Form>}
        </>}
      </Modal>
    </>
  );
}

export default observer(BrokerFormEdit);