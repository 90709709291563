import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Modal, Input, message, Switch} from 'antd';
import {apiQuery, apiQueryError} from "../../Utils";
import userStore from "../Auth/userStore";

const ConfirmPassSwitch = ({btnText = '', onFinish, disabled, checked}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const self = useRef()

  const showModal = (e) => {
    setIsModalOpen(true);
    self.current = e
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setConfirmLoading(false)
    self.current = null
  };

  const checkPass = (values) => {
    setConfirmLoading(true)
    apiQuery("/admin/checkPass", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        if (data?.error) {
          setConfirmLoading(false)
          return message.error(data.error.msg);
        }
        setIsModalOpen(false);
        onFinish(self.current);
        setConfirmLoading(false)
      })
      .catch((error) => {
        apiQueryError({error});
        setConfirmLoading(false)
      });
  }

  useEffect(() => {
    form.resetFields()
  }, [isModalOpen])

  return (
    <>
      <Switch disabled={disabled} checked={checked} onChange={showModal}/>
      <Modal title="Для подтверждения действия введите пароль от своей учетной записи администратора"
             open={isModalOpen}
             onOk={() => form.submit()}
             onCancel={handleCancel}
             width={320}
             confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          layout={'vertical'}
          onFinish={checkPass}
          autoComplete="off"
        >
          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};
export default ConfirmPassSwitch;