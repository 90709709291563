import {message} from "antd";
import userStore from "./components/Auth/userStore";

export const apiDomain = () => {
  const host = window.location.host
  let domain = 'trading.miracalyze.it';

  if ([
    'admin2.geekoinvest.kz',
    'dev_admin.geekoinvest.kz:3103'
  ].includes(host))
    domain = 'app2.geekoinvest.kz';

  if ([
    'admin.geekoinvest.kz'
  ].includes(host))
    domain = 'trading.miracalyze.it';

  return domain
}

/**
 * Мобильный или нет
 * @type {boolean}
 */
export const mobileCheck =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

/**
 * Обработка
 * @param data
 * @param company
 * @returns {{getUrl: string, getUrlData: string, getUrlDownload: string, getUrlFilename: string}|{getUrl: *, getUrlData: *, getUrlDownload: *, getUrlFilename: *}}
 */
export function fileAdapted(data, company) {
  if (data.fileUrl)
    return {
      getUrl: data.fileUrl,
      getUrlData: data.fileUrl,
      getUrlDownload: data.fileUrl,
      getUrlFilename: data.fileUrl,
    };

  if (data.serverDomain === undefined || data.id === undefined)
    return {
      getUrl: "",
      getUrlData: "",
      getUrlDownload: "",
      getUrlFilename: "",
    };

  if (data.public === false)
    return {
      getUrl:
        data.serverDomain +
        "/file/" +
        company.serverStaticToken +
        "/" +
        data.id,
      getUrlData:
        data.serverDomain +
        "/file/" +
        company.serverStaticToken +
        "/" +
        data.id +
        "/data",
      getUrlDownload:
        data.serverDomain +
        "/file/" +
        company.serverStaticToken +
        "/" +
        data.id +
        "/download",
      getUrlFilename:
        data.serverDomain +
        "/file/" +
        company.serverStaticToken +
        "/" +
        data.id +
        "/" +
        data.name,
    };

  return {
    getUrl: data.serverDomain + "/file/" + data.id,
    getUrlData: data.serverDomain + "/file/" + data.id + "/data",
    getUrlDownload: data.serverDomain + "/file/" + data.id + "/download",
    getUrlFilename: data.serverDomain + "/file/" + data.id + "/" + data.name,
  };
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const queryParam = (a) => {
  let s = [];
  let add = function (k, v) {
    v = typeof v === 'function' ? v() : v;
    v = v === null ? '' : v === undefined ? '' : v;
    s[s.length] = encodeURIComponent(k) + '=' + encodeURIComponent(v);
  };
  let buildParams = function (prefix, obj) {
    let i, len;

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(
            prefix + '[' + (typeof obj[i] === 'object' && obj[i] ? i : '') + ']',
            obj[i]
          );
        }
      } else if (Object.prototype.toString.call(obj) === '[object Object]') {
        for (let key of Object.keys(obj)) {
          buildParams(prefix + '[' + key + ']', obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      for (let key of Object.keys(obj)) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };

  return buildParams('', a).join('&');
};

export function apiQueryError({error}) {
  console.log(error)
  if (error?.res?.status === 401) userStore.setAuth(false);
  if (error?.res?.status === 404) message.error('Error 404! Not Found.');
  if (error?.body?.msg) message.error(error.body.msg);
}

/**
 * обработкой ошибок и проверкой на json
 * @param url
 * @param options
 */
export async function apiQuery(url, options = {}) {

  let domain = `https://${apiDomain()}/rest_api`;
  if (options.domain) domain = options.domain;

  if (options.method === 'GET') {
    url = url + '?' + queryParam(options.body);
    options.body = null;
  } else {
    if (options.body) options.body = JSON.stringify(options.body);
  }

  options.credentials = "include";

  let headers = {
    'Content-Type': 'application/json',
  }

  if (options.headers)
    options.headers = {headers, ...options.headers};
  else
    options.headers = headers;

  try {

    const res = await fetch(domain + url, options);

    if (res.ok) return await res.json();

    let data = {};
    if (res.status === 400 || res.status === 401) {
      let body = await res.json();
      data = {error: {res, body}}
    } else if (res.status === 401) {
      userStore.setAuth(false);
    } else if (res.status === 404) {
      data = {error: {res}}
    } else {
      let body = await res.text();
      data = {error: {res, body}}
    }

    if (options?.errorShow) {
      if (data.error?.res?.status === 404) message.error('Ошибка 404! Адрес не найден.');
      if (data.error?.body?.msg) message.error(data.error.body.msg);
      return;
    }
    return Promise.reject(data);
  } catch (e) {
    console.error(e)
    return Promise.reject({error: {body: {msg: 'Не удалось выполнить запрос'}}})
  }
}
export async function apiRequest({url, method = 'GET', headers, body, domainCustom, success = () => {}, error = () => {}}) {

  let domain = `https://${apiDomain()}/rest_api`;
  if (domainCustom) domain = domainCustom;

  if (method === 'GET') {
    if (body) url = url + '?' + queryParam(body);
  } else {
    if (body) body = JSON.stringify(body);
  }

  if (!headers) headers = {
    'Content-Type': 'application/json',
  }

  let options = {
    method,
    credentials: "include",
    headers,
    body
  };

  try {

    const res = await fetch(domain + url, options);

    if (res.ok) {
      const d = await res.json();
      success(d)
      return [null, d];
    }

    let data = {};
    if (res.status === 400 || res.status === 401) {
      let body = await res.json();
      data = {res, body}
    } else if (res.status === 401) {
      userStore.setAuth(false);
    } else if (res.status === 404) {
      data = {res}
    } else {
      let body = await res.text();
      data = {res, body}
    }

    error(data)
    return [data];
  } catch (e) {
    console.error(e)
    return [{error: {body: {msg: 'Не удалось выполнить запрос'}}}]
  }
}

export function shortenNum(num) {
  if (isNaN(num)) throw new Error(num + " is not a Number!");

  let sign = 1;
  if (num < 0) {
    num = num * -1;
    sign = -1;
  }

  let b = {
    0: "",
    1: "K",
    2: "M",
    3: "B",
    4: "T",
  };

  let numFloor = Math.floor(num);

  let thousands = Math.floor((("" + numFloor).length - 1) / 3);

  let c = 1000 ** thousands;

  return (num / c).toFixed(2) * sign + b[thousands];
}

export function shortenNumObj(num) {
  if (isNaN(num)) throw new Error(num + " is not a Number!");

  let sign = 1;
  if (num < 0) {
    num = num * -1;
    sign = -1;
  }

  let b = {
    0: "",
    1: "K",
    2: "M",
    3: "B",
    4: "T",
  };

  let thousands = Math.floor((("" + num).length - 1) / 3);

  let c = 1000 ** thousands;

  return {number: (num / c).toFixed(1) * sign, symbol: b[thousands]};
}

const getUniqueBy = (arr, prop) => {
  const set = new Set();
  return arr.filter((o) => !set.has(o[prop]) && set.add(o[prop]));
};

export function barToChart(data) {
  const mass = [];
  for (let i in data) {
    mass.push({
      x: i+1,
      y: data[i].c,
    })
  }
  return mass;
}

const Utils = {
  mobileCheck,
  fileAdapted,
  apiQuery,
  shortenNum,
  getUniqueBy,
  apiQueryError,
  apiDomain
};
export default Utils;
