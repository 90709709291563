import {Breadcrumb, Button, Checkbox, Col, DatePicker, Drawer, Form, Input, Row, Select, Table} from "antd";
import {apiQuery, apiQueryError} from "../../Utils";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ImagePreview from "../ImagePreview";

const {Option} = Select;
const columns = [
  {
    title: 'Лого',
    dataIndex: 'logo',
    key: 'logo',
    render: (text, record, index) => {
      return <ImagePreview src={record.logo} width={30} height={30}/>
    }
  },
  {
    title: 'Тикер',
    dataIndex: 'symbol',
    key: 'symbol',
  },
  {
    title: 'Компания',
    dataIndex: 'name',
    key: 'name',
    render: (text, record, index) => {
      return <>{record.dataAsset.name}</>
    }
  },
  // {
  //   title: 'Дериватив',
  //   dataIndex: 'derivative',
  //   key: 'derivative',
  //   render: (text, record, index) => {
  //     return <>{record.derivative ? 'Да':'Нет'}</>
  //   }
  // },
  {
    title: '',
    dataIndex: 'k1',
    key: 'k1',
    render: (text, record, index) => {
      return <Link to={`/tickers/${record._id}`}>Открыть</Link>
    }
  },
];

const TickerList = () => {

  const [items, setItems] = useState(null);
  const [filterClass, setFilterClass] = useState('all');
  const [filterNoLogo, setFilterNoLogo] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filters, setFilters] = useState({
    ticker: '',
    companyName: '',
  });

  const tickersGet = useCallback((page) => {
    console.log(filters);
    apiQuery('/admin/tickers', {
      method: 'POST',
      body: {
        page,
        filterClass,
        filterNoLogo,
        filters
      }
    }).then((data) => {
      console.log(data.items)
      setItems(data.items)
      setItemsCount(data.itemsCount)
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [filterClass, filterNoLogo, filters])

  const handleChangeFilterClass = (value) => {
    setFilterClass(value)
  }

  useEffect(() => {
    tickersGet()
  }, [tickersGet])

  const onClose = () => {
    setDrawerVisible(false);
  };

  const clearFilters = () => {
    // Устанавливаем начальные значения для всех фильтров
    setFilters({
      ticker: '',
      companyName: '',
    });

    tickersGet({
      ticker: '',
      companyName: '',
    });
  };

  const applyFilters = () => {
    tickersGet();
    onClose();
  };

  const handleFilterChange = (value, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  if (!items) return <>Загрузка...</>

  return (
      <>
        <Breadcrumb
            style={{
              margin: '16px 0',
            }}
        >
          <Breadcrumb.Item>Тикеры</Breadcrumb.Item>
          <Breadcrumb.Item>Список тикеров</Breadcrumb.Item>
        </Breadcrumb>

        <div
            className="site-layout-background"
            style={{
              minHeight: 360,
            }}
        >
          <div style={{marginBottom: 20}}>
            <Row justify="space-between" align="middle" style={{ margin: '16px 0' }}>
              {/*<Col flex={'200px'}>*/}
              {/*  <Select defaultValue="all" onChange={handleChangeFilterClass} style={{ width: 110 }}>*/}
              {/*    <Option value="all">Все</Option>*/}
              {/*    <Option value="us_equity">US Equity</Option>*/}
              {/*    <Option value="crypto">Crypto</Option>*/}
              {/*  </Select>*/}
              {/*</Col>*/}
              <Col flex={'200px'}>
                <Checkbox checked={filterNoLogo} onChange={(e) => {
                  setFilterNoLogo(e.target.checked)
                  tickersGet(1)
                }}>Без логотипа</Checkbox>
              </Col>
              <Col>
                <Button type="default" onClick={() => setDrawerVisible(true)}>
                  Фильтры
                </Button>
              </Col>
            </Row>

          </div>

          <Drawer
            title="Фильтры"
            width={500}
            onClose={onClose}
            open={drawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
            placement="right"
            footer={
              <Row justify="end" gutter={16}>
                <Col>
                  <Button onClick={clearFilters} style={{ marginRight: 8 }}>
                    Очистить
                  </Button>
                </Col>
                <Col>
                  <Button onClick={applyFilters} type="primary">
                    Применить
                  </Button>
                </Col>
              </Row>
            }
          >
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Тикер">
                    <Input
                      placeholder="Тикер"
                      value={filters.ticker}
                      onChange={(e) => handleFilterChange(e.target.value, 'ticker')}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Компания">
                    <Input
                      placeholder="Название компании"
                      value={filters.companyName}
                      onChange={(e) => handleFilterChange(e.target.value, 'companyName')}
                    />
                  </Form.Item>
                </Col>
              </Row>


            </Form>
          </Drawer>



          <Table dataSource={items}
                 columns={columns}
                 pagination={{
                   pageSize: 10,
                   showSizeChanger: false,
                   total: itemsCount,
                   onChange: (page, pageSize) => {
                     tickersGet(page)
                   }
                 }}
          />

        </div>
      </>
  )
}

export default TickerList;