import React, {useState} from 'react';
import {Form, DatePicker, Select, Button, Input} from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;


const ClientsForm = ({ onFinish }) => {
  const [role, setRole] = useState('')
  const [filterDateRange, setFilterDates] = useState([false,false]);
  const [filterFIO, setFilterFIO] = useState()


  return (
    <Form onFinish={(values) => onFinish(values, 'clients')}>
      <Form.Item label="ФИО" name="filterFIO">
        <Input placeholder="" value={filterFIO}  onChange={(e)=>{setFilterFIO(e.target.value)}}/>
      </Form.Item>
      <Form.Item name="dateRange" label="Период">
        <RangePicker
          onChange={(e)=>{setFilterDates(e)}}
          value={filterDateRange}
        />
      </Form.Item>
      <Form.Item name="clientTypes" label="Типы клиентов">
        <Select
          id="role"
          placeholder="Выберите типы клиентов"
          value={role}
          options={[
            { value: 'true', label: 'Сотрудник' },
            { value: 'false', label: 'Не сотрудник' },
          ]}
          defaultValue={''}
          onChange={(e) => {setRole(e)}}
        >
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Выгрузить отчет</Button>
      </Form.Item>
    </Form>
  );
};

export default ClientsForm;