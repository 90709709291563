import {Breadcrumb, Button, message, Space, Table, Tooltip, Typography, Tabs, Drawer, Form, Input, Select, DatePicker, Modal} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {apiQuery, apiQueryError} from "../../Utils";

import moment from "moment";
import {faCircleQuestion, faFolder, faRepeat} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import PDFModal from "../PDFModal/PDFModal";

// import { Document, Page, pdfjs } from 'react-pdf';
const Transfers = (params) => {
	const { Text } = Typography;
	const {TabPane} = Tabs;
	const testInv = params.testInv
	const statuses = [
		{value:'wait', label:'Ожидает'},
		{value:'cancel', label:'Отменен'},
		{value:'done', label:'Выполнен'},
		{value:'error', label:'Ошибка'},
	]

	const [modalFileData, setModalFileData] = useState(false);
	const [modalFileId, setModalFileId] = useState(false);

	const [brokerageItems, setBrokerageItems] = useState([])
	const [tradingItems, setTradingItems] = useState([])
	const  [route, setRoute] = useState('brokerage')
	const [open, setOpen] = useState(false);
	const [filterFIO, setFilterFIO] = useState('');
	const [filterStatus, setFilterStatus] = useState(false);
	const [filterDates, setFilterDates] = useState([false,false]);
	const [keyValue, setKeyValue] = useState('');
	const [modalFileVisible, setModalFileVisible] = useState(false);
	const [modalFileUrl, setModalFileUrl] = useState('');
	const [form] = Form.useForm();
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const resetFilter = ()=>{

		setFilterDates([false,false])
		setFilterStatus(false)
		setFilterFIO('')
		// setKeyValue(new Date())
		form.resetFields()
		// onClose()
	}

	const searchByFilter = ()=>{

		// console.log(filterFIO)
		// console.log(filterStatus)
		// console.log(filterDates)

		// aISDataGet()

		setKeyValue(new Date())

		onClose()
	}

	const onChangeTab = (value)=>{
		console.log(value);

		// globalRoute = value
		// aISDataGet()
		setRoute(value)
		// setItems([])
	}



	const [AISData, setAISData] = useState(false);
	const aISDataGet = useCallback(() => {

		// if(!route){
		// 	route = globalRoute
		// }

		console.log(filterFIO)
		console.log(filterStatus)
		console.log(filterDates)

		const filter ={}
		if(filterFIO && filterFIO!==''){
			filter.fio = filterFIO
		}
		if(filterStatus){
			filter.status = filterStatus
		}

		console.log(filterDates)

		if(filterDates[0]){
			filter.dateStart = dayjs(filterDates[0]).format('YYYY-MM-DD')
			filter.dateEnd = dayjs(filterDates[1]).format('YYYY-MM-DD')
		}

		console.log(filter)

		console.log('testInv', testInv);
		console.log(route)
		apiQuery("/admin/getAisTransfers", {
			method: 'POST',
			body: {testInv, route, filter}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)
				// data.forEach(el=>el.key = el.id)
				setAISData(data);
			})
			.catch((error) => {
				setAISData(false);
				apiQueryError({error});
			})
	}, [testInv, route, keyValue])

	// const rowSelection = {
	// 	onChange: (selectedRowKeys, selectedRows) => {
	// 		setItems(selectedRows.map(el=>el._id))
	// 	},
	// 	getCheckboxProps: (record) => (
	// 		{
	// 			disabled: record.status === 'error',
	// 			// Column configuration not to be checked
	// 			// name: record.name,
	// 			value: record._id,
	// 		}),
	// };


	const openPdfModal = async (url)=>{
		setModalFileUrl(url)
		setModalFileVisible(true)
	}

	const modalFile = ({visible, fileData, fileId, fileUrl}) => {
		console.log(fileUrl)
		if (visible) {
			setModalFileData({...fileData})
			setModalFileId(fileId)
			setModalFileUrl(fileUrl)
		} else {
			setModalFileData(false)
			setModalFileId(false)
		}
		setModalFileVisible(visible)
	}

	const columns = [
		// {
		// 	title: '',
		// 	key: 'checkbox',
		// 	render: (_, record) => (
		// 		<Space size="middle">
		// 			<Checkbox onChange={() => {onChange(record._id)}} checked={items.includes(record._id)} />
		// 		</Space>
		// 	),
		// },
		{
			title: ' Клиент',
			key: 'user',
			render: (_, record) => (
				<Space size="middle">
					<a href={'/users/'+record.user.id} target="_blank" rel="noreferrer">{record.user.aisData.aLastName} {record.user.aisData.aFirstName} {record.user.aisData.aMiddleName}</a>
				</Space>
			),
		},
		{
			title: 'Направление',
			key: 'route',
			render: (_, record) => (
				<Space size="middle">
					{record.route==='trading' ? 'На торговый счет' : (record.route==='brokerage' ? 'На брокерский' : 'Вывод средств')}
				</Space>
			),
		},
		{
			title: 'Объем средств',
			key: 'amount',
			render: (_, record) => (
				<Space size="middle">
					{record?.aisData?.vAmount || '-'}
				</Space>
			),
		},
		{
			title: 'Комиссия',
			key: 'comissions',
			render: (_, record) => (
				<Space size="middle">
					{record?.aisData?.vBankComission || '-'}
				</Space>
			),
		},
		{
			title: 'Комиссия в тенге',
			key: 'comissionKZT',
			render: (_, record) => (
				<Space size="middle">
					{record?.aisData?.vBankComissionKZT || '-'}
				</Space>
			),
		},
		{
			title: 'Дата',
			key: 'createdAt',
			render: (_, record) => (
				<Space size="middle">
					{moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')}
				</Space>
			),
		},
		// {
		// 	title: 'Поручение',
		// 	key: 'user',
		// 	render: (_, record) => (
		// 		<Space size="middle">
		// 			{record?.data?.fileName ? <a href={record?.data?.fileName} target="_blank" rel="noreferrer">Скачать</a> : '-'}
		// 		</Space>
		// 	),
		// },
		{
			title: 'Поручение',
			key: 'user',
			render: (_, record) => (

				// <Space size="middle">
				// 	{record?.data?.fileName ? <PDFModal pdfFile={record?.data?.fileName} /> : '-'}
				// </Space>
				<Space size="middle">
					{record?.data?.fileName ? <a onClick={()=>{modalFile({visible: true, fileData: 'asds.pdf', fileId: record._id, fileUrl: record?.data?.fileName})}}>Открыть</a> : '-'}
				</Space>
			),
		},
		// {
		// 	title: 'Поручение тест модалки',
		// 	key: 'user',
		// 	render: (_, record) => (
		// 		<Space size="middle">
		// 			{/*modalFile({visible: true, mode: 'agreementFile', fileData: record.fileData, fileId: record._id}){record?.data?.fileName ? <a onClick={()=>{openPdfModal(record?.data?.fileName)}}>Скачать</a> : '-'}*/}
		//
		// 		</Space>
		// 	),
		// },
		{
			title: 'Статус',
			key: 'status',
			render: (_, record) => (
				<Space size="middle">
					{record.status==='wait' ?
						<Text>Ожидает</Text> :
						(record.status==='done' ? <Text type="success">Выполнен</Text> :
							<Text type="danger">Ошибка</Text>)}
					{record.status==='error'?
						<>
						<Tooltip title={record?.error?.msg} placement="leftTop">
							<FontAwesomeIcon icon={faCircleQuestion} />
						</Tooltip>
						<Tooltip title="Повторить" placement="leftTop">
							<FontAwesomeIcon icon={faRepeat} onClick={()=>{repeatMTO(record._id)}} />
						</Tooltip>
						</>
						: <></>
					}
				</Space>
			),
		},
	];


	const fetchPdfDocument = useCallback((document) => {
		console.log(document)

		apiQuery(`${document}`)
			.then(response => {
				console.log(response)
				response.blob()
			})
			.then(blob => {
				const pdfUrl = URL.createObjectURL(blob);

			})
			.catch(error => {
				console.error("Ошибка при загрузке PDF", error);

			});
	}, []);

	const checkComissions = () => {

		const items = route==='trading' ? tradingItems : brokerageItems

		console.log(items)
		// return false

		if(items.length===0){
			return message.error('Выберите записи!')
		}

		return apiQuery("/admin/checkTransferCommissions", {
			method: 'POST',
			body: {testInv, items}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)

				message.success(data.success.msg)

				aISDataGet()
			})
			.catch((error) => {
				aISDataGet()
				apiQueryError({error});
			})
	};


	const repeatMTO = (item)=>{
		return apiQuery("/admin/repeatTransferMTO", {
			method: 'POST',
			body: {testInv, items:[item]}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)

				message.success(data.success.msg)
				aISDataGet()
			})
			.catch((error) => {
				aISDataGet()
				apiQueryError({error});
			})
	}

	const createMTOs = () => {

		const items = route==='trading' ? tradingItems : brokerageItems

		if(items.length===0){
			return message.error('Выберите записи!')
		}

		return apiQuery("/admin/createTransferMTOS", {
			method: 'POST',
			body: {testInv, items}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)

				message.success(data.success.msg)
				aISDataGet()
			})
			.catch((error) => {
				aISDataGet()
				apiQueryError({error});
			})
	};
	// const createMTOs = () => {
	// 	// setIsModalVisible(true);
	// };

	useEffect(() => {
		aISDataGet()
	}, [aISDataGet, testInv, route])
	return (
		<>
			<Breadcrumb style={{margin: '16px 0'}}>
				<Breadcrumb.Item>Переводы</Breadcrumb.Item>
			</Breadcrumb>
			<div
				style={{
					minHeight: 360,
				}}
			>
				<Button type="primary"
				        style={{marginBottom: 15}}
				        onClick={checkComissions}
				>
					Расчитать комиссии
				</Button>
				<Button type="primary"
				        style={{marginBottom: 15}}
				        onClick={createMTOs}
				>
					Создать приказы
				</Button>

				<Button type="primary" style={{marginBottom: 15}} onClick={showDrawer} >
					Фильтр
				</Button>
			<Tabs defaultActiveKey="brokerage" onChange={onChangeTab}>
				<TabPane tab="На брокерский" key="brokerage">

					<div
						style={{
							minHeight: 360,
						}}
					>



						<Table
							rowSelection={{
								type: 'checkbox',
								onChange: (selectedRowKeys, selectedRows) => {
									setBrokerageItems(selectedRows.map(el=>el._id))
								},
								getCheckboxProps: (record) => (
									{
										disabled: record.status === 'error',
										// Column configuration not to be checked
										// name: record.name,
										value: record._id,
									}),
							}}
							columns={columns} dataSource={AISData} />

					</div>
				</TabPane>
				<TabPane tab="На торговый" key="trading">
					<div
						style={{
							minHeight: 360,
						}}
					>
						{/*<Button type="primary"*/}
						{/*        style={{marginBottom: 15}}*/}
						{/*        onClick={checkComissions}*/}
						{/*>*/}
						{/*	Расчитать комиссии*/}
						{/*</Button>*/}
						{/*<Button type="primary"*/}
						{/*        style={{marginBottom: 15}}*/}
						{/*        onClick={createMTOs}*/}
						{/*>*/}
						{/*	Создать приказы*/}
						{/*</Button>*/}




						<Table
							rowSelection={{
								type: 'checkbox',
								onChange: (selectedRowKeys, selectedRows) => {
									setTradingItems(selectedRows.map(el=>el._id))
								},
								getCheckboxProps: (record) => (
									{
										disabled: record.status === 'error',
										// Column configuration not to be checked
										// name: record.name,
										value: record._id,
									}),
							}}
							columns={columns} dataSource={AISData} />

					</div>
				</TabPane>
			</Tabs>
			</div>
			<Drawer
				title="Фильтр"
				width={720}
				onClose={onClose}
				open={open}
				styles={{
					body: {
						paddingBottom: 80,
					},
				}}
				extra={
					<Space>
						<Button onClick={resetFilter}>Сбросить</Button>
						<Button onClick={searchByFilter} type="primary">
							Применить
						</Button>
					</Space>
				}
			>

				<Form form={form}>
					<Form.Item label="ФИО или email">
						<Input placeholder="" value={filterFIO}  onChange={(e)=>{setFilterFIO(e.target.value)}}/>
					</Form.Item>
					<Form.Item
						name="status"
						label="Статус"
					>
						<Select placeholder="" options={statuses}  value={filterStatus} onChange={(e)=>{console.log(e); setFilterStatus(e)}}>
							{/*<Option value="private">Private</Option>*/}
							{/*<Option value="public">Public</Option>*/}
						</Select>
					</Form.Item>
					<Form.Item
						name="dateTime"
						label="Дата"
					>
						<DatePicker.RangePicker
							style={{
								width: '100%',
							}}
							// key={keyValue}
							getPopupContainer={(trigger) => trigger.parentElement}
							onChange={(e)=>{setFilterDates(e)}}
							value={filterDates}
						/>
					</Form.Item>
				</Form>

			</Drawer>
			<PDFModal visible={modalFileVisible}
			          fileUrl={modalFileUrl}
			          fileData={{ext:'pdf'}}
			          onClose={() => setModalFileVisible(false)}
			/>
		</>
	)
}

export default Transfers;
