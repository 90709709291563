import {FileOutlined, FilePdfOutlined, FileWordOutlined} from "@ant-design/icons";

const FileIcon = ({ext, style}) => {

  const d = {
    'pdf': <FilePdfOutlined />,
    'doc': <FileWordOutlined />,
    'docx': <FileWordOutlined />,
  }
  if (!d[ext]) return null;

  return <div class="image-preview" style={style}>
    <span class="anticon anticon-file-image">
      {!d[ext] && <FileOutlined />}
      {!!d[ext] && d[ext]}
    </span>
  </div>
}

export default FileIcon;