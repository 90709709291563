import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Input, message, Upload} from 'antd';
import {apiQuery, apiQueryError} from "../../Utils";
import {PlusOutlined} from "@ant-design/icons";

const ConfirmPass2 = (params) => {
  const {
    btnText = '',
    fieldCommentVisible = false,
    fieldCommentRequired = true,
    fieldFileVisible = false,
    fieldFileRequired = false,
    onFinish,
    btnType = 'primary',
    danger = false,
    loading,
    mode,
    addFieldVisible = false,
    addFieldLabel = '',
    addFieldValue = ''
  } = params
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);



  const beforeUpload = async (file, fileList) => {
    try {
      fileList[0].base64File = await convertFileToBase64(file);
      setFileList(fileList)
    } catch (error) {
      console.error('Ошибка при загрузке файла:', error);
      message.error('Ошибка при загрузке файла');
    }

    return false; // Отменяем стандартную загрузку, так как мы обработали ее сами
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setConfirmLoading(false)
  };

  const checkPass = (values) => {
    setConfirmLoading(true)
    apiQuery("/admin/checkPass", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        if (data?.error) {
          setConfirmLoading(false)
          return message.error(data.error.msg);
        }
        setIsModalOpen(false);
        onFinish({
          comment: values.comment,
          file: fileList[0] ? fileList[0]?.base64File || null : null,
          addField: values.addField,
          mode
        });
        setConfirmLoading(false)
      })
      .catch((error) => {
        apiQueryError({error});
        setConfirmLoading(false)
      });
  }

  useEffect(() => {
    form.resetFields()
    setFileList([])
  }, [isModalOpen])


    // if(addFieldVisible) {
    //     form.setFieldValue('addField', addFieldValue)
    // }

    console.log(params)

  return (
    <>
      <Button type={btnType} danger={danger} loading={loading} onClick={showModal} htmlType={'button'}>
        {btnText}
      </Button>
      <Modal title="Для подтверждения действия введите пароль от своей учетной записи администратора"
             open={isModalOpen}
             onOk={() => form.submit()}
             onCancel={handleCancel}
             width={320}
             confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          layout={'vertical'}
          onFinish={checkPass}
          autoComplete="off"
        >
          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {addFieldVisible && <Form.Item
            label={addFieldLabel}
            name="addField"
            rules={[
              {
                required: true,
                message: 'Введите '+addFieldLabel.toLowerCase()+'!',
              },
            ]}
            initialValue={addFieldValue}
          >
            <Input />
          </Form.Item>}
          {fieldCommentVisible && <Form.Item
            label="Комментарий"
            name="comment"
            rules={[
              {
                required: fieldCommentRequired,
                message: 'Введите комментарий!',
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>}

          {fieldFileVisible && <Form.Item label="Файл" name={'file'} rules={[
            {
              required: fieldFileRequired,
              message: 'Выберите файл!',
            }
          ]}>
            <Upload
              maxCount={1}
              listType="picture-card"
              fileList={fileList}
              beforeUpload={beforeUpload}
              onRemove={()=>setFileList([])}
            >
              {fileList.length === 0 && (
                <div>
                  <PlusOutlined/>
                  <div>
                    Загрузить
                    <br/> файл
                  </div>
                </div>
              )}
            </Upload>
          </Form.Item>}
        </Form>
      </Modal>
    </>
  );
};
export default ConfirmPass2;