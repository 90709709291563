import { configure, makeAutoObservable } from "mobx";
import {apiQuery, apiQueryError} from "../../Utils";
import {message} from "antd";

configure({ enforceActions: "never" });

class AlpacaStore {
  data = null;
  cipData = null;

  constructor() {
    makeAutoObservable(this);
  }

  alpacaUserProfile ({userId, cb = () => {}}) {
     apiQuery("/admin/alpacaUserProfile", {
      method: 'POST',
      body: {userId},
    })
      .then((data) => {
        if (data.error) return message.error(data.error.msg)
        this.data = data
        cb(data)
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }

  cip ({userId, cb = () => {}}) {
    apiQuery("/admin/alpacaCip", {
      method: 'POST',
      body: {userId},
    })
      .then((data) => {
        console.log(data)
        if (data.error) return message.error(data.error.msg)
        this.cipData = data
        cb(data)
      })
      .catch((error) => {
        apiQueryError({error});
      });
  }

}

const clsAlpacaStore = new AlpacaStore();
export default clsAlpacaStore;
