import {
	Breadcrumb,
	Button,
	message,
	Space,
	Table,
	Tooltip,
	Typography,
	Tabs,
	Drawer,
	Form,
	Input,
	Select,
	DatePicker,
	Modal,
	Row, Col, Card
} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Utils, {apiQuery, apiQueryError} from "../../Utils";

import moment from "moment";
import {faCircleQuestion, faFolder, faRepeat} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import PDFModal from "../PDFModal/PDFModal";
import {Link} from "react-router-dom";
import userStore from "../Auth/userStore";
import ConfirmPass2 from "../ConfirmPass/ConfirmPass2";
const { TextArea } = Input;
// import { Document, Page, pdfjs } from 'react-pdf';
const TransferPulls = (params) => {
	const { Text } = Typography;
	const {TabPane} = Tabs;
	const testInv = Utils.apiDomain()==='app2.geekoinvest.kz'
	const statuses = [
		{value:'wait', label:'Новый'},
		{value:'sent', label:'Отправлен'},
		{value:'done', label:'Поступил'},
		{value:'error', label:'Ошибка'},
	]

	const [modalFileData, setModalFileData] = useState(false);
	const [modalFileId, setModalFileId] = useState(false);
	const [load, setLoad] = useState(null);
	const [brokerageItems, setBrokerageItems] = useState([])
	const [tradingItems, setTradingItems] = useState([])
	const  [route, setRoute] = useState('brokerage')
	const [open, setOpen] = useState(false);
	const [filterFIO, setFilterFIO] = useState('');
	const [filterStatus, setFilterStatus] = useState(false);
	const [filterDates, setFilterDates] = useState([false,false]);
	const [keyValue, setKeyValue] = useState('');
	const [modalFileVisible, setModalFileVisible] = useState(false);
	const [modalFileUrl, setModalFileUrl] = useState('');
	const [pullItems, setPullItems] = useState([])
	const [pullData, setPullData] = useState(null)
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isSystemModalVisible, setIsSystemModalVisible] = useState(false);
	const [isSystemModalVisible2, setIsSystemModalVisible2] = useState(false);
	const [form] = Form.useForm();
	const [form2] = Form.useForm();
	const [form3] = Form.useForm();
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const showModal = (data) => {

		setIsModalVisible(true);
	};
	const showSystemModal = (data) => {
		for (let key of Object.keys(data)) {
			let d = {};
			d[key] = data[key];
			form2.setFieldsValue(d);


		}
		setIsSystemModalVisible(true);
	};
	const showSystemModal2 = (data) => {
		for (let key of Object.keys(data)) {
			let d = {};
			d[key] = data[key];
			form3.setFieldsValue(d);


		}
		setIsSystemModalVisible2(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		form.resetFields([])
	};
	const handleSystemCancel = () => {
		setIsSystemModalVisible(false);
		form2.resetFields([])
	};
	const handleSystemCancel2 = () => {
		setIsSystemModalVisible2(false);
		form3.resetFields([])
	};

	const resetFilter = ()=>{

		setFilterDates([false,false])
		setFilterStatus(false)
		setFilterFIO('')
		// setKeyValue(new Date())
		form.resetFields()
		// onClose()
	}

	const searchByFilter = ()=>{

		// console.log(filterFIO)
		// console.log(filterStatus)
		// console.log(filterDates)

		// aISDataGet()

		setKeyValue(new Date())

		onClose()
	}

	const onChangeTab = (value)=>{
		console.log(value);

		// globalRoute = value
		// aISDataGet()
		setRoute(value)
		// setItems([])
	}


	const getPull = async (pullId)=>{
		apiQuery("/admin/getPull", {
			method: "POST",
			body: {pullId},
		})
			.then((data) => {

				setPullItems(data.transfers)
				setPullData(data.pull)
				console.log(data.pull)


				if(data.pull.source!=='system') {
					showModal()
				}
				else{
					if(data.pull.route==='trading') {
						showSystemModal(data.pull.addData)
					}
					if(data.pull.route==='brokerage') {
						showSystemModal2(data.pull.addData)
					}
				}


			})
			.catch((error) => {
				apiQueryError({error});
			});
	}
	const [AISData, setAISData] = useState(false);
	const aISDataGet = useCallback(() => {

		// if(!route){
		// 	route = globalRoute
		// }

		console.log(filterFIO)
		console.log(filterStatus)
		console.log(filterDates)

		const filter ={}

		if(filterStatus){
			filter.status = filterStatus
		}

		console.log(filterDates)

		if(filterDates[0]){
			filter.dateStart = dayjs(filterDates[0]).format('YYYY-MM-DD')
			filter.dateEnd = dayjs(filterDates[1]).format('YYYY-MM-DD')
		}

		console.log(filter)

		console.log('testInv', testInv);
		console.log(route)
		apiQuery("/admin/getTransferPulls", {
			method: 'POST',
			body: {testInv, route, filter}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)
				// data.forEach(el=>el.key = el.id)
				setAISData(data);
			})
			.catch((error) => {
				setAISData(false);
				apiQueryError({error});
			})
	}, [testInv, route, keyValue])

	// const rowSelection = {
	// 	onChange: (selectedRowKeys, selectedRows) => {
	// 		setItems(selectedRows.map(el=>el._id))
	// 	},
	// 	getCheckboxProps: (record) => (
	// 		{
	// 			disabled: record.status === 'error',
	// 			// Column configuration not to be checked
	// 			// name: record.name,
	// 			value: record._id,
	// 		}),
	// };


	const openPdfModal = async (url)=>{
		setModalFileUrl(url)
		setModalFileVisible(true)
	}

	const addComment = async(data)=>{
		if(!userStore.userData.role.cfo && !userStore.userData.role.backOfficer && !!userStore.userData.role.compliance){
			return false
		}

		apiQuery("/admin/saveTransferPullComment", {
			method: "POST",
			body: data,
		})
			.then((data) => {

				aISDataGet()

			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const editPullId = async(data)=>{
		if(!userStore.userData.role.cfo && !userStore.userData.role.backOfficer){
			return false
		}
		if(userStore.userData.role.backOfficer) {
			data.action = 'editPullId'
		}
		if(userStore.userData.role.cfo) {
			data.action = 'resolve'
		}
		data.pullId = data.addField

		console.log(data)

		apiQuery("/admin/saveTransferPullId", {
			method: "POST",
			body: data,
		})
			.then((data) => {
				aISDataGet()
				// setItems((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.items;
				// 	return d
				// });
				// setItemsCount((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.itemsCount;
				// 	console.log(d)
				// 	return d
				// });
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const modalFile = ({visible, fileData, fileId, fileUrl}) => {
		console.log(fileUrl)
		if (visible) {
			setModalFileData({...fileData})
			setModalFileId(fileId)
			setModalFileUrl(fileUrl)
		} else {
			setModalFileData(false)
			setModalFileId(false)
		}
		setModalFileVisible(visible)
	}

	const columns = [
		{
			title: "Дата",
			dataIndex: "date",
			// key: "email",
			render: (text, record, index) => {
				return (
					<span>{moment(record.createdAt).format('DD.MM.YYYY')}</span>
				);
			},
			// sorter: true,
		},
		{
			title: "Сумма",
			dataIndex: "amount",
			key: "amount",
			render: (text, record, index) => {
				return <span>{record?.amount || '-'}</span>;
			},
			// sorter: true,
		},
		{
			title: "Направление",
			dataIndex: "route",
			key: "route",
			render: (text, record, index) => {
				return <span>{record.route==='trading' ? 'Б -> Т' : (
					record.route==='brokerage' ? 'Т -> Б' :  'неизвестно'
				)}</span>;
			},
			// sorter: true,
		},
		{
			title: "Источник",
			dataIndex: "source",
			key: "source",
			render: (text, record, index) => {
				return <span>{record.source==='backOffice' ? 'Бэк офис' : (
					record.source==='system' ? 'Система' :  'неизвестно'
				)}</span>;
			},
			// sorter: true,
		},
		{
			title: "Уникальный ID",
			dataIndex: "name-sender",
			key: "bankData.nameSender",
			render: (text, record, index) => {
				return <span>{record?.pullId ? <a onClick={()=>{getPull(record?._id)}}>{record?.pullId}</a> :''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Статус",
			dataIndex: "name-sender",
			key: "bankData.nameSender",
			render: (text, record, index) => {
				return <span>{record?.status==='wait' ? 'Ожидает' : (
					record?.status==='sent' ? 'Отправлен' : (
						record?.status==='done' ? 'Поступил' :  'неизвестно'
					)
				)}</span>;
			},
			// sorter: true,
		},

		{
			title: "Зачисление",
			dataIndex: "type",
			key: "type",
			render: (text, record, index) => {
				return <span>{record.type==='auto' ? 'Автоматическое' : (
					record.type==='hand' ? 'Ручное' : 'неизвестно'
				)}</span>;
			},
			// sorter: true,
		},
		{
			title: "Комментарии от Бэк-Офиса",
			dataIndex: "backComment",
			// key: "email",
			render: (text, record, index) => {
				return <>
					{!record.backComment || record.backComment.length===0 ? <></> :
						record.backComment.map(el=>
							<div>
								<div>{el.name}</div>
								<div>{el.time}</div>
								<div>{el.text}</div>
								{el.file ? <a href={el.file} target={'_blank'}>файл</a>
									:<></>}
							</div>
						)
					}
				</>

			},
			// sorter: true,
		},
		{
			title: "Комментарии от Compliance",
			dataIndex: "complianceComment",
			// key: "email",
			render: (text, record, index) => {
				return <>
					{!record.complianceComment || record.backComment.length===0 ? <></> :
						record.complianceComment.map(el=>
							<div>
								<div>{el.name}</div>
								<div>{el.time}</div>
								<div>{el.text}</div>
								{el.file ? <a href={el.file} target={'_blank'}>файл</a>
									:<></>}
							</div>
						)
					}
				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Исполнитель)",
			dataIndex: "backOfficer",
			// key: "email",
			render: (text, record, index) => {
				if(!record?.backOfficer) {
					return <></>
				}

				return <>
					{record?.backOfficer.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Подтверждающий)",
			dataIndex: "cfo",
			// key: "email",
			render: (text, record, index) => {
				if(!record?.cfo) {
					return <></>
				}

				return <>
					{record?.cfo.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Compliance))",
			dataIndex: "compliance",
			// key: "email",
			render: (text, record, index) => {

				if(!record?.compliance) {
					return <></>
				}

				return <>
					{record?.compliance.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

				</>
					;
			},
			// sorter: true,
		},
		{
			title: "Причина Возврата",
			dataIndex: "refundReason",
			// key: "email",
			render: (text, record, index) => {
				return <span>{record.refundReason || ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Действия",
			dataIndex: "actions",
			// key: "email",
			render: (text, record, index) => {


				if(record?.source==='system'){
					if(record.status==='wait'){

						if(userStore.userData.role.backOfficer) {
							if (!record?.backOfficerLastAction?.action) {
								return <>
									<div>
										<ConfirmPass2 btnText={'Объединить'}
										              btnType={'default'}
										              fieldCommentVisible={true}
										              fieldFileVisible={true}
										              addFieldVisible={true}
										              addFieldLabel={'PullId'}
										              addFieldValue={''}
											// danger={!userData?.blocking}
											// loading={userBlockLoad}
											          onFinish={(data) => {
												          editPullId({_id:record._id,...data})
											          }}/>
									</div>
									<div>
										<ConfirmPass2 btnText={'Комментировать'}
										              btnType={'default'}
										              fieldCommentVisible={true}
										              fieldFileVisible={true}
											// danger={!userData?.blocking}
											// loading={userBlockLoad}
											          onFinish={(data) => {
												          addComment({_id:record._id,...data})
											          }}/>
									</div>

								</>
							}


							return <div>
								<ConfirmPass2 btnText={'Комментировать'}
								              btnType={'default'}
								              fieldCommentVisible={true}
								              fieldFileVisible={true}
									// danger={!userData?.blocking}
									// loading={userBlockLoad}
									          onFinish={(data) => {
										          addComment({_id:record._id,...data})
									          }}/>
							</div>
						}
					}
				}

				if(record.status==='wait'){

					if(userStore.userData.role.backOfficer) {
						if (!record?.backOfficerLastAction?.action) {
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>

							</>
						}


						return <div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>
					}




					return <div>
						<ConfirmPass2 btnText={'Комментировать'}
						              btnType={'default'}
						              fieldCommentVisible={true}
						              fieldFileVisible={true}
							// danger={!userData?.blocking}
							// loading={userBlockLoad}
							          onFinish={(data) => {
								          addComment({_id:record._id,...data})
							          }}/>
					</div>

				}


				if(record.status==='sent'){

					if(userStore.userData.role.cfo) {
						if (!record?.cfoLastAction?.action || (record?.cfoLastAction?.action && record?.cfoLastAction?.action!==record?.backOfficerLastAction?.action)) {
							if (record?.backOfficerLastAction?.action === 'editPullId') {


								return <>
									<div>
										<ConfirmPass2 btnText={'Комментировать'}
										              btnType={'default'}
										              fieldCommentVisible={true}
										              fieldFileVisible={true}
											// danger={!userData?.blocking}
											// loading={userBlockLoad}
											          onFinish={(data) => {
												          addComment({_id: record._id, ...data})
											          }}/>
									</div>
									<div>
										<ConfirmPass2 btnText={'Зачислить'}
										              btnType={'default'}
										              fieldCommentVisible={true}
										              fieldFileVisible={true}
										              addFieldVisible={true}
										              addFieldLabel={'PullId'}
										              addFieldValue={record?.pullId || ''}
											// danger={!userData?.blocking}
											// loading={userBlockLoad}
											          onFinish={(data) => {
												          editPullId({_id: record._id, ...data})
											          }}/>
									</div>
								</>

							}

						}
						return <div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>


					}

					if(userStore.userData.role.compliance) {
						if (!record?.complianceLastAction?.action) {
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>


							</>
						}
					}
					return <>
						<div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>

					</>
				}

				if(record.status==='done'){

					if(userStore.userData.role.backOfficer || userStore.userData.role.cfo){

						if(record?.backOfficerLastAction?.action!=='refund' && record?.cfoLastAction?.action!=='refund'){
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>


							</>
						}


					}
					return <>
						<div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>

					</>
				}

				return <></>
			},
			// sorter: true,
		},

	];

	const columnsForEditPull = [
		{
			title: "Дата поручения",
			dataIndex: "date",
			// key: "email",
			render: (text, record, index) => {
				return (
					<span>{record.createdAt ? moment(record.createdAt).format('DD.MM.YYYY') : ''}</span>
				);
			},
			// sorter: true,
		},
		{
			title: "Время клиента с часовым поясом",
			dataIndex: "time",
			key: "time",
			render: (text, record, index) => {
				return <span>{record?.data?.userTime ? record.data.userTime+' по GMT'+record.data.timezone : ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "ID",
			dataIndex: "ID",
			key: "bankData.id",
			render: (text, record, index) => {
				return <div>
					{
						!record.id ? <></> :
							<Link onClick={()=>{}}>{record.id}</Link>
					}
				</div>;
			},
			// sorter: true,
		},
		{
			title: "Клиент (ФИО/Наименование)",
			dataIndex: "name-sender",
			key: "bankData.nameSender",
			render: (text, record, index) => {
				return <span>{record.user?.addData?.fio || ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "ИИН/БИН",
			dataIndex: "rnn-sender",
			key: "bankData.rnn-sender",
			render: (text, record, index) => {
				return <span>{record.user?.addData?.iin||''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Сумма",
			dataIndex: "amount-sender",
			key: "bankData.amount-sender",
			render: (text, record, index) => {
				return <span>{record?.aisData?.vAmount || '-'}</span>;
			},
			// sorter: true,
		},
		{
			title: "Прочие комиссии банка",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				return <div>
					{ record.status==='wait' ?
						<></>
						:
						<p>{record?.aisData?.otherCommissions || 0}</p>
					}
				</div>
			},
			// sorter: true,
		},
		{
			title: "Комиссия за перевод",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				return <div>
					{ record.status==='wait' ?
						<></>
						:
						<p>{record?.aisData?.vBankComission || 0}</p>
					}
				</div>
			},
			// sorter: true,
		},
		{
			title: "Комиссия в тенге",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				// return <span>{record?.aisData?.vBankComissionKZT || '-'}</span>;
				return <div>
					{ record.status==='wait' ?
						<></>
						:
						<p>{record?.aisData?.vBankComissionKZT || 0}</p>
					}
				</div>
			},
			// sorter: true,
		},
		{
			title: "Направление",
			dataIndex: "route",
			key: "route",
			render: (text, record, index) => {
				return <span>{!record.route ? '' :(record.route==='trading' ? 'Б -> Т' : (
					record.route==='brokerage' ? 'Т -> Б' :  'неизвестно'
				))}</span>;
			},
			// sorter: true,
		},

	];


	const fetchPdfDocument = useCallback((document) => {
		console.log(document)

		apiQuery(`${document}`)
			.then(response => {
				console.log(response)
				response.blob()
			})
			.then(blob => {
				const pdfUrl = URL.createObjectURL(blob);

			})
			.catch(error => {
				console.error("Ошибка при загрузке PDF", error);

			});
	}, []);


	// const createMTOs = () => {
	// 	// setIsModalVisible(true);
	// };

	useEffect(() => {
		aISDataGet()
	}, [aISDataGet, testInv, route])
	return (
		<>
			<Breadcrumb style={{margin: '16px 0'}}>
				<Breadcrumb.Item>Пуллы</Breadcrumb.Item>
			</Breadcrumb>
			<div
				style={{
					minHeight: 360,
				}}
			>
				{/*<Button type="primary"*/}
				{/*        style={{marginBottom: 15}}*/}
				{/*        onClick={checkComissions}*/}
				{/*>*/}
				{/*	Расчитать комиссии*/}
				{/*</Button>*/}
				{/*<Button type="primary"*/}
				{/*        style={{marginBottom: 15}}*/}
				{/*        onClick={createMTOs}*/}
				{/*>*/}
				{/*	Создать приказы*/}
				{/*</Button>*/}

				<Button type="primary" style={{marginBottom: 15}} onClick={showDrawer} >
					Фильтр
				</Button>
				<Tabs defaultActiveKey="brokerage" onChange={onChangeTab}>
					<TabPane tab="На брокерский" key="brokerage">

						<div
							style={{
								minHeight: 360,
							}}
						>



							<Table
								// rowSelection={{
								// 	type: 'checkbox',
								// 	onChange: (selectedRowKeys, selectedRows) => {
								// 		setBrokerageItems(selectedRows.map(el=>el._id))
								// 	},
								// 	getCheckboxProps: (record) => (
								// 		{
								// 			disabled: record.status === 'error',
								// 			// Column configuration not to be checked
								// 			// name: record.name,
								// 			value: record._id,
								// 		}),
								// }}
								columns={columns} dataSource={AISData} />

						</div>
					</TabPane>
					<TabPane tab="На торговый" key="trading">
						<div
							style={{
								minHeight: 360,
							}}
						>
							{/*<Button type="primary"*/}
							{/*        style={{marginBottom: 15}}*/}
							{/*        onClick={checkComissions}*/}
							{/*>*/}
							{/*	Расчитать комиссии*/}
							{/*</Button>*/}
							{/*<Button type="primary"*/}
							{/*        style={{marginBottom: 15}}*/}
							{/*        onClick={createMTOs}*/}
							{/*>*/}
							{/*	Создать приказы*/}
							{/*</Button>*/}




							<Table
								// rowSelection={{
								// 	type: 'checkbox',
								// 	onChange: (selectedRowKeys, selectedRows) => {
								// 		setTradingItems(selectedRows.map(el=>el._id))
								// 	},
								// 	getCheckboxProps: (record) => (
								// 		{
								// 			disabled: record.status === 'error',
								// 			// Column configuration not to be checked
								// 			// name: record.name,
								// 			value: record._id,
								// 		}),
								// }}
								columns={columns} dataSource={AISData} />

						</div>
					</TabPane>
				</Tabs>
			</div>
			<Drawer
				title="Фильтр"
				width={720}
				onClose={onClose}
				open={open}
				styles={{
					body: {
						paddingBottom: 80,
					},
				}}
				extra={
					<Space>
						<Button onClick={resetFilter}>Сбросить</Button>
						<Button onClick={searchByFilter} type="primary">
							Применить
						</Button>
					</Space>
				}
			>

				<Form form={form}>
					<Form.Item
						name="status"
						label="Статус"
					>
						<Select placeholder="" options={statuses}  value={filterStatus} onChange={(e)=>{console.log(e); setFilterStatus(e)}}>
							{/*<Option value="private">Private</Option>*/}
							{/*<Option value="public">Public</Option>*/}
						</Select>
					</Form.Item>
					<Form.Item
						name="dateTime"
						label="Дата"
					>
						<DatePicker.RangePicker
							style={{
								width: '100%',
							}}
							// key={keyValue}
							getPopupContainer={(trigger) => trigger.parentElement}
							onChange={(e)=>{setFilterDates(e)}}
							value={filterDates}
						/>
					</Form.Item>
				</Form>

			</Drawer>
			<PDFModal visible={modalFileVisible}
			          fileUrl={modalFileUrl}
			          fileData={{ext:'pdf'}}
			          onClose={() => setModalFileVisible(false)}
			/>
			<Modal title={'Пулл '+(pullData?.pullId || '')}
			       visible={isModalVisible}
				// okText={'Сохранить и отправить'}
				// onOk={handleOk}
				   onCancel={handleCancel}
				   width={1250}
				   confirmLoading={load}
				// cancelButtonProps={{ghost:true}}
				// cancelText={''}
				   footer={null}
			>

				<Table
					rowKey={'_id'}
					size="small"
					dataSource={pullItems}
					columns={columnsForEditPull}
					pagination={false}
					summary={(pageData) => {

						console.log(pageData)

						let totalAmount = 0;
						let totalOtherCommissions = 0;
						let totalBankComission = 0;
						let totalBankComissionKZT = 0;
						pageData.forEach((record) => {
							totalAmount += Number(record?.aisData?.vAmount || 0);
							totalOtherCommissions += Number(record?.aisData?.otherCommissions || 0);
							totalBankComission += Number(record?.aisData?.vBankComission || 0);
							totalBankComissionKZT += Number(record?.aisData?.vBankComissionKZT || 0);
						});
						return (
							<>
								<Table.Summary.Row>
									<Table.Summary.Cell index={0}>Итого</Table.Summary.Cell>
									<Table.Summary.Cell index={1}/>
									<Table.Summary.Cell index={2}/>
									<Table.Summary.Cell index={3}/>
									<Table.Summary.Cell index={4}/>
									<Table.Summary.Cell index={5}>
										<Text >${totalAmount.toFixed(2)}</Text>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={6}>
										<Text>{totalOtherCommissions.toFixed(2)}</Text>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={7}>
										<Text >${totalBankComission.toFixed(2)}</Text>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={8}>
										<Text>{totalBankComissionKZT.toFixed(2)}KZT</Text>
									</Table.Summary.Cell>
								</Table.Summary.Row>
							</>
						);
					}}
				/>
			</Modal>

			<Modal title={'Пулл '+(pullData?.pullId || '')}
			       visible={isSystemModalVisible}
				// okText={'Сохранить и отправить'}
				// onOk={handleOk}
				   onCancel={handleSystemCancel}
				   width={1250}
				   confirmLoading={load}
				// cancelButtonProps={{ghost:true}}
				// cancelText={''}
				   footer={null}
			>
				<Form
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 15 }}
					// onFinish={onFinishAisData}
					scrollToFirstError={true}
					form={form2}
				>
					<Card size="small" title="" style={{marginBottom: 15}}>

						<Form.Item label={'account_id'} name={'account_id'} ><Input /></Form.Item>
						<Form.Item label={'at'} name={'at'} ><Input /></Form.Item>
						<Form.Item label={'description'} name={'description'} ><TextArea rows={4} /></Form.Item>
						<Form.Item label={'entry_type'} name={'entry_type'} ><Input /></Form.Item>
						<Form.Item label={'event_id'} name={'event_id'} ><Input /></Form.Item>
						<Form.Item label={'event_ulid'} name={'event_ulid'} ><Input /></Form.Item>
						<Form.Item label={'id'} name={'id'} ><Input /></Form.Item>
						<Form.Item label={'net_amount'} name={'net_amount'} ><Input /></Form.Item>
						<Form.Item label={'per_share_amount'} name={'per_share_amount'} ><Input /></Form.Item>
						<Form.Item label={'price'} name={'price'} ><Input /></Form.Item>
						<Form.Item label={'qty'} name={'qty'} ><Input /></Form.Item>
						<Form.Item label={'settle_date'} name={'settle_date'} ><Input /></Form.Item>
						<Form.Item label={'status'} name={'status'} ><Input /></Form.Item>
						<Form.Item label={'symbol'} name={'symbol'} ><Input /></Form.Item>
						<Form.Item label={'system_date'} name={'system_date'} ><Input /></Form.Item>

					</Card>

				</Form>
			</Modal>
			<Modal title={'Пулл '+(pullData?.pullId || '')}
			       visible={isSystemModalVisible2}
				// okText={'Сохранить и отправить'}
				// onOk={handleOk}
				   onCancel={handleSystemCancel2}
				   width={1250}
				   confirmLoading={load}
				// cancelButtonProps={{ghost:true}}
				// cancelText={''}
				   footer={null}
			>
				<Form
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 15 }}
					// onFinish={onFinishAisData}
					scrollToFirstError={true}
					form={form3}
				>
					<Card size="small" title="" style={{marginBottom: 15}}>

						<Form.Item label={'id'} name={'id'} ><Input /></Form.Item>
						<Form.Item label={'statement-status'} name={'statement-status'} ><Input /></Form.Item>
						<Form.Item label={'document-number'} name={'document-number'} ><Input /></Form.Item>
						<Form.Item label={'statement-reference'} name={'statement-reference'} ><Input /></Form.Item>
						<Form.Item label={'amount-sender'} name={'amount-sender'} ><Input /></Form.Item>
						<Form.Item label={'amount-recipient'} name={'amount-recipient'} ><Input /></Form.Item>
						<Form.Item label={'name-sender'} name={'name-sender'} ><Input /></Form.Item>
						<Form.Item label={'name-recipient'} name={'name-recipient'} ><Input /></Form.Item>
						<Form.Item label={'rnn-sender'} name={'rnn-sender'} ><Input /></Form.Item>
						<Form.Item label={'rnn-recipient'} name={'rnn-recipient'} ><Input /></Form.Item>
						<Form.Item label={'account-sender'} name={'account-sender'} ><Input /></Form.Item>
						<Form.Item label={'account-recipient'} name={'account-recipient'} ><Input /></Form.Item>
						<Form.Item label={'knp-code'} name={'knp-code'} ><Input /></Form.Item>
						<Form.Item label={'date-update'} name={'date-update'} ><Input /></Form.Item>
						<Form.Item label={'date-value-sender'} name={'date-value-sender'} ><Input /></Form.Item>
						<Form.Item label={'date-prov-sender'} name={'date-prov-sender'} ><Input /></Form.Item>
						<Form.Item label={'date-value-recipient'} name={'date-value-recipient'} ><Input /></Form.Item>
						<Form.Item label={'date-prov-recipient'} name={'date-prov-recipient'} ><Input /></Form.Item>
						<Form.Item label={'payment-purpose'} name={'payment-purpose'} ><TextArea rows={4} /></Form.Item>
						<Form.Item label={'payment-purpose-sender'} name={'payment-purpose-sender'} ><TextArea rows={4} /></Form.Item>
						<Form.Item label={'payment-purpose-recipient'} name={'payment-purpose-recipient'} ><TextArea rows={4} /></Form.Item>
						<Form.Item label={'mfo-sender'} name={'mfo-sender'} ><Input /></Form.Item>
						<Form.Item label={'mfo-recipient'} name={'mfo-recipient'} ><Input /></Form.Item>
						<Form.Item label={'currency'} name={'currency'} ><Input /></Form.Item>
						<Form.Item label={'client-side'} name={'client-side'} ><Input /></Form.Item>
						<Form.Item label={'date-sender'} name={'date-sender'} ><Input /></Form.Item>
						<Form.Item label={'date-recipient'} name={'date-recipient'} ><Input /></Form.Item>

					</Card>

				</Form>
			</Modal>
		</>
	)
}

export default TransferPulls;
