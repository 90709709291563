import {ConfigProvider, Result, theme} from "antd";
import AdminLayout from "../../layouts/AdminLayout";
import Dashboard from "../Dashboard";
import {useRoutes} from "react-router-dom";
import TickerList from "../Tickers/TickerList";
import UserList from "../Users/UserList";
import UserLive from "../Users/UserLive";
import User from "../Users/User";
import TickerLog from "../Logs/TickerLog";
import Ticker from "../Tickers/Ticker";
import Comments from "../Comments/Comments";
import AppStore from "../App/AppStore";
import Portfolios from "../Portfolios/Portfolios";
import Portfolio from "../Portfolios/Portfolio";
import Accrual from "../Accrual/Accrual";
import Transfers from "../Transfers/Transfers";
import TransfersV2 from "../TransfersV2/TransfersV2";
import TransferPulls from "../TransferPulls/TransferPulls";
import Deposits from "../Deposits/Deposits";
import Orders from "../Orders/Orders";
import Transactions from "../Transactions/Transactions";
import NewsTranslate from "../NewsTranslate/NewsTranslate";
import Reports from "../Reports/Reports";

function NoMatch() {
  return (
      <div>
        <Result
            status="404"
            title="404"
            subTitle="Извините, страница, которую вы посетили, не существует."
            extra={
              <a href={"/"} type="primary">
                На главную
              </a>
            }
        />
      </div>
  );
}

function App() {

  let element = useRoutes([
    {
      path: "/",
      element: <AdminLayout/>,
      children: [
        {path: "/", element: <UserList testInv={false}/> },
        {path: "/tickers", element: <TickerList/>},
        {path: "/tickers/:tickerId", element: <Ticker/>},
        {path: "/usersProd", element: <UserList testInv={false}/>},
        {path: "/usersTest", element: <UserList testInv={true}/>},
        {path: "/users/:userId", element: <User mode={null}/>},
        {path: "/usersProd/:userId", element: <User mode={'live'}/>},
        {path: "/usersTest/:userId", element: <User mode={'sandbox'}/>},
        {path: "/logs/tickers", element: <TickerLog/>},
        {path: "/comments/list", element: <Comments/>},
        {path: "/portfolios", element: <Portfolios/>},
        {path: "/portfolios/:portfolioId", element: <Portfolio type={'edit'}/>},
        {path: "/portfolios/add", element: <Portfolio type={'add'}/>},
        {path: "/accrualProd", element: <Accrual testInv={false}/>},
        {path: "/accrualTest", element: <Accrual testInv={true}/>},
        {path: "/transfersProd", element: <Transfers testInv={false}/>},
        {path: "/transfersTest", element: <Transfers testInv={true}/>},
        {path: "/transfersV2", element: <TransfersV2 />},
        {path: "/transferPulls", element: <TransferPulls />},
        {path: "/depositsProd", element: <Deposits testInv={false}/>},
        {path: "/depositsTest", element: <Deposits testInv={true}/>},
        {path: "/depositsProd", element: <Deposits testInv={false}/>},
        {path: "/ordersTest", element: <Orders testInv={true}/>},
        {path: "/ordersProd", element: <Orders testInv={false}/>},
        {path: "/transactions", element: <Transactions/>},
        {path: "/newsTranslate", element: <NewsTranslate/>},
        {path: "/reports", element: <Reports />},
      ],
    },
    { path: "*", element: <NoMatch /> },
  ]);

  return (
    <>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
        }}
      >
        {element}
      </ConfigProvider>
    </>
  );
}

export default App;

AppStore.resize(window.innerWidth, window.innerHeight);

(function () {
  window.addEventListener("resize", resizeThrottler, false);

  let resizeTimeout;

  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        actualResizeHandler(this);

        // The actualResizeHandler will execute at a rate of 15fps
      }, 500);
    }
  }

  function actualResizeHandler(e) {
    AppStore.resize(e.innerWidth, e.innerHeight);
  }
})();
