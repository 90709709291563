import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Input, message} from 'antd';
import {apiQuery, apiQueryError} from "../../Utils";

const ConfirmPass = ({btnText = '', onFinish, btnType = 'primary', danger = false, loading}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setConfirmLoading(false)
  };

  const checkPass = (values) => {
    setConfirmLoading(true)
    apiQuery("/admin/checkPass", {
      method: "POST",
      body: values,
    })
      .then((data) => {
        if (data?.error) {
          setConfirmLoading(false)
          return message.error(data.error.msg);
        }
        setIsModalOpen(false);
        onFinish();
        setConfirmLoading(false)
      })
      .catch((error) => {
        apiQueryError({error});
        setConfirmLoading(false)
      });
  }

  useEffect(() => {
    form.resetFields()
  }, [isModalOpen])

  return (
    <>
      <Button type={btnType} danger={danger} loading={loading} onClick={showModal} htmlType={'button'}>
        {btnText}
      </Button>
      <Modal title="Для подтверждения действия введите пароль от своей учетной записи администратора"
             open={isModalOpen}
             onOk={() => form.submit()}
             onCancel={handleCancel}
             width={320}
             confirmLoading={confirmLoading}
      >
        <Form
          form={form}
          layout={'vertical'}
          onFinish={checkPass}
          autoComplete="off"
        >
          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};
export default ConfirmPass;