import {
	Badge,
	Breadcrumb,
	Button,
	Card,
	Form, Input,
	message,
	Modal,
	Radio,
	Select,
	Space,
	Spin,
	Table,
	Tabs,
	Tag
} from "antd";
import userStore from "../Auth/userStore";
import {useCallback, useEffect, useState} from "react";
import Utils, {apiQuery, apiQueryError} from "../../Utils";
import {Link} from "react-router-dom";
import moment from "moment";
import StatusBlock from "../Users/StatusBlock";
import ConfirmPass2 from "../ConfirmPass/ConfirmPass2";


const { TextArea } = Input;




const Transactions = (params) => {

	const testInv = Utils.apiDomain()==='app2.geekoinvest.kz'
	const [items, setItems] = useState({all: [], iinNotFound: [], sentToAis: [], rejected: [], refundApproved: [], refundSent: [], docRequested: [], blocked: []});
	const [itemsCount, setItemsCount] = useState({all: 0, iinNotFound: 0, sentToAis: 0, rejected: 0, refundApproved: 0, refundSent: 0, docRequested: 0, blocked: 0});
	const [itemsPage, setItemsPage] = useState({all: 1, iinNotFound: 1, sentToAis: 1, rejected: 1, refundApproved: 1, refundSent: 1, docRequested: 1, blocked: 1});
	const [pageSize, setPageSize] = useState(5);
	const [activeTab, setActiveTab] = useState('all');
	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [load, setLoad] = useState(null);


	const showModal = (data) => {

		// console.log(userStore.userData)

		for (let key of Object.keys(data)) {
			let d = {};
			d[key] = data[key];
			form.setFieldsValue(d);


		}

		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		form.resetFields([])
	};
	const onTabsChange = (tab) => {

		setActiveTab(tab)
		transactionsGet({tab, page: {
				current: 1,
				pageSize
			}})
	}

	const transactionsGet = useCallback((data = {}) => {

		console.log('transactionsGet')
		const tab = data.tab;
		// data.filterMode = filterMode;
		// data.filterValue = filterValue;
		data.mode = testInv ?'sandbox' : 'live';
		// data.filterTab = filterTab.current[tab]

		console.log(data)
		apiQuery("/admin/getTransactions", {
			method: "POST",
			body: data,
		})
			.then((data) => {

				console.log(data)

				setItems((v) => {
					const d = {...v}
					d[tab] = data.items;
					return d
				});
				setItemsCount((v) => {
					const d = {...v}
					d[tab] = data.itemsCount;
					console.log(d)
					return d
				});
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}, [])

	useEffect(() => {
		transactionsGet({tab: 'all'});
	}, [transactionsGet, testInv]);


	const addComment = async(data)=>{
		if(!userStore.userData.role.cfo && !userStore.userData.role.backOfficer && !!userStore.userData.role.compliance){
			return false
		}

		apiQuery("/admin/saveTransactionComment", {
			method: "POST",
			body: data,
		})
			.then((data) => {

				transactionsGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				// setItems((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.items;
				// 	return d
				// });
				// setItemsCount((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.itemsCount;
				// 	console.log(d)
				// 	return d
				// });
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const rejectTransaction = async(data)=>{
		if(!userStore.userData.role.cfo && !userStore.userData.role.backOfficer){
			return false
		}

		data.action= 'reject'

		apiQuery("/admin/rejectTransaction", {
			method: "POST",
			body: data,
		})
			.then((data) => {
				transactionsGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				// setItems((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.items;
				// 	return d
				// });
				// setItemsCount((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.itemsCount;
				// 	console.log(d)
				// 	return d
				// });
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const resolveTransaction = async(data)=>{
		if(!userStore.userData.role.cfo && !userStore.userData.role.backOfficer){
			return false
		}

		data.action= 'resolve'

		apiQuery("/admin/resolveTransaction", {
			method: "POST",
			body: data,
		})
			.then((data) => {
				transactionsGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				// setItems((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.items;
				// 	return d
				// });
				// setItemsCount((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.itemsCount;
				// 	console.log(d)
				// 	return d
				// });
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}
	const refundApproveTransaction = async(data)=>{
		if(!userStore.userData.role.compliance){
			return false
		}

		data.action= 'refundApprove'

		apiQuery("/admin/saveTransactionComment", {
			method: "POST",
			body: data,
		})
			.then((data) => {
				transactionsGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				// setItems((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.items;
				// 	return d
				// });
				// setItemsCount((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.itemsCount;
				// 	console.log(d)
				// 	return d
				// });
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}
	const refundSentTransaction = async(data)=>{
		if(!userStore.userData.role.cfo && !userStore.userData.role.backOfficer){
			return false
		}

		data.action= 'refund'

		apiQuery("/admin/saveTransactionComment", {
			method: "POST",
			body: data,
		})
			.then((data) => {
				transactionsGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				// setItems((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.items;
				// 	return d
				// });
				// setItemsCount((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.itemsCount;
				// 	console.log(d)
				// 	return d
				// });
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const editIIN = async(data)=>{
		if(!userStore.userData.role.cfo && !userStore.userData.role.backOfficer){
			return false
		}
		data.action= 'editIIN'
		data.iin = data.addField

		console.log(data)

		apiQuery("/admin/saveTransactionComment", {
			method: "POST",
			body: data,
		})
			.then((data) => {
				transactionsGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				// setItems((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.items;
				// 	return d
				// });
				// setItemsCount((v) => {
				// 	const d = {...v}
				// 	d[tab] = data.itemsCount;
				// 	console.log(d)
				// 	return d
				// });
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const columns = [
		{
			title: "date",
			dataIndex: "date",
			// key: "email",
			render: (text, record, index) => {
				return (
					<span>{moment(record.createdAt).format('DD.MM.YYYY')}</span>
				);
			},
			// sorter: true,
		},
		{
			title: "time",
			dataIndex: "time",
			key: "time",
			render: (text, record, index) => {
				return <span>{moment(record.createdAt).format("HH:mm:ss")}</span>;
			},
			// sorter: true,
		},
		{
			title: "ID",
			dataIndex: "ID",
			key: "bankData.id",
			render: (text, record, index) => {
				return <Link onClick={()=>{showModal(record.bankData)}}>{record.bankData.id}</Link>;
			},
			// sorter: true,
		},
		{
			title: "Клиент (ФИО/Наименование)",
			dataIndex: "name-sender",
			key: "bankData.nameSender",
			render: (text, record, index) => {
				return <span>{record.bankData['name-sender']}</span>;
			},
			// sorter: true,
		},
		{
			title: "ИИН/БИН",
			dataIndex: "rnn-sender",
			key: "bankData.rnn-sender",
			render: (text, record, index) => {
				return <span>{record.bankData['rnn-sender-custom'] || ( record.bankData['rnn-sender']||'')}</span>;
			},
			// sorter: true,
		},
		{
			title: "Сумма",
			dataIndex: "amount-sender",
			key: "bankData.amount-sender",
			render: (text, record, index) => {
				return <span>{record.bankData['amount-sender']}</span>;
			},
			// sorter: true,
		},
		{
			title: "Комиссия",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				return <span>{record.commission}</span>;
			},
			// sorter: true,
		},
		{
			title: "Направление",
			dataIndex: "route",
			key: "route",
			render: (text, record, index) => {
				return <span>{record.route==='deposit' ? 'Пополнение' : (
					record.route==='refund' ? 'Возврат' : (
						record.route==='withdrawal' ? 'Вывод' : 'неизвестно'
					)
				)}</span>;
			},
			// sorter: true,
		},

		{
			title: "Статус",
			dataIndex: "status",
			key: "status",
			render: (text, record, index) => {
				return <span>{record.status==='iinNotFound' ? 'ИИН не найден' : (
					record.status==='sentToAis' ? 'Отправлен в АИС' : (
						record.status==='rejected' ? 'Отклонен' : (
							record.status==='blocked' ? 'Заблокирован' : (
								record.status==='docRequested' ? 'Запрошен документ' : (
									record.status==='refundApproved' ? 'Возврат одобрен' : (
										record.status==='refundSent' ? 'Возврат отправлен' : 'неизвестно'
									)
								)
							)
						)
					)
				)}</span>;
			},
			// sorter: true,
		},
		{
			title: "Зачисление",
			dataIndex: "type",
			key: "type",
			render: (text, record, index) => {
				return <span>{record.type==='auto' ? 'Автоматическое' : (
					record.type==='hand' ? 'Ручное' : 'неизвестно'
				)}</span>;
			},
			// sorter: true,
		},
		{
			title: "Комментарии от Бэк-Офиса",
			dataIndex: "backComment",
			// key: "email",
			render: (text, record, index) => {
				return <>
					{!record.backComment || record.backComment.length===0 ? <></> :
						record.backComment.map(el=>
							<div>
								<div>{el.name}</div>
								<div>{el.time}</div>
								<div>{el.text}</div>
								{el.file ? <a href={el.file} target={'_blank'}>файл</a>
									:<></>}
							</div>
						)
					}
				</>

			},
			// sorter: true,
		},
		{
			title: "Комментарии от Compliance",
			dataIndex: "complianceComment",
			// key: "email",
			render: (text, record, index) => {
				return <>
					{!record.complianceComment || record.backComment.length===0 ? <></> :
						record.complianceComment.map(el=>
							<div>
								<div>{el.name}</div>
								<div>{el.time}</div>
								<div>{el.text}</div>
								{el.file ? <a href={el.file} target={'_blank'}>файл</a>
									:<></>}
							</div>
						)
					}
				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Исполнитель)",
			dataIndex: "backOfficer",
			// key: "email",
			render: (text, record, index) => {
				if(!record?.backOfficer) {
					return <></>
				}

				return <>
					{record?.backOfficer.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Подтверждающий)",
			dataIndex: "cfo",
			// key: "email",
			render: (text, record, index) => {
				if(!record?.cfo) {
					return <></>
				}

				return <>
					{record?.cfo.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Compliance))",
			dataIndex: "compliance",
			// key: "email",
			render: (text, record, index) => {

				if(!record?.compliance) {
					return <></>
				}

				return <>
					{record?.compliance.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

					</>
				;
			},
			// sorter: true,
		},
		{
			title: "Причина Возврата",
			dataIndex: "refundReason",
			// key: "email",
			render: (text, record, index) => {
				return <span>{record.refundReason || ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Ответ АИС",
			dataIndex: "aisResponse",
			// key: "email",
			render: (text, record, index) => {
				return (
					<>
						<div>{record?.aisResponse?.time || ''}</div>
						<div>{record?.aisResponse?.msg || ''}</div>
					</>
				);
			},
			// sorter: true,
		},

		{
			title: "Действия",
			dataIndex: "actions",
			// key: "email",
			render: (text, record, index) => {

				if(record.status==='sentToAis'){
					return <></>
				}
				if(record.status==='iinNotFound'){

					if(userStore.userData.role.backOfficer) {
						if (!record?.backOfficerLastAction?.action) {
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
					                      fieldCommentVisible={true}
			                              fieldFileVisible={true}
							              // danger={!userData?.blocking}
							              // loading={userBlockLoad}
							              onFinish={(data) => {
							              	addComment({_id:record._id,...data})
							              }}/>
								</div>
								<div>
									<ConfirmPass2 btnText={'Редактировать ИИН'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
									              addFieldVisible={true}
									              addFieldLabel={'ИИН'}
									              addFieldValue={record.bankData['rnn-sender'] || (record.bankData['rnn-sender-custom'] ||'')}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          editIIN({_id:record._id,...data})
										          }}/>
								</div>
								<div>
									<ConfirmPass2 btnText={'Зачислить'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          resolveTransaction({_id:record._id,...data})
										          }}/>
								</div>
								<div>
									<ConfirmPass2 btnText={'Отклонить'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          rejectTransaction({_id:record._id,...data})
										          }}/>
								</div>
							</>
						}

						if(record?.backOfficerLastAction?.action==='reject' || record?.backOfficerLastAction?.action==='resolve'){
							return <div>
									<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
					                      fieldCommentVisible={true}
					                      fieldFileVisible={true}
							              // danger={!userData?.blocking}
							              // loading={userBlockLoad}
							              onFinish={(data) => {
							              	addComment({_id:record._id,...data})
							              }}/>
								</div>
						}
						if(record?.backOfficerLastAction?.action==='editIIN' && record?.cfoLastAction?.action==='editIIN'){
							return<>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
					                      fieldCommentVisible={true}
					                      fieldFileVisible={true}
							              // danger={!userData?.blocking}
							              // loading={userBlockLoad}
							              onFinish={(data) => {
							                addComment({_id:record._id,...data})
							              }}/>
								</div>
								<div>
									<ConfirmPass2 btnText={'Зачислить'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          resolveTransaction({_id:record._id,...data})
										          }}/>
								</div>
								<div>
									<ConfirmPass2 btnText={'Отклонить'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          rejectTransaction({_id:record._id,...data})
										          }}/>
								</div>
							</>
						}

						return <div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>
					}


					if(userStore.userData.role.cfo) {
						if (!record?.cfoLastAction?.action || (record?.cfoLastAction?.action && record?.cfoLastAction?.action!==record?.backOfficerLastAction?.action)) {

							if(record?.backOfficerLastAction?.action === 'reject') {

								return <>
									<div>
										<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
				                          fieldCommentVisible={true}
				                          fieldFileVisible={true}
							              // danger={!userData?.blocking}
							              // loading={userBlockLoad}
							              onFinish={(data) => {
							              	addComment({_id:record._id,...data})
							              }}/>
									</div>
									<div>
										<ConfirmPass2 btnText={'Отклонить'}
									              btnType={'default'}
								                  fieldCommentVisible={true}
								                  fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          rejectTransaction({_id:record._id,...data})
										          }}/>
									</div>
								</>
							}

							if(record?.backOfficerLastAction?.action === 'resolve') {

								return <>
									<div>
										<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
				                          fieldCommentVisible={true}
				                          fieldFileVisible={true}
							              // danger={!userData?.blocking}
							              // loading={userBlockLoad}
							              onFinish={(data) => {
							              	addComment({_id:record._id,...data})
							              }}/>
									</div>
									<div>
										<ConfirmPass2 btnText={'Зачислить'}
									              btnType={'default'}
								                  fieldCommentVisible={true}
								                  fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          resolveTransaction({_id:record._id,...data})
										          }}/>
									</div>
								</>
							}
							if(record?.backOfficerLastAction?.action === 'editIIN') {

								return <>
									<div>
										<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
				                          fieldCommentVisible={true}
				                          fieldFileVisible={true}
							              // danger={!userData?.blocking}
							              // loading={userBlockLoad}
							              onFinish={(data) => {
							              	addComment({_id:record._id,...data})
							              }}/>
									</div>
									<div>
										<ConfirmPass2 btnText={'Редактировать ИИН'}
										              btnType={'default'}
										              fieldCommentVisible={true}
										              fieldFileVisible={true}
										              addFieldVisible={true}
										              addFieldLabel={'ИИН'}
										              addFieldValue={record.bankData['rnn-sender'] || (record.bankData['rnn-sender-custom'] ||'')}
											// danger={!userData?.blocking}
											// loading={userBlockLoad}
											          onFinish={(data) => {
												          editIIN({_id:record._id,...data})
											          }}/>
									</div>
								</>
							}
						}

						return <div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
							              // danger={!userData?.blocking}
							              // loading={userBlockLoad}
							              onFinish={(data) => {
							              	addComment({_id:record._id,...data})
							              }}/>
							</div>


					}

					return <div>
						<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
								          fieldCommentVisible={true}
								          fieldFileVisible={true}
							              // danger={!userData?.blocking}
							              // loading={userBlockLoad}
							              onFinish={(data) => {
							              	addComment({_id:record._id,...data})
							              }}/>
					</div>

				}


				if(record.status==='rejected'){
					if(userStore.userData.role.compliance) {
						if (!record?.complianceLastAction?.action) {
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>
								<div>
									<ConfirmPass2 btnText={'Возврат одобрен'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          refundApproveTransaction({_id:record._id,...data})
										          }}
									/>
								</div>

							</>
						}
					}
					return <>
						<div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>

					</>
				}

				if(record.status==='refundApproved'){

					if(userStore.userData.role.backOfficer || userStore.userData.role.cfo){

						if(record?.backOfficerLastAction?.action!=='refund' && record?.cfoLastAction?.action!=='refund'){
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>
								<div>
									<ConfirmPass2 btnText={'Возврат отправлен'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          refundSentTransaction({_id:record._id,...data})
										          }}
									/>
								</div>

							</>
						}


					}
					return <>
						<div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>

					</>
				}

				return <></>
			},
			// sorter: true,
		},

	];

	if (!items) return <>Загрузка...</>;

	return (
		<>
			<Breadcrumb style={{margin: "16px 0"}} items={[
				{title: 'Транзакции'}
			]}/>
			<Tabs defaultActiveKey="all" onChange={onTabsChange} items={[
	{
		key     : 'all',
		label   : <>Все <Badge count={itemsCount.all} style={{
			backgroundColor: '#faad14',
		}}/></>,
		children: <div className="site-layout-background" style={{minHeight: 360}}>

			<Table
				rowKey={'_id'}
				size="small"
				dataSource={items.all}
				columns={columns}
				onChange={(page, filters, sorter, extra) => {
					setItemsPage(v => {
						const d = {...v}
						d.all = page.current;
						console.log(d)
						return d
					})
					transactionsGet({
						page,
						filters,
						sorter,
						extra,
						tab: 'all'
					});
				}}
				pagination={{
					pageSize,
					showSizeChanger : true,
					onShowSizeChange: (current, ps) => setPageSize(ps),
					total           : itemsCount.all,
					current           : itemsPage.all,
				}}
			/>
		</div>
	},
	{
		key     : 'iinNotFound',
		label   : <>ИИН не найден в АИС <Badge count={itemsCount.iinNotFound} style={{
			backgroundColor: '#faad14',
		}}/></>,
		children: <div className="site-layout-background" style={{minHeight: 360}}>

			<Table
				rowKey={'_id'}
				size="small"
				dataSource={items.iinNotFound}
				columns={columns}
				onChange={(page, filters, sorter, extra) => {
					setItemsPage(v => {
						const d = {...v}
						d.iinNotFound = page.current;
						console.log(d)
						return d
					})
					transactionsGet({
						page,
						filters,
						sorter,
						extra,
						tab: 'iinNotFound'
					});
				}}
				pagination={{
					pageSize,
					showSizeChanger : true,
					onShowSizeChange: (current, ps) => setPageSize(ps),
					total           : itemsCount.iinNotFound,
					current           : itemsPage.iinNotFound,
				}}
			/>
		</div>
	},
	{
		key     : 'sentToAis',
		label   : <>Отправлены в АИС <Badge count={itemsCount.sentToAis} style={{
			backgroundColor: '#faad14',
		}}/></>,
		children: <div className="site-layout-background" style={{minHeight: 360}}>

			<Table
				rowKey={'_id'}
				size="small"
				dataSource={items.sentToAis}
				columns={columns}
				onChange={(page, filters, sorter, extra) => {
					setItemsPage(v => {
						const d = {...v}
						d.sentToAis = page.current;
						console.log(d)
						return d
					})
					transactionsGet({
						page,
						filters,
						sorter,
						extra,
						tab: 'sentToAis'
					});
				}}
				pagination={{
					pageSize,
					showSizeChanger : true,
					onShowSizeChange: (current, ps) => setPageSize(ps),
					total           : itemsCount.sentToAis,
					current           : itemsPage.sentToAis,
				}}
			/>
		</div>
	},
	{
		key     : 'rejected',
		label   : <>Отклонены <Badge count={itemsCount.rejected} style={{
			backgroundColor: '#faad14',
		}}/></>,
		children: <div className="site-layout-background" style={{minHeight: 360}}>

			<Table
				rowKey={'_id'}
				size="small"
				dataSource={items.rejected}
				columns={columns}
				onChange={(page, filters, sorter, extra) => {
					setItemsPage(v => {
						const d = {...v}
						d.rejected = page.current;
						console.log(d)
						return d
					})
					transactionsGet({
						page,
						filters,
						sorter,
						extra,
						tab: 'rejected'
					});
				}}
				pagination={{
					pageSize,
					showSizeChanger : true,
					onShowSizeChange: (current, ps) => setPageSize(ps),
					total           : itemsCount.rejected,
					current           : itemsPage.rejected,
				}}
			/>
		</div>
	},
	{
		key     : 'refundApproved',
		label   : <>Возврат одобрен <Badge count={itemsCount.refundApproved} style={{
			backgroundColor: '#faad14',
		}}/></>,
		children: <div className="site-layout-background" style={{minHeight: 360}}>

			<Table
				rowKey={'_id'}
				size="small"
				dataSource={items.refundApproved}
				columns={columns}
				onChange={(page, filters, sorter, extra) => {
					setItemsPage(v => {
						const d = {...v}
						d.refundApproved = page.current;
						console.log(d)
						return d
					})
					transactionsGet({
						page,
						filters,
						sorter,
						extra,
						tab: 'refundApproved'
					});
				}}
				pagination={{
					pageSize,
					showSizeChanger : true,
					onShowSizeChange: (current, ps) => setPageSize(ps),
					total           : itemsCount.refundApproved,
					current           : itemsPage.refundApproved,
				}}
			/>
		</div>
	},
	{
		key     : 'refundSent',
		label   : <>Возврат отправлен <Badge count={itemsCount.refundSent} style={{
			backgroundColor: '#faad14',
		}}/></>,
		children: <div className="site-layout-background" style={{minHeight: 360}}>

			<Table
				rowKey={'_id'}
				size="small"
				dataSource={items.refundSent}
				columns={columns}
				onChange={(page, filters, sorter, extra) => {
					setItemsPage(v => {
						const d = {...v}
						d.refundSent = page.current;
						console.log(d)
						return d
					})
					transactionsGet({
						page,
						filters,
						sorter,
						extra,
						tab: 'refundSent'
					});
				}}
				pagination={{
					pageSize,
					showSizeChanger : true,
					onShowSizeChange: (current, ps) => setPageSize(ps),
					total           : itemsCount.refundSent,
					current           : itemsPage.refundSent,
				}}
			/>
		</div>
	},
	{
		key     : 'docRequested',
		label   : <>Запрошены документы <Badge count={itemsCount.docRequested} style={{
			backgroundColor: '#faad14',
		}}/></>,
		children: <div className="site-layout-background" style={{minHeight: 360}}>

			<Table
				rowKey={'_id'}
				size="small"
				dataSource={items.docRequested}
				columns={columns}
				onChange={(page, filters, sorter, extra) => {
					setItemsPage(v => {
						const d = {...v}
						d.docRequested = page.current;
						console.log(d)
						return d
					})
					transactionsGet({
						page,
						filters,
						sorter,
						extra,
						tab: 'docRequested'
					});
				}}
				pagination={{
					pageSize,
					showSizeChanger : true,
					onShowSizeChange: (current, ps) => setPageSize(ps),
					total           : itemsCount.docRequested,
					current           : itemsPage.docRequested,
				}}
			/>
		</div>
	},
	{
		key     : 'blocked',
		label   : <>Заблокированы <Badge count={itemsCount.blocked} style={{
			backgroundColor: '#faad14',
		}}/></>,
		children: <div className="site-layout-background" style={{minHeight: 360}}>

			<Table
				rowKey={'_id'}
				size="small"
				dataSource={items.blocked}
				columns={columns}
				onChange={(page, filters, sorter, extra) => {
					setItemsPage(v => {
						const d = {...v}
						d.blocked = page.current;
						console.log(d)
						return d
					})
					transactionsGet({
						page,
						filters,
						sorter,
						extra,
						tab: 'blocked'
					});
				}}
				pagination={{
					pageSize,
					showSizeChanger : true,
					onShowSizeChange: (current, ps) => setPageSize(ps),
					total           : itemsCount.blocked,
					current           : itemsPage.blocked,
				}}
			/>
		</div>
	},

]}


	/>
			<Modal title={'Данные выписки'}
			       visible={isModalVisible}
				// okText={'Сохранить и отправить'}
				// onOk={handleOk}
				   onCancel={handleCancel}
				   width={800}
				   confirmLoading={load}
			>
				<Form
					form={form}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 15 }}
					// onFinish={onFinishAisData}
					scrollToFirstError={true}
				>
					<Card size="small" title="" style={{marginBottom: 15}}>


						<Form.Item label={'id'} name={'id'} ><Input /></Form.Item>
						<Form.Item label={'statement-status'} name={'statement-status'} ><Input /></Form.Item>
						<Form.Item label={'document-number'} name={'document-number'} ><Input /></Form.Item>
						<Form.Item label={'statement-reference'} name={'statement-reference'} ><Input /></Form.Item>
						<Form.Item label={'amount-sender'} name={'amount-sender'} ><Input /></Form.Item>
						<Form.Item label={'amount-recipient'} name={'amount-recipient'} ><Input /></Form.Item>
						<Form.Item label={'name-sender'} name={'name-sender'} ><Input /></Form.Item>
						<Form.Item label={'name-recipient'} name={'name-recipient'} ><Input /></Form.Item>
						<Form.Item label={'rnn-sender'} name={'rnn-sender'} ><Input /></Form.Item>
						<Form.Item label={'rnn-recipient'} name={'rnn-recipient'} ><Input /></Form.Item>
						<Form.Item label={'account-sender'} name={'account-sender'} ><Input /></Form.Item>
						<Form.Item label={'account-recipient'} name={'account-recipient'} ><Input /></Form.Item>
						<Form.Item label={'knp-code'} name={'knp-code'} ><Input /></Form.Item>
						<Form.Item label={'date-update'} name={'date-update'} ><Input /></Form.Item>
						<Form.Item label={'date-value-sender'} name={'date-value-sender'} ><Input /></Form.Item>
						<Form.Item label={'date-prov-sender'} name={'date-prov-sender'} ><Input /></Form.Item>
						<Form.Item label={'date-value-recipient'} name={'date-value-recipient'} ><Input /></Form.Item>
						<Form.Item label={'date-prov-recipient'} name={'date-prov-recipient'} ><Input /></Form.Item>
						<Form.Item label={'payment-purpose'} name={'payment-purpose'} ><TextArea rows={4} /></Form.Item>
						<Form.Item label={'payment-purpose-sender'} name={'payment-purpose-sender'} ><TextArea rows={4} /></Form.Item>
						<Form.Item label={'payment-purpose-recipient'} name={'payment-purpose-recipient'} ><TextArea rows={4} /></Form.Item>
						<Form.Item label={'mfo-sender'} name={'mfo-sender'} ><Input /></Form.Item>
						<Form.Item label={'mfo-recipient'} name={'mfo-recipient'} ><Input /></Form.Item>
						<Form.Item label={'currency'} name={'currency'} ><Input /></Form.Item>
						<Form.Item label={'client-side'} name={'client-side'} ><Input /></Form.Item>
						<Form.Item label={'date-sender'} name={'date-sender'} ><Input /></Form.Item>
						<Form.Item label={'date-recipient'} name={'date-recipient'} ><Input /></Form.Item>

					</Card>

				</Form>
			</Modal>
		</>
	);
}

export default Transactions;
