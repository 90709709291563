import {apiQuery, apiQueryError} from "../../Utils";
import {Breadcrumb, Table} from "antd";
import {Link} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import moment from 'moment';

const columns = [
  {
    title: 'Дата',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record, index) => {
      return <>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
    }
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '',
    dataIndex: 'k1',
    key: 'k1',
    render: (text, record, index) => {
      if (record.type !== 'ticker' ) return <></>
      return <Link to={`/tickers/detail/${record.ticker}`}>Открыть</Link>
    }
  },
];

const TickerLog = () => {
  const [items, setItems] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);

  const logsGet = useCallback((page) => {
    apiQuery('/admin/logs', {
      method: 'POST',
      body: {
        page
      }
    }).then((data) => {
      setItems(data.items)
      setItemsCount(data.itemsCount)
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [])

  useEffect(() => {
    logsGet()
  }, [logsGet])

  if (!items) return <>Загрузка...</>

  return (
      <>
        <Breadcrumb style={{margin: '16px 0'}}>
          <Breadcrumb.Item>Логи</Breadcrumb.Item>
        </Breadcrumb>
        <div className="site-layout-background"
             style={{minHeight: 360}}
        >
          <Table dataSource={items}
                 columns={columns}
                 pagination={{
                   pageSize: 10,
                   showSizeChanger: false,
                   total: itemsCount,
                   onChange: (page, pageSize) => {
                     logsGet(page)
                   }
                 }}
          />
        </div>
      </>
  )
}

export default TickerLog;