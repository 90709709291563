import {apiQuery, apiQueryError} from "../../Utils";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Breadcrumb, Button, Popconfirm, Table} from "antd";
import moment from "moment";

const Comments = () => {

  const [items, setItems] = useState(null);
  const [pageN, setPageN] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);
  const commentsGet = useCallback((page) => {
    apiQuery('/admin/comments', {
      method: 'POST',
      body: {
        page
      }
    }).then((data) => {
      setItems(data.items)
      setItemsCount(data.itemsCount)
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [])
  const commentsDel = useCallback((commentId) => {
    apiQuery('/admin/comments/'+commentId, {
      method: 'DELETE',
    }).then(() => {
      commentsGet(pageN)
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [commentsGet, pageN])

  const columns = useMemo(() => [
    {
      title: 'Текст',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: (text, record, index) => {
        return <><div>{record.type}</div>
          {record.type === 'news' && <a href={'https://v2.geeko.tech/news/'+record.targetId}
                                        target={'_blank'}
                                        rel="noreferrer"
          >Перейти в новость</a>}</>
      }
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record, index) => {
        return <>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
      }
    },
    {
      title: 'Пользователь',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        return <>{record.userData.email} - {record.userData.displayName}</>
      }
    },
    {
      title: '',
      dataIndex: 'k1',
      key: 'k1',
      render: (text, record, index) => {
        return <Popconfirm
            title="Удалить комментарий?"
            onConfirm={()=>{commentsDel(record._id)}}
            okText="Да"
            cancelText="Нет"
        >
          <Button>Удалить</Button>
        </Popconfirm>
      }
    },
  ],[commentsDel]);

  useEffect(() => {
    commentsGet()
  }, [commentsGet])

  if (!items) return <>Загрузка...</>

  return (
      <>
        <Breadcrumb
            style={{
              margin: '16px 0',
            }}
        >
          <Breadcrumb.Item>Комментарии</Breadcrumb.Item>
        </Breadcrumb>
        <div
            className="site-layout-background"
            style={{
              minHeight: 360,
            }}
        >

          <Table dataSource={items}
                 columns={columns}
                 pagination={{
                   pageSize: 10,
                   showSizeChanger: false,
                   total: itemsCount,
                   onChange: (page, pageSize) => {
                     setPageN(page)
                     commentsGet(page)
                   }
                 }}
          />

        </div>
      </>
  )

}

export default Comments;