import React, {useState} from 'react';
import { Form, DatePicker, Select, Button } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

const statuses = [
  {value:'wait', label:'Ожидает'},
  {value:'cancel', label:'Отменен'},
  {value:'done', label:'Выполнен'},
  {value:'error', label:'Ошибка'},
]

const TransfersForm = ({ onFinish }) => {
  const [dateRange, setFilterDates] = useState([null, null]);

  return (
    <Form onFinish={(values) => onFinish(values, 'transfers')}>
      <Form.Item name="dateRange" label="Период">
        <RangePicker
          onChange={(e)=>{setFilterDates(e)}}
          value={dateRange}
        />
      </Form.Item>
      <Form.Item name="status" label="Статус">
        <Select
          placeholder="Выберите статус"
          options={statuses}
        >
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Выгрузить отчет</Button>
      </Form.Item>
    </Form>
  );
};

export default TransfersForm;