import {
	Breadcrumb,
	Button,
	Col,
	DatePicker,
	Drawer,
	Form,
	Input,
	message,
	Row,
	Select,
	Table
} from "antd";
import {useCallback, useEffect, useState} from "react";
import {apiQuery, apiQueryError} from "../../Utils";
import moment from "moment";

const Transfers = (params) => {

	const [AISData, setAISData] = useState(false);
	const testInv = params.testInv
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    date: null,
  });
	const aISDataGet = useCallback((data = {}) => {

		console.log(filters);
		apiQuery("/admin/getAisDeposits", {
			method: 'POST',
			body: {testInv, ...filters}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)
				setAISData(data);
			})
			.catch((error) => {
				setAISData(false);
				apiQueryError({error});
			})
	}, [testInv, filters])


	const columns = [
		{
			title: ' Клиент',
			key: 'user',
			render: (_, record) => (
				<div size="middle">
					<a href={'/users/'+record.user.id} target="_blank" rel="noreferrer">{record.user.aisData.aLastName} {record.user.aisData.aFirstName} {record.user.aisData.aMiddleName}</a>
				</div>
			),
		},
		{
			title: 'Сумма',
			key: 'amount',
			render: (_, record) => (
				<div size="middle">
					<p>{record.amountInCurrency} $</p>
					<p>{record.amountInTenge} KZT</p>
				</div>
			),
		},
		{
			title: 'Банковские данные',
			key: 'bankDetails',
			render: (_, record) => (
				<div size="middle">
					<p>Система: {record.bankDetails.senderBank} </p>
					<p>Местонахождение: {record.bankDetails.senderBankLocation} </p>
					<p>Название: {record.bankDetails.bankName} </p>
					<p>Бик: {record.bankDetails.bankBIC} </p>
					<p>IBAN: {record.bankDetails.senderAccountNumber} </p>
					<p>КНП: {record.paymentPurposeCode} </p>
				</div>
			),
		},
		{
			title: 'Дата',
			key: 'createdAt',
			render: (_, record) => (
				<div size="middle">
					{moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')}
				</div>
			),
		},
	];

	const showDrawer = () => {
		setDrawerVisible(true);
	};

	const onClose = () => {
		setDrawerVisible(false);
	};

	useEffect(() => {
		aISDataGet()
	}, [aISDataGet, testInv])

  const handleFilterChange = (value, key) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

	const [form] = Form.useForm();

  const clearFilters = () => {
    setFilters({
      name: '',
      date: null,
    });

    aISDataGet({
      name: '',
      date: null,
    });
		// form.resetFields()
  };

  const applyFilters = () => {
    aISDataGet();
    onClose();
  };

	const handleDateChange = (date) => {
		setFilters({ ...filters, date: date ? date.format('YYYY-MM-DD') : null });
	};

	return (
		<>
			<Row justify="space-between" align="middle" style={{ margin: '16px 0' }}>
				<Col>
					<Breadcrumb style={{margin: '16px 0'}}>
						<Breadcrumb.Item>Переводы</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
				<Col>
					<Button type="default" onClick={showDrawer}>
						Фильтры
					</Button>
				</Col>
			</Row>

			<div
				style={{
					minHeight: 360,
				}}
			>

        <Drawer
          title="Фильтры"
          width={500}
          onClose={onClose}
          open={drawerVisible}
          bodyStyle={{ paddingBottom: 80 }}
          placement="right"
          footer={
            <Row justify="end" gutter={16}>
              <Col>
                <Button onClick={clearFilters} style={{ marginRight: 8 }}>
                  Очистить
                </Button>
              </Col>
              <Col>
                <Button onClick={applyFilters} type="primary">
                  Применить
                </Button>
              </Col>
            </Row>
          }
        >
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Имя">
                  <Input
                    placeholder="Введите имя"
										value={filters.name}
										onChange={(e) => handleFilterChange(e.target.value, 'name')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Дата">
                  <DatePicker
                    style={{ width: "100%" }}
										value={filters.date ? moment(filters.date) : null}
										onChange={handleDateChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>


				<Table columns={columns} dataSource={AISData} />
			</div>
		</>
	)
}

export default Transfers;
