import {Breadcrumb, Button, Col, Form, Input, message, Modal, Row, Select, Spin, Upload} from "antd";
import {useCallback, useEffect, useState} from "react";
import { PlusOutlined } from '@ant-design/icons';
import {apiQuery, apiQueryError} from "../../Utils";
import {Link, useNavigate, useParams} from "react-router-dom";
import ImagePreview from "../ImagePreview";
import ModalTickerAdd from "./ModalTickerAdd";
import TickerStore from "./TickerStore";
import {observer} from "mobx-react-lite";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TickerItem = observer(({ tickerId, tickerDel }) => {
  const item = TickerStore.tickerData[tickerId];

  useEffect(() => {
    TickerStore.getTicker(tickerId);
  }, [tickerId]);

  if (!item) return null;

  return (
    <>
      <Row>
        <Col flex={'30px'}>
          <ImagePreview src={item.logo} width={20} height={20}/>
        </Col>
        <Col flex={'auto'}>{item.symbol} - {item?.dataAsset?.name}</Col>
        <Col>
          <Button onClick={() => tickerDel(item?._id)}>Удалить</Button>
        </Col>
      </Row>
    </>
  );
})

const Portfolio = ({type}) => {
  const navigate = useNavigate();
  const {portfolioId} = useParams();
  const [data, setData] = useState()
  const [tickers, setTickers] = useState([])
  const [form] = Form.useForm();
  const [loadSave, setLoadSave] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({fileList: newFileList}) => setFileList(newFileList);
  const handleChange2 = ({fileList: newFileList}) => setFileList2(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const portfolioGet = useCallback(() => {
    apiQuery('/admin/portfolio/'+portfolioId).then((data) => {
      if (data?.error) return message.error(data.error.msg)
      setData(data)
      setTickers(data.tickers)
      form.setFieldsValue(data)

      if (data.logo) {
        setFileList([{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: data.logo,
        }])
      }

      if (data.background) {
        setFileList2([{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: data.background,
        }])
      }
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [portfolioId, setFileList])

  const onFinish = (values) => {
    setLoadSave(true)
    values.logo = fileList[0] ? fileList[0]?.thumbUrl || null : null;
    values.background = fileList2[0] ? fileList2[0]?.thumbUrl || null : null;
    values.tickers =  tickers;

    if (!portfolioId){
      apiQuery('/admin/portfolio', {
        method: 'POST',
        body: values
      }).then((data) => {
        setLoadSave(false)
        message.success('Данные сохранены')
        navigate('/portfolios/'+data._id)
      }).catch((error) => {
        apiQueryError({error})
        setLoadSave(false)
      })
      return
    }

    apiQuery('/admin/portfolio/'+portfolioId, {
      method: 'POST',
      body: values
    }).then(() => {
      portfolioGet()
      setLoadSave(false)
      message.success('Данные сохранены')
    }).catch((error) => {
      apiQueryError({error})
      setLoadSave(false)
    })
  };

  const tickerAdd = (tickerId) => {
    setTickers(v => {
      let m = [...v]
      m.push(tickerId)
      return m
    })
  }

  const tickerDel = (tickerId) => {
    setTickers(tickers.filter((item) => item !== tickerId))
  }

  useEffect(() => {
    if (type === 'edit' && portfolioId) portfolioGet()
    if (type === 'add') {
      setData({})
    }
  }, [portfolioId, portfolioGet, type])

  if (!data) return <Spin/>

  return <>

    <Breadcrumb
      style={{
        margin: '16px 0',
      }}
    >
      <Breadcrumb.Item><Link to={'/portfolios'}>Портфолио</Link></Breadcrumb.Item>
      <Breadcrumb.Item>{type === 'add' ? 'Добавить' : 'Редактировать'} портфолио</Breadcrumb.Item>
    </Breadcrumb>

    <div
      className="site-layout-background"
      style={{
        minHeight: 360,
      }}
    >

      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        style={{
          maxWidth: 800,
        }}
      >
        <Form.Item label={'Иконка'} name="logo">
          <Upload
            action="https://static.geekoinvest.kz/fake-upload"
            listType="picture-circle"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label={'Фон'} name="background">
          <Upload
            action="https://static.geekoinvest.kz/fake-upload"
            listType="picture-card"
            fileList={fileList2}
            onPreview={handlePreview}
            onChange={handleChange2}
          >
            {fileList2.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>

        <Form.Item
          label="Наименование"
          name="name"
          rules={[{required: true}]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Статус"
          name="status"
          rules={[{required: true}]}
        >
          <Select>
            <Select.Option value={0}>Не активен</Select.Option>
            <Select.Option value={1}>Активен</Select.Option>
          </Select>
        </Form.Item>

        <div style={{marginBottom: 15}}>
          {tickers.map((tickerId, index) => <div key={index} style={{margin: '5px 0'}}>
            <TickerItem tickerId={tickerId} tickerDel={tickerDel}/>
          </div>)}
        </div>

        <ModalTickerAdd tickers={tickers} tickerAdd={tickerAdd} tickerDel={tickerDel}/>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadSave}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>


    </div>
  </>
}

export default Portfolio;