import React, {useCallback, useEffect, useState} from "react";
import {apiQuery, apiQueryError} from "../../Utils";
import {Breadcrumb, Button, Popconfirm, Space, Table} from "antd";
import ImagePreview from "../ImagePreview";
import {Link} from "react-router-dom";
import {DeleteOutlined, MenuOutlined} from "@ant-design/icons";
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const Portfolios = () => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);

  const portfoliosGet = useCallback((page) => {
    apiQuery('/admin/portfolios', {
      method: 'POST',
      body: {
        page
      }
    }).then((data) => {
      setItems(data.items)
      setItemsCount(data.itemsCount)
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [])
  const portfoliosDel = useCallback((portfolioId) => {
    apiQuery('/admin/portfolios', {
      method: 'DELETE',
      body: {
        portfolioId
      }
    }).then((data) => {
      portfoliosGet(page)
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [])
  const portfoliosSortSave = useCallback((mass) => {
    apiQuery('/admin/portfolios/sortSave', {
      method: 'POST',
      body: {
        items: mass
      }
    }).then(() => {
    }).catch((error) => {
      apiQueryError({error})
    })
  }, [])

  const columns = [
    {
      key: 'sort',
    },
    {
      title: 'Лого',
      dataIndex: 'logo',
      key: 'logo',
      render: (text, record) => {
        return <ImagePreview src={record.logo} width={30} height={30}/>
      }
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'k1',
      key: 'k1',
      render: (text, record) => {
        return <Space>
          <Link to={`/portfolios/${record._id}`}>Открыть</Link>
          <Popconfirm
            title="Удалить портфолио?"
            onConfirm={()=>{portfoliosDel(record._id)}}
            okText="Да"
            cancelText="Нет"
          >
            <Button danger size={"small"}><DeleteOutlined /></Button>
          </Popconfirm>
        </Space>
      }
    },
  ];

  const onDragEnd = ({ active, over }) => {
    console.log({ active, over })
    if (active.id !== over?.id) {
      setItems((previous) => {
        const activeIndex = previous.findIndex((i) => i._id === active.id);
        const overIndex = previous.findIndex((i) => i._id === over?.id);
        const mass = arrayMove(previous, activeIndex, overIndex)
        portfoliosSortSave(mass)
        return mass;
      });
    }
  };

  useEffect(() => {
    portfoliosGet(1)
  }, [portfoliosGet])

  if (!items) return <>Загрузка...</>

  return (
    <>
      <Breadcrumb
        style={{
          margin: '16px 0',
        }}
      >
        <Breadcrumb.Item>Портфолио</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{
          minHeight: 360,
        }}
      >
        <div style={{marginBottom: 20}}><Link to={'/portfolios/add'} >Добавить портфолио</Link></div>

        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={items.map((i) => i._id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                      body: {
                        row: Row,
                      },
                    }}
                   rowKey="_id"
                   dataSource={items}
                   columns={columns}
              pagination={false}
                   // pagination={{
                   //   pageSize: 10,
                   //   showSizeChanger: false,
                   //   total: itemsCount,
                   //   onChange: (page) => {
                   //     setPage(page)
                   //     portfoliosGet(page)
                   //   }
                   // }}
            />
          </SortableContext>
        </DndContext>

      </div>
    </>
  )
}

export default Portfolios;