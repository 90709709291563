import {Button, Modal, Table, Tag} from "antd";
import {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {Spin} from "antd/lib";
import Utils, {apiQuery, apiQueryError} from "../../Utils";
import moment from "moment";
import ImagePreview from "../ImagePreview";
import FileIcon from "../ImagePreview/FileIcon";
import PDFModal from "../PDFModal/PDFModal";

const ActionsHistory = ({userData, mode, style}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [items, setItems] = useState(null);
  const [itemsCount, setItemsCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [modalFileVisible, setModalFileVisible] = useState(false);
  const [modalFileData, setModalFileData] = useState(false);
  const [modalFileId, setModalFileId] = useState(false);

  const modalFile = ({visible, mode, fileData, fileId}) => {
    if (visible) {
      setModalFileData({mode, ...fileData})
      setModalFileId(fileId)
    } else {
      setModalFileData(false)
      setModalFileId(false)
    }
    setModalFileVisible(visible)
  }

  const columns = useMemo(() => [
    {
      title: "Менеджер",
      dataIndex: "email",
      // key: "email",
      render: (text, record, index) => {
        return (
          <a href={`/users/${record?.managerId.id}`} key={index} target={'_blank'} rel="noreferrer">
            <div style={{color: 'rgb(215, 215, 215)'}}>{record?.managerId.profileData.name}
              {' '}
              {record?.managerId.mode === 'live' && <Tag color="green" style={{fontSize: 10, height:18, padding: '2px 4px', lineHeight: '11px'}}>live</Tag>}
              {record?.managerId.mode === 'sandbox' && <Tag color="gold" style={{fontSize: 10, height:18, padding: '2px 4px', lineHeight: '11px'}}>sandbox</Tag>}
            </div>
            <div  style={{fontSize: 12, color: 'rgb(215, 215, 215)'}}>{record?.managerId.email}</div>
            <div style={{fontSize: 10, color: 'rgb(136, 136, 136)'}}>ID: {record?.managerId.id} | _ID: {record?.managerId._id}</div>
          </a>
        );
      },
      // sorter: true,
    },
    {
      title: "Изменения",
      dataIndex: "data",
      key: "data",
      render: (text, record, index) => {
        return <>
          {record.mode !== 'brokerForm' && <>
            <div style={{fontSize: 10, color: '#999'}}>
              Дата: {moment(record.createdAt).format("DD.MM.YYYY HH:mm:ss")}
            </div>
            {record.action && <div>{record.action}</div>}
            <div>
              <div style={{fontSize: 10, color: '#999'}}>Комментарий:</div>
              {record.comment}
            </div>
            {record.fileData && <div>
              <div style={{cursor: "pointer"}} onClick={() => {
                modalFile({visible: true, mode: 'actionFile', fileData: record.fileData, fileId: record._id})
              }}>
                {['png', 'jpg', 'jpeg'].includes(record.fileData.ext) &&
                  <ImagePreview src={`https://${Utils.apiDomain()}/rest_api/admin/actionFile/${record._id}`}/>}
                {!['png', 'jpg', 'jpeg'].includes(record.fileData.ext) && <FileIcon ext={record.fileData.ext} style={{
                  fontSize: 30,
                  border: 0,
                  boxShadow: 'none',
                  width: 'inherit',
                  height: 'inherit',
                  backgroundColor: 'transparent'
                }}/>}
              </div>
            </div>}
          </>}
          {record.mode === 'brokerForm' && <>
            {record.action && <>
              <div style={{fontSize: 10, color: '#999'}}>
                Дата: {moment(record.createdAt).format("DD.MM.YYYY HH:mm:ss")}
              </div><div>{record.action}</div>
            </>}
            {record.data && Object.keys(record.data).map((key, index) => <div key={index}
                                                                              style={{borderBottom: '1px dashed #333'}}>
              <div style={{fontSize: 10, color: '#999'}}>Дата: {moment(record.createdAt).format("DD.MM.YYYY HH:mm:ss")}</div>
              <div>[{key}] {record.data[key].textOld} -> {record.data[key].text}</div>
              <div><div style={{fontSize: 10, color: '#999'}}>Комментарий:</div> {record.data[key].comment}</div>
            </div>)}

          </>}
        </>;
      },
      sorter: false,
    }
  ], []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const traderDataHistory = useCallback((data) => {
    setLoad(true)
    data.userId = userData._id;
    data.mode = mode
    apiQuery("/admin/actionsHistory", {
      method: "POST",
      body: data,
    })
      .then((data) => {
        setItems(data.items);
        setItemsCount(data.itemsCount);
        setLoad(false)
      })
      .catch((error) => {
        apiQueryError({error});
        setLoad(false)
      });
  }, [userData])

  useEffect(() => {
    if (isModalOpen) {
      traderDataHistory({})
    } else {
      setLoad(true)
    }
  }, [isModalOpen, traderDataHistory])

  if (!userData) return;

  return (
    <>
      <Button onClick={showModal} style={style}>
        История изменений
      </Button>
      <Modal title="История изменений"
             open={isModalOpen}
             onCancel={handleCancel}
             footer={''}
      >
        {load && <Spin/>}
        {!load && <Table
            rowKey={'_id'}
            size="small"
            dataSource={items}
            columns={columns}
            onChange={(page, filters, sorter, extra) => {
              traderDataHistory({page, filters, sorter, extra});
            }}
            pagination={{
              pageSize,
              showSizeChanger: true,
              onShowSizeChange: (current, ps) => setPageSize(ps),
              total: itemsCount,
            }}
          />
        }
      </Modal>
      <PDFModal visible={modalFileVisible}
                fileData={modalFileData}
                fileId={modalFileId}
                onClose={() => setModalFileVisible(false)}
      />
    </>
  );
}

export default observer(ActionsHistory);