import {Breadcrumb, DatePicker, Tabs, Input, Space, Select, Tag, Form, Button} from "antd";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {apiQuery, apiQueryError} from "../../Utils";
import * as XLSX from 'xlsx';

import ReportsTabs from './ReportsTabs';

export default function Reports (params) {
  const testInv = params.testInv;

  return (
    <>
      <Breadcrumb style={{margin: "16px 0"}} items={[
        {title: 'Отчеты'}
      ]}/>
      <ReportsTabs testInv={testInv} />
    </>
  );
}
