import React from 'react';
import {Form, DatePicker, Select, Button, Input} from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

const BalancesForm = ({ onFinish }) => {
  return (
    <Form onFinish={(values) => onFinish(values, 'balances')}>
      <Form.Item name="dateRange" label="Период">
        <RangePicker />
      </Form.Item>
      <Form.Item name="clientTypes" label="Типы клиентов">
        <Select mode="multiple" placeholder="Выберите типы клиентов">
          <Option value="individual">Физическое лицо</Option>
          <Option value="corporate">Юридическое лицо</Option>
        </Select>
      </Form.Item>
      <Form.Item name="iins" label="ИИНы">
        <Input placeholder="Введите ИИНы, разделенные запятой" />
      </Form.Item>
      <Form.Item name="assetsType" label="Тип активов">
        <Select placeholder="Выберите тип активов">
          <Option value="papers">Бумаги</Option>
          <Option value="money">Деньги</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Выгрузить отчет</Button>
      </Form.Item>
    </Form>
  );
};

export default BalancesForm;