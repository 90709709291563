import React, {useCallback, useRef} from 'react';
import {DatePicker, Select, Tabs} from 'antd';
import {apiQuery} from "../../Utils";
import OrdersForm from "./Forms/OrdersForm";
import ClientsForm from "./Forms/ClientsForm";
import TransfersForm from "./Forms/TransfersForm";
import FundsForm from "./Forms/FundsForm";
import BalancesForm from "./Forms/BalancesForm";
import DealsForm from "./Forms/DealsForm";
import CommissionsForm from "./Forms/CommissionsForm";

import * as XLSX from 'xlsx';
import moment from "moment/moment";
import dayjs from "dayjs";

const {RangePicker} = DatePicker;
const {TabPane} = Tabs;
const {Option} = Select;

const ReportsTabs = ({testInv}) => {

  const timeoutRef = useRef(null);

  const onFinish = useCallback((values, reportType) => {
    console.log('Received values of form: ', values);
    console.log('Report Type: ', reportType);
    // В зависимости от типа отчета (reportType), вызываем разные функции для отправки данных
    switch (reportType) {
      case 'clients':
        fetchAndDownloadReport(values, '/admin/reports/users', 'ClientsReport');
        break;
      case 'transfers':
        fetchAndDownloadReport(values, '/admin/getAisTransfers', 'TransferReport');
        break;
      case 'orders':
        fetchAndDownloadReport(values, '/admin/getOrders', 'OrderReport');
        break;
      case 'funds':
        fetchAndDownloadReport(values, '/admin/getTransactions', 'TransactionsReport');
        break;
      default:
        console.log('Unknown report type');
    }
  }, [testInv]);

  const dataMappers = {
    '/admin/reports/users': (response) => {
      const items = response.items


      return items.map(item => ({
        'ФИО': item?.addData?.fio,
        'ИИН/БИН': item?.addData?.iin,
        'Дата создания': moment(item?.createdAt).format('DD.MM.YYYY HH:mm:ss'),
        'Статус': Object.keys(item?.role)
          .filter(key => item?.role[key])
          .join(', '),
        'Номер и дата договора': item?.addData?.docNumber,
        'Телефон': '',
      }))
    },
    '/admin/getOrders': (response) => {
      response.map(item => ({
        'Дата и время создания ордера': moment(item?.data?.created_at).format('DD.MM.YYYY HH:mm:ss'),
        'Дата и время подтверждения ордера': moment(item?.data?.submitted_at).format('DD.MM.YYYY HH:mm:ss'),
        'Дата и время исполнения ордера': moment(item?.data?.filled_at).format('DD.MM.YYYY HH:mm:ss'),
        'Клиент (ФИО/Наименование)': item?.user?.profileData?.name,
        'ИИН/БИН': item?.user?.aisData?.aIIN,
        'Тип ордера (Рыночный/лимитный)': item?.data?.type === 'limit' ? 'Лимитный' : 'Рыночный',
        'Номер приказа': item?.data.id,
        'Направление (Покупка/Продажа)': item?.side === 'buy' ? 'Покупка' : 'Продажа',
        'Тикер': item?.data?.symbol,
        'ИСИН': item?.addData?.isin || '',
        'Объем': item?.data?.type === "market"
          ? (item?.data?.notional ?
              Number(Number(item.data.notional).toFixed(2))
              : (item.data.qty && item.addData.snapshotPrice
                  ? Number((item.addData.snapshotPrice * item.data.qty).toFixed(2))
                  : 0
              )
          ) : item.data.type === "limit"
            ? Number((item.data.limit_price * item.data.qty).toFixed(2)) : 0,
        'Фактический объем': Number(item?.data?.filled_qty * item?.data?.filled_avg_price).toFixed(2),
        'Количество': item.data.type === "market"
          ? (item.data?.filled_qty || (item.data?.qty || '-'))
          : item.data.type === "limit"
            ? (item.data?.filled_qty || (item.data?.qty || '-')) : '-',
        'Фактическое количество': item.data?.filled_qty || '-',
        'Статус': item.data.status,
        'Статус АИС': item?.aisData?.error
          ? 'Ошибка при создании ордера'
          : item?.aisData?.ORDER_ID
            ? 'Ордер создан'
            : 'Статус не определен',
      }))
    },
    '/admin/getTransactions': (response) => {
      const items = response.items

      return items.map(item => {
        const backComments = item?.backComment && item?.backComment.length > 0
          ? item?.backComment.map(el => `${el.name}, ${el.time}, ${el.text}`).join('; ')
          : '';

        const complianceComment = item?.complianceComment && item?.complianceComment.length > 0
          ? item?.complianceComment.map(el => `${el.name}, ${el.time}, ${el.text}`).join('; ')
          : '';

        const cfo = item?.cfo && item?.cfo.length > 0
          ? item?.cfo.map(el => `${el.name}, ${el.time}, ${el.text}`).join('; ')
          : '';

        return {
          'Дата': moment(item?.createdAt).format('DD.MM.YYYY HH:mm:ss'),
          'Сумма': item?.bankData?.['amount-sender'],
          'Уникальный Номер': item?.bankData?.id,
          'Статус': item.status === 'iinNotFound' ? 'ИИН не найден' : (
            item.status === 'sentToAis' ? 'Отправлен в АИС' : (
              item.status === 'rejected' ? 'Отклонен' : (
                item.status === 'blocked' ? 'Заблокирован' : (
                  item.status === 'docRequested' ? 'Запрошен документ' : (
                    item.status === 'refundApproved' ? 'Возврат одобрен' : (
                      item.status === 'refundSent' ? 'Возврат отправлен' : 'неизвестно'
                    )
                  )
                )
              )
            )
          ),
          'Автоматическое/ручное зачисление': item.type === 'auto' ? 'Автоматическое' : (
            item.type === 'hand' ? 'Ручное' : 'неизвестно'
          ),
          'Комментарии от Бэк-Офиса': backComments,
          'Аудиторский след (кто и когда внес изменения)': item?.backOfficer,
          'Аудиторский след (кто и когда подтвердил изменения)': cfo,
          'Аудиторский след (кто и когда подтвердил возврат со стороны комплаенс)': complianceComment,
          'Причина возврата': item?.refundReason || ''
        }
      })
    },
    'admin/getAisTransfers': (response) => {
      return response.map((item) => {
        return {
          'Дата': moment(item?.createdAt).format('DD.MM.YYYY HH:mm:ss'),
          'Сумма': item.aisData?.vAmount,
          'ID': item?.aisData?.MTO_ID,
          'Статус': item?.status === 'wait' ? 'Ожидает' : (item?.status === 'done' ? 'Выполнен' : 'Ошибка'),
          'Авто/Ручное зачисление': '',
          'Коментарий от Бэк-Офиса': '',
          'Аудиторский след (кто и когда внес изменения)': '',
          'Аудиторский след (кто и когда подтвердил изменения': '',
          'Аудиторский след (кто и когда подтвердил возврат со стороны комплаенс)': '',
          'Причина возврата': ''
        }
      })
    }
  };

  function addDataToWorksheet(workbook, dataToExport, headers) {
    // Преобразование данных в формат, понятный для XLSX
    const worksheetData = [headers, ...dataToExport.map(item => headers.map(header => item[header]))];

    // Создание листа из данных
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Настройка стилей для заголовков
    for (let i = 0; i < headers.length; i++) {
      const cellRef = XLSX.utils.encode_cell({r: 0, c: i}); // Координата ячейки для заголовка
      if (!worksheet[cellRef]) worksheet[cellRef] = {};
      worksheet[cellRef].s = {
        font: {
          bold: true,
          sz: 14
        },
        alignment: {
          horizontal: "center"
        }
      };
    }

    // Автоширина колонок в зависимости от данных
    worksheet['!cols'] = worksheetData[0].map((_, colIndex) => {
      const maxLength = Math.max(...worksheetData.map(row => (row[colIndex] ? row[colIndex].toString().length : 0)));
      return {wch: maxLength + 2}; // Добавляем немного дополнительного места
    });

    // Добавление листа в рабочую книгу
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
  }

  function getHeadersForReport(url) {
    const headersMap = {
      '/admin/reports/users': ['ФИО', 'ИИН/БИН', 'Дата создания', 'Статус', 'Номер и дата договора', 'Телефон'],
      '/admin/getOrders': [
        'Дата и время создания ордера',
        'Дата и время подтверждения ордера',
        'Дата и время исполнения ордера',
        'Клиент (ФИО/Наименование)',
        'ИИН/БИН',
        'Тип ордера (Рыночный/лимитный)',
        'Номер приказа',
        'Направление (Покупка/Продажа)',
        'Тикер',
        'ИСИН',
        'Объем',
        'Фактический объем',
        'Количество',
        'Фактическое количество',
        'Статус',
        'Статус АИС'
      ],
      '/admin/getTransactions': [
        'Дата',
        'Сумма',
        'Уникальный ID',
        'Статус',
        'Автоматическое/ручное зачисление',
        'Комментарии от Бэк-Офиса',
        'Аудиторский след (кто и когда внес изменения)',
        'Аудиторский след (кто и когда подтвердил изменения)',
        'Аудиторский след (кто и когда подтвердил возврат со стороны комплаенс)',
        'Причина возврата'
      ],
      'admin/getAisTransfers': [
        'Дата',
        'Сумма',
        'ID',
        'Статус',
        'Авто/Ручное зачисление',
        'Коментарий от Бэк-Офиса',
        'Аудиторский след (кто и когда внес изменения)',
        'Аудиторский след (кто и когда подтвердил изменения',
        'Аудиторский след (кто и когда подтвердил возврат со стороны комплаенс)',
        'Причина возврата'
      ]
    }
    return headersMap[url] || [];
  }

  const fetchAndDownloadReport = useCallback((formData, url, fileName) => {
    console.log("form data: ", url)
    const data = {}
    if (formData?.dateRange) {
      data.startDate = formData?.dateRange[0].format('YYYY-MM-DD')
      data.endDate = formData?.dateRange[1].format('YYYY-MM-DD')
    }

    if (formData.filterFIO) {
      data.fio = formData.filterFIO
    }

    if (url === '/admin/getTransactions') {
      data.tab = 'all'
    }

    if (url === '/admin/getAisTransfers') {
      data.route = 'brokerage'
    }

    data.mode = testInv ? 'live' : 'sandbox';
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      console.log("Sending data to server:", data);

      apiQuery(url, {
        method: "POST",
        body: {data},
      })
        .then((response) => {
          console.log(response)
          const mapper = dataMappers[url];
          if (!mapper) {
            console.error("No mapper defined for URL:", url);
            return;
          }

          const dataToExport = mapper(response);
          const workbook = XLSX.utils.book_new();
          const headers = getHeadersForReport(url);
          addDataToWorksheet(workbook, dataToExport, headers);
          XLSX.writeFile(workbook, `${fileName}.xlsx`);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }, 500);
  }, []);

  return (

    <Tabs defaultActiveKey="1" tabPosition='left'>
      <TabPane tab="Список клиентов/пользователей" key="1">
        <ClientsForm onFinish={(values) => onFinish(values, 'clients')}/>
      </TabPane>

      <TabPane tab="Список переводов со статусами" key="2">
        <TransfersForm onFinish={(values) => onFinish(values, 'transfers')}/>
      </TabPane>

      <TabPane tab="Список поступлений и выводов средств" key="3">
        <FundsForm onFinish={(values) => onFinish(values, 'funds')}/>
      </TabPane>

      <TabPane tab="Список ордеров со статусами" key="4">
        <OrdersForm onFinish={(values) => onFinish(values, 'orders')}/>
      </TabPane>

      <TabPane tab="Список сделок со статусами" key="5">
        <DealsForm onFinish={(values) => onFinish(values, 'deals')}/>
      </TabPane>

      <TabPane tab="Список всех комиссий" key="6">
        <CommissionsForm onFinish={(values) => onFinish(values, 'commissions')}/>
      </TabPane>

      <TabPane tab="Сверка итоговых остатков" key="7">
        <BalancesForm onFinish={(values) => onFinish(values, 'balance')}/>
      </TabPane>

    </Tabs>

  );
};

export default ReportsTabs;