import React, {useState} from 'react';
import { Form, DatePicker, Select, Button } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

const statuses = [
  {value:'iinNotFound', label:'ИИН не найден'},
  {value:'sentToAis', label:'Отправлен в АИС'},
  {value:'rejected', label:'Отклонен'},
  {value:'blocked', label:'Заблокирован'},
  {value:'docRequested', label:'Запрошен документ'},
  {value:'refundApproved', label:'Возврат одобрен'},
  {value:'refundSent', label:'Возврат отправлен'},
]

const FundsForm = ({ onFinish }) => {

  const [dateRange, setFilterDates] = useState([false,false]);

  return (
    <Form onFinish={(values) => onFinish(values, 'funds')}>
      <Form.Item name="dateRange" label="Период">
        <RangePicker
          onChange={(e)=>{setFilterDates(e)}}
          value={dateRange}
        />
      </Form.Item>
      <Form.Item name="status" label="Статус">
        <Select
          placeholder="Выберите статус"
          options={statuses}
        >
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Выгрузить отчет</Button>
      </Form.Item>
    </Form>
  );
};

export default FundsForm;