import {
	Breadcrumb,
	Button,
	message,
	Modal,
	Space,
	Table,
	Tooltip,
	Typography,
	Tabs,
	Drawer,
	Form,
	Input,
	Select,
	DatePicker,
	Badge, Col, Row
} from "antd";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Utils, {apiQuery, apiQueryError} from "../../Utils";


import moment from "moment";
import {faCircleQuestion, faFolder, faRepeat} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import userStore from "../Auth/userStore";
import {Link} from "react-router-dom";
import ConfirmPass2 from "../ConfirmPass/ConfirmPass2";
import PDFModal from "../PDFModal/PDFModal";
const { Text } = Typography;
const {TabPane} = Tabs;


const TransfersV2 = (params) => {

	const testInv = Utils.apiDomain()==='app2.geekoinvest.kz'
	const [items, setItems] = useState({all: [], wait: [], inProgress: [], done: [], canceled: [], rejected: [], blocked: [], sentJNLC: []});
	const [itemsCount, setItemsCount] = useState({all: 0, wait: 0, inProgress: 0, done: 0, canceled: 0, rejected: 0, blocked: 0, sentJNLC: 0});
	const [itemsPage, setItemsPage] = useState({all: 1, wait: 1, inProgress: 1, done: 1, canceled: 1, rejected: 1, blocked: 1, sentJNLC: 1});
	const [pageSize, setPageSize] = useState(5);
	const [activeTab, setActiveTab] = useState('all');
	const [form] = Form.useForm();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalFileVisible, setModalFileVisible] = useState(false);
	const [modalFileUrl, setModalFileUrl] = useState('');
	const [modalFileData, setModalFileData] = useState(false);
	const [modalFileId, setModalFileId] = useState(false);
	const [load, setLoad] = useState(null);
	const [selectedItems, setSelectedItems] = useState([])
	const [pullItems, setPullItems] = useState([])
	const [pullData, setPullData] = useState(null)
	const timeOutId = useRef(null);


	const showModal = (data) => {

		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		form.resetFields([])
	};
	const onTabsChange = (tab) => {

		setActiveTab(tab)
		aisDataGet({tab, page: {
				current: 1,
				pageSize
			}})
	}

	const aisDataGet = useCallback((data = {}) => {

		console.log('aisDataGet')
		const tab = data.tab;
		// data.filterMode = filterMode;
		// data.filterValue = filterValue;
		data.mode = testInv ?'sandbox' : 'live';
		// data.filterTab = filterTab.current[tab]

		console.log(data)
		apiQuery("/admin/getTransfers", {
			method: "POST",
			body: data,
		})
			.then((data) => {

				console.log(data)

				setItems((v) => {
					const d = {...v}
					d[tab] = data.items;
					return d
				});
				setItemsCount((v) => {
					const d = {...v}
					d[tab] = data.itemsCount;
					console.log(d)
					return d
				});
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}, [])

	useEffect(() => {
		aisDataGet({tab: 'all'});
	}, [aisDataGet, testInv]);


	const addComment = async(data)=>{
		if(!userStore.userData.role.cfo && !userStore.userData.role.backOfficer && !!userStore.userData.role.compliance){
			return false
		}

		apiQuery("/admin/saveTransferComment", {
			method: "POST",
			body: data,
		})
			.then((data) => {

				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})

			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const getPull = async (pullId)=>{
		apiQuery("/admin/getPull", {
			method: "POST",
			body: {pullId},
		})
			.then((data) => {

				setPullItems(data.transfers)
				setPullData(data.pull)
				console.log(data.pull)

				showModal()


			})
			.catch((error) => {
				apiQueryError({error});
			});
	}
	const getTransfer = async (id, index)=>{
		apiQuery("/admin/getTransferAndAddIntoPull", {
			method: "POST",
			body: {id, pullId:pullData._id},
		})
			.then((data) => {

				if(data.error){
					return message.error(data.error.msg)
				}


				message.success(data.success.msg)

				// setPullItems(data.transfers)
				getPull(pullData._id)


				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
			})
			.catch((error) => {
				apiQueryError({error});
			});
	}

	const editCommissions = async(key, value, id)=>{
		clearTimeout(timeOutId.current);
		const newItems = []
		for(const item of pullItems){
			if(id===item._id){

				console.log(item)
				item.aisData[key] = value
				console.log(item)
			}

			newItems.push(item)


		}

		setPullItems(newItems)

		timeOutId.current = setTimeout(() => {
			apiQuery("/admin/editTransferCommission", {
				method: "POST",
				body: {key, value, id},
			})
				.then((data) => {

					if(data.error){
						return message.error(data.error.msg)
					}


					message.success(data.success.msg)



					aisDataGet({tab:activeTab, page: {
							current: itemsPage[activeTab],
							pageSize
						}})
				})
				.catch((error) => {
					apiQueryError({error});
				});
		}, 1000)




	}

	const deleteTransferFromPull = async(id, index)=>{


		apiQuery("/admin/deleteTransferFromPull", {
			method: "POST",
			body: {id, pullId:pullData._id},
		})
			.then((data) => {

				if(data.error){
					return message.error(data.error.msg)
				}

				message.success(data.success.msg)

				// setPullItems(data.transfers)
				getPull(pullData._id)

				// const newItems = pullItems.filter((el,i)=>index!==i)
				//
				// // delete newItems[index]
				//
				// setPullItems(newItems)

				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})

			})
			.catch((error) => {
				apiQueryError({error});
			});


	}
	const modalFile = ({visible, fileData, fileId, fileUrl}) => {
		console.log(fileUrl)
		if (visible) {
			setModalFileData({...fileData})
			setModalFileId(fileId)
			setModalFileUrl(fileUrl)
		} else {
			setModalFileData(false)
			setModalFileId(false)
		}
		setModalFileVisible(visible)
	}

	const columns = [
		{
			title: "Дата поручения",
			dataIndex: "date",
			// key: "email",
			render: (text, record, index) => {
				return (
					<span>{moment(record.createdAt).format('DD.MM.YYYY')}</span>
				);
			},
			// sorter: true,
		},
		{
			title: "Время клиента с часовым поясом",
			dataIndex: "time",
			key: "time",
			render: (text, record, index) => {
				return <span>{record?.data?.userTime ? record.data.userTime+' по GMT'+record.data.timezone : ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Дата зачисления получателем",
			dataIndex: "time",
			key: "time",
			render: (text, record, index) => {
				return <span>{moment(record.doneAt).format("DD.MM.YYYY")}</span>;
			},
			// sorter: true,
		},
		{
			title: "Время зачисления получателем",
			dataIndex: "time",
			key: "time",
			render: (text, record, index) => {
				return <span>{moment(record.doneAt).format("HH:mm:ss")}</span>;
			},
			// sorter: true,
		},
		{
			title: "ID",
			dataIndex: "ID",
			key: "bankData.id",
			render: (text, record, index) => {
				return  <a onClick={()=>{modalFile({visible: true, fileData: 'asds.pdf', fileId: record._id, fileUrl: record?.data?.fileName})}}>{record.id}</a>;
			},
			// sorter: true,
		},
		{
			title: "Pull_id",
			dataIndex: "name-sender",
			key: "bankData.nameSender",
			render: (text, record, index) => {
				return <span>{record?.pull?.pullId ? <a onClick={()=>{getPull(record?.pull?._id)}}>{record?.pull?.pullId}</a> :''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Pull_status",
			dataIndex: "name-sender",
			key: "bankData.nameSender",
			render: (text, record, index) => {
				return <span>{record?.pull?.status==='wait' ? 'Ожидает' : (
					record?.pull?.status==='sent' ? 'Отправлен' : (
						record?.pull?.status==='done' ? 'Поступил' :  'неизвестно'
					)
				)}</span>;
			},
			// sorter: true,
		},
		{
			title: "Клиент (ФИО/Наименование)",
			dataIndex: "name-sender",
			key: "bankData.nameSender",
			render: (text, record, index) => {
				return <span>{record.user?.addData?.fio || ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "ИИН/БИН",
			dataIndex: "rnn-sender",
			key: "bankData.rnn-sender",
			render: (text, record, index) => {
				return <span>{record.user?.addData?.iin||''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Сумма",
			dataIndex: "amount-sender",
			key: "bankData.amount-sender",
			render: (text, record, index) => {
				return <span>{record?.aisData?.vAmount || '-'}</span>;
			},
			// sorter: true,
		},
		{
			title: "Прочие комиссии банка",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				return <span></span>;
			},
			// sorter: true,
		},
		{
			title: "Комиссия за перевод",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				return <span>{record?.aisData?.vBankComission || '-'}</span>;
			},
			// sorter: true,
		},
		{
			title: "Комиссия в тенге",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				return <span>{record?.aisData?.vBankComissionKZT || '-'}</span>;
			},
			// sorter: true,
		},
		{
			title: "Направление",
			dataIndex: "route",
			key: "route",
			render: (text, record, index) => {
				return <span>{record.route==='trading' ? 'Б -> Т' : (
					record.route==='brokerage' ? 'Т -> Б' :  'неизвестно'
				)}</span>;
			},
			// sorter: true,
		},

		{
			title: "Статус",
			dataIndex: "status",
			key: "status",
			render: (text, record, index) => {
				return <span>{record.status==='wait' ? 'Принят (новый)' : (
					record.status==='inProgress' ? 'В обработке' : (
						record.status==='done' ? 'Исполнен' : (
							record.status==='sentJNLC' ? 'Отправлен JNLC' : (
								record.status==='blocked' ? 'Заблокирован' : (
									record.status==='canceled' ? 'Отменен' : (
										record.status==='rejected' ? 'Отклонен' : (
											record.status==='receiveJNLC' ? 'Отправлен JNLC' : 'неизвестно'
										)
									)
								)
							)
						)
					)
				)}</span>;
			},
			// sorter: true,
		},
		{
			title: "Зачисление",
			dataIndex: "type",
			key: "type",
			render: (text, record, index) => {
				return <span>{record.type==='auto' ? 'Автоматическое' : (
					record.type==='hand' ? 'Ручное' : 'неизвестно'
				)}</span>;
			},
			// sorter: true,
		},
		{
			title: "Комментарии от Бэк-Офиса",
			dataIndex: "backComment",
			// key: "email",
			render: (text, record, index) => {
				return <>
					{!record.backComment || record.backComment.length===0 ? <></> :
						record.backComment.map(el=>
							<div>
								<div>{el.name}</div>
								<div>{el.time}</div>
								<div>{el.text}</div>
								{el.file ? <a href={el.file} target={'_blank'}>файл</a>
									:<></>}
							</div>
						)
					}
				</>

			},
			// sorter: true,
		},
		{
			title: "Комментарии от Compliance",
			dataIndex: "complianceComment",
			// key: "email",
			render: (text, record, index) => {
				return <>
					{!record.complianceComment || record.backComment.length===0 ? <></> :
						record.complianceComment.map(el=>
							<div>
								<div>{el.name}</div>
								<div>{el.time}</div>
								<div>{el.text}</div>
								{el.file ? <a href={el.file} target={'_blank'}>файл</a>
									:<></>}
							</div>
						)
					}
				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Исполнитель)",
			dataIndex: "backOfficer",
			// key: "email",
			render: (text, record, index) => {
				if(!record?.backOfficer) {
					return <></>
				}

				return <>
					{record?.backOfficer.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Подтверждающий)",
			dataIndex: "cfo",
			// key: "email",
			render: (text, record, index) => {
				if(!record?.cfo) {
					return <></>
				}

				return <>
					{record?.cfo.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

				</>
			},
			// sorter: true,
		},
		{
			title: "Аудиторский след  (Compliance))",
			dataIndex: "compliance",
			// key: "email",
			render: (text, record, index) => {

				if(!record?.compliance) {
					return <></>
				}

				return <>
					{record?.compliance.map(el=>{
						return <>
							<div>{el?.name || ''}</div>
							<div>{el?.time || ''}</div>
							<div>{el?.action || ''}</div>
						</>
					})}

				</>
					;
			},
			// sorter: true,
		},
		{
			title: "Причина Возврата",
			dataIndex: "refundReason",
			// key: "email",
			render: (text, record, index) => {
				return <span>{record.refundReason || ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Ответ АИС",
			dataIndex: "aisResponse",
			// key: "email",
			render: (text, record, index) => {
				return (
					<>
						<div>{record?.aisResponse?.time || ''}</div>
						<div>{record?.aisResponse?.msg || ''}</div>
					</>
				);
			},
			// sorter: true,
		},

		{
			title: "Действия",
			dataIndex: "actions",
			// key: "email",
			render: (text, record, index) => {

				if(record.status==='inProgress'){
					return <></>
				}
				if(record.status==='wait'){

					if(userStore.userData.role.backOfficer) {
						if (!record?.backOfficerLastAction?.action) {
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>

							</>
						}

						if(record?.backOfficerLastAction?.action==='reject' || record?.backOfficerLastAction?.action==='resolve'){
							return <div>
								<ConfirmPass2 btnText={'Комментировать'}
								              btnType={'default'}
								              fieldCommentVisible={true}
								              fieldFileVisible={true}
									// danger={!userData?.blocking}
									// loading={userBlockLoad}
									          onFinish={(data) => {
										          addComment({_id:record._id,...data})
									          }}/>
							</div>
						}
						if(record?.backOfficerLastAction?.action==='editIIN' && record?.cfoLastAction?.action==='editIIN'){
							return<>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>

							</>
						}

						return <div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>
					}


					if(userStore.userData.role.cfo) {
						if (!record?.cfoLastAction?.action || (record?.cfoLastAction?.action && record?.cfoLastAction?.action!==record?.backOfficerLastAction?.action)) {

							if(record?.backOfficerLastAction?.action === 'reject') {

								return <>
									<div>
										<ConfirmPass2 btnText={'Комментировать'}
										              btnType={'default'}
										              fieldCommentVisible={true}
										              fieldFileVisible={true}
											// danger={!userData?.blocking}
											// loading={userBlockLoad}
											          onFinish={(data) => {
												          addComment({_id:record._id,...data})
											          }}/>
									</div>

								</>
							}

							if(record?.backOfficerLastAction?.action === 'resolve') {

								return <>
									<div>
										<ConfirmPass2 btnText={'Комментировать'}
										              btnType={'default'}
										              fieldCommentVisible={true}
										              fieldFileVisible={true}
											// danger={!userData?.blocking}
											// loading={userBlockLoad}
											          onFinish={(data) => {
												          addComment({_id:record._id,...data})
											          }}/>
									</div>

								</>
							}
							if(record?.backOfficerLastAction?.action === 'editIIN') {

								return <>
									<div>
										<ConfirmPass2 btnText={'Комментировать'}
										              btnType={'default'}
										              fieldCommentVisible={true}
										              fieldFileVisible={true}
											// danger={!userData?.blocking}
											// loading={userBlockLoad}
											          onFinish={(data) => {
												          addComment({_id:record._id,...data})
											          }}/>
									</div>

								</>
							}
						}

						return <div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>


					}

					return <div>
						<ConfirmPass2 btnText={'Комментировать'}
						              btnType={'default'}
						              fieldCommentVisible={true}
						              fieldFileVisible={true}
							// danger={!userData?.blocking}
							// loading={userBlockLoad}
							          onFinish={(data) => {
								          addComment({_id:record._id,...data})
							          }}/>
					</div>

				}


				if(record.status==='done'){
					if(userStore.userData.role.compliance) {
						if (!record?.complianceLastAction?.action) {
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>


							</>
						}
					}
					return <>
						<div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>

					</>
				}

				if(record.status==='canceled'){

					if(userStore.userData.role.backOfficer || userStore.userData.role.cfo){

						if(record?.backOfficerLastAction?.action!=='refund' && record?.cfoLastAction?.action!=='refund'){
							return <>
								<div>
									<ConfirmPass2 btnText={'Комментировать'}
									              btnType={'default'}
									              fieldCommentVisible={true}
									              fieldFileVisible={true}
										// danger={!userData?.blocking}
										// loading={userBlockLoad}
										          onFinish={(data) => {
											          addComment({_id:record._id,...data})
										          }}/>
								</div>


							</>
						}


					}
					return <>
						<div>
							<ConfirmPass2 btnText={'Комментировать'}
							              btnType={'default'}
							              fieldCommentVisible={true}
							              fieldFileVisible={true}
								// danger={!userData?.blocking}
								// loading={userBlockLoad}
								          onFinish={(data) => {
									          addComment({_id:record._id,...data})
								          }}/>
						</div>

					</>
				}

				return <></>
			},
			// sorter: true,
		},

	];
	const columnsForEditPull = [
		{
			title: "Дата поручения",
			dataIndex: "date",
			// key: "email",
			render: (text, record, index) => {
				return (
					<span>{record.createdAt ? moment(record.createdAt).format('DD.MM.YYYY') : ''}</span>
				);
			},
			// sorter: true,
		},
		{
			title: "Время клиента с часовым поясом",
			dataIndex: "time",
			key: "time",
			render: (text, record, index) => {
				return <span>{record?.data?.userTime ? record.data.userTime+' по GMT'+record.data.timezone : ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "ID",
			dataIndex: "ID",
			key: "bankData.id",
			render: (text, record, index) => {
				return <div>
					{
						!record.id ? <input type="number" onChange={(e)=>{
								clearTimeout(timeOutId.current);

							timeOutId.current = setTimeout(() => {
								getTransfer(e.target.value, index)
							}, 1000)

							}}/> :
						<Link onClick={()=>{}}>{record.id}</Link>
					}
				</div>;
			},
			// sorter: true,
		},
		{
			title: "Клиент (ФИО/Наименование)",
			dataIndex: "name-sender",
			key: "bankData.nameSender",
			render: (text, record, index) => {
				return <span>{record.user?.addData?.fio || ''}</span>;
			},
			// sorter: true,
		},
		{
			title: "ИИН/БИН",
			dataIndex: "rnn-sender",
			key: "bankData.rnn-sender",
			render: (text, record, index) => {
				return <span>{record.user?.addData?.iin||''}</span>;
			},
			// sorter: true,
		},
		{
			title: "Сумма",
			dataIndex: "amount-sender",
			key: "bankData.amount-sender",
			render: (text, record, index) => {
				return <span>{record?.aisData?.vAmount || '-'}</span>;
			},
			// sorter: true,
		},
		{
			title: "Прочие комиссии банка",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				return <div>
					{ record.status==='wait' ?
						<input type="number" disabled={!record.id} min={0}
						       value={record?.aisData?.otherCommissions || 0} onChange={(e) => {
							editCommissions('otherCommissions', e.target.value, record._id)
						}}/>
						:
						<p>{record?.aisData?.otherCommissions || 0}</p>
					}
				</div>
			},
			// sorter: true,
		},
		{
			title: "Комиссия за перевод",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				return <div>
					{ record.status==='wait' ?
						<input type="number" disabled={!record.id} min={0} value={record?.aisData?.vBankComission || 0}
						       onChange={(e) => {
							       editCommissions('vBankComission', e.target.value, record._id)
						       }}/>
						:
						<p>{record?.aisData?.vBankComission || 0}</p>
					}
				</div>
			},
			// sorter: true,
		},
		{
			title: "Комиссия в тенге",
			dataIndex: "commission",
			key: "commission",
			render: (text, record, index) => {
				// return <span>{record?.aisData?.vBankComissionKZT || '-'}</span>;
				return <div>
					{ record.status==='wait' ?
						<input type="number" disabled={!record.id} min={0}
						       value={record?.aisData?.vBankComissionKZT || 0} onChange={(e) => {
							editCommissions('vBankComissionKZT', e.target.value, record._id)
						}}/>
						:
						<p>{record?.aisData?.vBankComissionKZT || 0}</p>
					}
				</div>
			},
			// sorter: true,
		},
		{
			title: "Направление",
			dataIndex: "route",
			key: "route",
			render: (text, record, index) => {
				return <span>{!record.route ? '' :(record.route==='trading' ? 'Б -> Т' : (
					record.route==='brokerage' ? 'Т -> Б' :  'неизвестно'
				))}</span>;
			},
			// sorter: true,
		},
		{
			title: "Действия",
			dataIndex: "actions",
			key: "actions",
			render: (text, record, index) => {
				return <div>
					{pullItems.length>1 && record.status==='wait' ? <Button onClick={()=>{deleteTransferFromPull(record._id, index)}}>X</Button> : <></>}
				</div>
			},
			// sorter: true,
		},
	];

	if (!items) return <>Загрузка...</>;

	const checkComissions = () => {

		// const items = route==='trading' ? tradingItems : brokerageItems
		const items = pullItems.map(el=>el._id)

		console.log(items)
		// return false

		if(items.length===0){
			return message.error('Выберите записи!')
		}

		return apiQuery("/admin/checkTransferCommissions", {
			method: 'POST',
			body: {testInv, items, route:pullData.route}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)

				message.success(data.success.msg)

				// setPullItems(data.transfers)
				getPull(pullData._id)

				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
			})
			.catch((error) => {
				aisDataGet()
				apiQueryError({error});
			})
	};
	const createPull = () => {

		// const items = route==='trading' ? tradingItems : brokerageItems
		const items = selectedItems

		console.log(items)
		// return false

		if(items.length===0){
			return message.error('Выберите записи!')
		}

		return apiQuery("/admin/createPull", {
			method: 'POST',
			body: {testInv, items}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)
				setSelectedItems([])

				setPullItems(data.transfers)
				setPullData(data.pull)
				showModal()

				// message.success(data.success.msg)

				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})

			})
			.catch((error) => {
				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				apiQueryError({error});
			})
	};


	const addTransferRowToPullTable = async()=>{

		console.log('addTransferRowToPullTable')
		console.log(pullItems)

		setPullItems([...pullItems, {}])

	}



	const repeatMTO = (item)=>{
		return apiQuery("/admin/repeatTransferMTO", {
			method: 'POST',
			body: {testInv, items:[item]}
		})
			.then((data) => {

				console.log(data, 'data')

				if (data?.error) return message.error(data.error.msg)

				message.success(data.success.msg)
				aisDataGet()
			})
			.catch((error) => {
				aisDataGet()
				apiQueryError({error});
			})
	}

	const createMTOs = () => {

		if(!pullData.readyToSend){
			return message.error('Требуется расчет комиссий!')
		}

		const items = pullItems.map(el=>el._id)

		if(items.length===0){
			return message.error('Выберите записи!')
		}

		message.success('Начинаю создание МТО. Ожидайте результата!')

		return apiQuery("/admin/createTransferMTOSV2", {
			method: 'POST',
			body: {testInv, items, pullId:pullData._id}
		})
			.then((data) => {

				console.log(data, 'data')
				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				if (data?.error) return message.error(data.error.msg)

				message.success(data.success.msg)

				if(pullData.route==='brokerage' && pullData.mode==='live'){
					createJNLC()
				}


			})
			.catch((error) => {
				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				apiQueryError({error});
			})
	};


	const createJNLC = () => {

		// if(!pullData.readyToSend){
		// 	return message.error('Требуется расчет комиссий!')
		// }

		// const items = pullItems.map(el=>el._id)

		// if(items.length===0){
		// 	return message.error('Выберите записи!')
		// }
		message.success('Начинаю отсылку JNLC')
		return apiQuery("/admin/createJNLC", {
			method: 'POST',
			body: {testInv,pullId:pullData._id, route:pullData.route}
		})
			.then((data) => {

				console.log(data, 'data')
				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				if (data?.error) return message.error(data.error.msg)

				message.success(data.success.msg)


			})
			.catch((error) => {
				aisDataGet({tab:activeTab, page: {
						current: itemsPage[activeTab],
						pageSize
					}})
				apiQueryError({error});
			})
	};

	// const createMTOs = () => {
	// 	// setIsModalVisible(true);
	// };

	// useEffect(() => {
	// 	aisDataGet()
	// }, [aisDataGet, testInv, route])
	return (
		<>
			<Breadcrumb style={{margin: '16px 0'}}>
				<Breadcrumb.Item>Переводы V2</Breadcrumb.Item>
			</Breadcrumb>
			<div
				style={{
					minHeight: 360,
				}}
			>
				{/*<Button type="primary"*/}
				{/*        style={{marginBottom: 15}}*/}
				{/*        onClick={checkComissions}*/}
				{/*>*/}
				{/*	Расчитать комиссии*/}
				{/*</Button>*/}
				{/*<Button type="primary"*/}
				{/*        style={{marginBottom: 15}}*/}
				{/*        onClick={createMTOs}*/}
				{/*>*/}
				{/*	Создать приказы*/}
				{/*</Button>*/}

				{/*<Button type="primary" style={{marginBottom: 15}} onClick={showDrawer} >*/}
				{/*	Фильтр*/}
				{/*</Button>*/}
				<Tabs defaultActiveKey="all" onChange={onTabsChange} items={[
					{
						key     : 'all',
						label   : <>Все <Badge count={itemsCount.all} style={{
							backgroundColor: '#faad14',
						}}/></>,
						children: <div className="site-layout-background" style={{minHeight: 360}}>

							<Table
								rowKey={'_id'}
								size="small"
								dataSource={items.all}
								columns={columns}
								onChange={(page, filters, sorter, extra) => {
									setItemsPage(v => {
										const d = {...v}
										d.all = page.current;
										console.log(d)
										return d
									})
									aisDataGet({
										page,
										filters,
										sorter,
										extra,
										tab: 'all'
									});
								}}
								pagination={{
									pageSize,
									showSizeChanger : true,
									onShowSizeChange: (current, ps) => setPageSize(ps),
									total           : itemsCount.all,
									current           : itemsPage.all,
								}}
							/>
						</div>
					},
					{
						key     : 'wait',
						label   : <>Принят (новый) <Badge count={itemsCount.wait} style={{
							backgroundColor: '#faad14',
						}}/></>,
						children: <div className="site-layout-background" style={{minHeight: 360}}>
							<div>
								<Button type="default"
								        style={{marginBottom: 15}}
								        onClick={createPull}
								>
									Создать пулл
								</Button>
							</div>
							<Table
								rowKey={'_id'}
								size="small"
								rowSelection={{
									type: 'checkbox',
									onChange: (selectedRowKeys, selectedRows) => {
										setSelectedItems(selectedRows.map(el=>el._id))
									},
									getCheckboxProps: (record) => (
										{
											disabled: !!record.pull,
											// Column configuration not to be checked
											// name: record.name,
											value: record._id,
										}),
								}}
								dataSource={items.wait}
								columns={columns}
								onChange={(page, filters, sorter, extra) => {
									setItemsPage(v => {
										const d = {...v}
										d.wait = page.current;
										console.log(d)
										return d
									})
									aisDataGet({
										page,
										filters,
										sorter,
										extra,
										tab: 'wait'
									});
								}}
								pagination={{
									pageSize,
									showSizeChanger : true,
									onShowSizeChange: (current, ps) => setPageSize(ps),
									total           : itemsCount.wait,
									current           : itemsPage.wait,
								}}
							/>
						</div>
					},
					{
						key     : 'inProgress',
						label   : <>В обработке <Badge count={itemsCount.inProgress} style={{
							backgroundColor: '#faad14',
						}}/></>,
						children: <div className="site-layout-background" style={{minHeight: 360}}>

							<Table
								rowKey={'_id'}
								size="small"
								dataSource={items.inProgress}
								columns={columns}
								onChange={(page, filters, sorter, extra) => {
									setItemsPage(v => {
										const d = {...v}
										d.inProgress = page.current;
										console.log(d)
										return d
									})
									aisDataGet({
										page,
										filters,
										sorter,
										extra,
										tab: 'inProgress'
									});
								}}
								pagination={{
									pageSize,
									showSizeChanger : true,
									onShowSizeChange: (current, ps) => setPageSize(ps),
									total           : itemsCount.inProgress,
									current           : itemsPage.inProgress,
								}}
							/>
						</div>
					},
					{
						key     : 'done',
						label   : <>Исполнен <Badge count={itemsCount.done} style={{
							backgroundColor: '#faad14',
						}}/></>,
						children: <div className="site-layout-background" style={{minHeight: 360}}>

							<Table
								rowKey={'_id'}
								size="small"
								dataSource={items.done}
								columns={columns}
								onChange={(page, filters, sorter, extra) => {
									setItemsPage(v => {
										const d = {...v}
										d.done = page.current;
										console.log(d)
										return d
									})
									aisDataGet({
										page,
										filters,
										sorter,
										extra,
										tab: 'done'
									});
								}}
								pagination={{
									pageSize,
									showSizeChanger : true,
									onShowSizeChange: (current, ps) => setPageSize(ps),
									total           : itemsCount.done,
									current           : itemsPage.done,
								}}
							/>
						</div>
					},
					{
						key     : 'canceled',
						label   : <>Отменен <Badge count={itemsCount.canceled} style={{
							backgroundColor: '#faad14',
						}}/></>,
						children: <div className="site-layout-background" style={{minHeight: 360}}>

							<Table
								rowKey={'_id'}
								size="small"
								dataSource={items.canceled}
								columns={columns}
								onChange={(page, filters, sorter, extra) => {
									setItemsPage(v => {
										const d = {...v}
										d.canceled = page.current;
										console.log(d)
										return d
									})
									aisDataGet({
										page,
										filters,
										sorter,
										extra,
										tab: 'canceled'
									});
								}}
								pagination={{
									pageSize,
									showSizeChanger : true,
									onShowSizeChange: (current, ps) => setPageSize(ps),
									total           : itemsCount.canceled,
									current           : itemsPage.canceled,
								}}
							/>
						</div>
					},
					{
						key     : 'rejected',
						label   : <>Отклонен <Badge count={itemsCount.rejected} style={{
							backgroundColor: '#faad14',
						}}/></>,
						children: <div className="site-layout-background" style={{minHeight: 360}}>

							<Table
								rowKey={'_id'}
								size="small"
								dataSource={items.rejected}
								columns={columns}
								onChange={(page, filters, sorter, extra) => {
									setItemsPage(v => {
										const d = {...v}
										d.rejected = page.current;
										console.log(d)
										return d
									})
									aisDataGet({
										page,
										filters,
										sorter,
										extra,
										tab: 'rejected'
									});
								}}
								pagination={{
									pageSize,
									showSizeChanger : true,
									onShowSizeChange: (current, ps) => setPageSize(ps),
									total           : itemsCount.rejected,
									current           : itemsPage.rejected,
								}}
							/>
						</div>
					},
					{
						key     : 'blocked',
						label   : <>Заблокирован <Badge count={itemsCount.blocked} style={{
							backgroundColor: '#faad14',
						}}/></>,
						children: <div className="site-layout-background" style={{minHeight: 360}}>

							<Table
								rowKey={'_id'}
								size="small"
								dataSource={items.blocked}
								columns={columns}
								onChange={(page, filters, sorter, extra) => {
									setItemsPage(v => {
										const d = {...v}
										d.blocked = page.current;
										console.log(d)
										return d
									})
									aisDataGet({
										page,
										filters,
										sorter,
										extra,
										tab: 'blocked'
									});
								}}
								pagination={{
									pageSize,
									showSizeChanger : true,
									onShowSizeChange: (current, ps) => setPageSize(ps),
									total           : itemsCount.blocked,
									current           : itemsPage.blocked,
								}}
							/>
						</div>
					},
					{
						key     : 'sentJNLC',
						label   : <>Отправлен JNLC <Badge count={itemsCount.sentJNLC} style={{
							backgroundColor: '#faad14',
						}}/></>,
						children: <div className="site-layout-background" style={{minHeight: 360}}>

							<Table
								rowKey={'_id'}
								size="small"
								dataSource={items.sentJNLC}
								columns={columns}
								onChange={(page, filters, sorter, extra) => {
									setItemsPage(v => {
										const d = {...v}
										d.sentJNLC = page.current;
										console.log(d)
										return d
									})
									aisDataGet({
										page,
										filters,
										sorter,
										extra,
										tab: 'sentJNLC'
									});
								}}
								pagination={{
									pageSize,
									showSizeChanger : true,
									onShowSizeChange: (current, ps) => setPageSize(ps),
									total           : itemsCount.sentJNLC,
									current           : itemsPage.sentJNLC,
								}}
							/>
						</div>
					},

				]}


				/>
				<Modal title={'Пулл '+(pullData?.pullId || '')}
				       visible={isModalVisible}
					// okText={'Сохранить и отправить'}
					// onOk={handleOk}
					   onCancel={handleCancel}
					   width={1250}
					   confirmLoading={load}
				       // cancelButtonProps={{ghost:true}}
				       // cancelText={''}
				       footer={null}
				>
					{ pullData?.status ==='wait' ?
						<Row justify="flex-start" align="middle" style={{margin: '16px 0'}}>
							<Col>
								<Button type="default"
								        style={{marginBottom: 15}}
								        onClick={addTransferRowToPullTable}
								>
									Добавить перевод в пулл
								</Button>
							</Col>
							<Col>
								<Button type="default"
								        style={{marginBottom: 15}}
								        onClick={checkComissions}
								>
									Расчитать комиссии
								</Button>
							</Col>
							<Col>
								<Button type="default"
								        style={{marginBottom: 15}}
								        onClick={createMTOs}
								>
									Отправить в Аис
								</Button>
							</Col>
						</Row>
						: <></>
					}
					<Table
						rowKey={'_id'}
						size="small"
						dataSource={pullItems}
						columns={columnsForEditPull}
						pagination={false}
						summary={(pageData) => {

							console.log(pageData)

							let totalAmount = 0;
							let totalOtherCommissions = 0;
							let totalBankComission = 0;
							let totalBankComissionKZT = 0;
							pageData.forEach((record) => {
								totalAmount += Number(record?.aisData?.vAmount || 0);
								totalOtherCommissions += Number(record?.aisData?.otherCommissions || 0);
								totalBankComission += Number(record?.aisData?.vBankComission || 0);
								totalBankComissionKZT += Number(record?.aisData?.vBankComissionKZT || 0);
							});
							return (
								<>
									<Table.Summary.Row>
										<Table.Summary.Cell index={0}>Итого</Table.Summary.Cell>
										<Table.Summary.Cell index={1}/>
										<Table.Summary.Cell index={2}/>
										<Table.Summary.Cell index={3}/>
										<Table.Summary.Cell index={4}/>
										<Table.Summary.Cell index={5}>
											<Text >${totalAmount.toFixed(2)}</Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={6}>
											<Text>{totalOtherCommissions.toFixed(2)}</Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={7}>
											<Text >${totalBankComission.toFixed(2)}</Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={8}>
											<Text>{totalBankComissionKZT.toFixed(2)}KZT</Text>
										</Table.Summary.Cell>
									</Table.Summary.Row>
								</>
							);
						}}
						/>
				</Modal>
				<PDFModal visible={modalFileVisible}
				          fileUrl={modalFileUrl}
				          fileData={{ext:'pdf'}}
				          onClose={() => setModalFileVisible(false)}
				/>
			</div>
		</>
	)
}

export default TransfersV2;
