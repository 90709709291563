import React from 'react';
import { Form, DatePicker, Select, Button } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

const OrdersForm = ({ onFinish }) => {
  return (
    <Form layout="vertical" onFinish={(values) => onFinish(values, 'orders')}>
      <Form.Item name="dateRange" label="Период">
        <RangePicker />
      </Form.Item>
      <Form.Item name="status" label="Статус ордера">
        <Select placeholder="Выберите статус">
          <Option value="open">Открыт</Option>
          <Option value="closed">Закрыт</Option>
          <Option value="cancelled">Отменён</Option>
          <Option value="filled">Исполнен</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Выгрузить отчет</Button>
      </Form.Item>
    </Form>
  );
};

export default OrdersForm;