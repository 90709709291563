import {Button, Menu, Spin, theme, Layout, Row, Col} from "antd";
import React, {useEffect, useState} from "react";
import {
  LoadingOutlined, MenuFoldOutlined, MenuUnfoldOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUsers, faMoneyBillTrendUp, faGaugeHigh, faFolder} from '@fortawesome/free-solid-svg-icons'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import userStore from "../components/Auth/userStore";
import {observer} from "mobx-react-lite";
import AppStore from "../components/App/AppStore";
import Utils from "../Utils";
import ImagePreview from "../components/ImagePreview";

const { Header, Sider, Content } = Layout;
const antIcon = <LoadingOutlined style={{fontSize: 24, color: '#e6f800'}} spin/>;

const AdminLayout = () => {
  const history = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [menuKey, setMenuKey] = useState('1');
  // const pathname = window.location.pathname;
  const { pathname } = useLocation();

  useEffect(()=>{
    if (pathname === '/') setMenuKey('1')
    if (/^\/usersProd/.test(pathname)) setMenuKey('1')
    if (/^\/usersTest/.test(pathname)) setMenuKey('2')
    if (/^\/tickers/.test(pathname)) setMenuKey('3')
    if (/^\/portfolios/.test(pathname)) setMenuKey('4')
    // if (/^\/logs/.test(pathname)) setMenuKey('3')
    // if (/^\/comments/.test(pathname)) setMenuKey('4')

  },[pathname])

  useEffect(()=>{
    userStore.authCheck().catch()
  },[])

  if (userStore.auth === null)
    return (
      <div className="example" style={{
        margin      : '20px 0',
        marginBottom: '20px',
        padding     : '30px 50px',
        textAlign   : 'center'
      }}>
        <Spin indicator={antIcon}/>
      </div>
    );

  if (userStore.auth === false){
    window.location.href = `https://${Utils.apiDomain()}/login`
    return null;
  }

  return <Layout style={{height: AppStore.height}}>
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[menuKey]}
        items={[
          // {
          //   key: '1',
          //   icon: <FontAwesomeIcon icon={faGaugeHigh} />,
          //   label: 'Главная',
          //   onClick: () => {
          //     history('/');
          //   }
          // },
          {
            key: '1',
            // icon: <FontAwesomeIcon icon={faUsers} />,
            label: 'Пользователи Prod',
            onClick: () => {
              history('/usersProd');
            }
          },
          {
            key: '2',
            label: "Пользователи Test",
            onClick: () => {
              history('/usersTest');
            }
          },
          {
            key: '3',
            // icon: <FontAwesomeIcon icon={faMoneyBillTrendUp} /> ,
            label: 'Тикеры',
            onClick: () => {
              history('/tickers');
            }
          },
          {
            key: '4',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Портфолио',
            onClick: () => {
              history('/portfolios');
            }
          },
          // {
          //   key: '5',
          //   // icon: <FontAwesomeIcon icon={faFolder} /> ,
          //   label: 'Пополнение средств (Старое)',
          //   onClick: () => {
          //     history('/accrual');
          //   }
          // },
          {
            key: '5',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Пополнение средств Prod',
            onClick: () => {
              history('/accrualProd');
            }
          },
          {
            key: '6',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Пополнение средств Test',
            onClick: () => {
              history('/accrualTest');
            }
          },
          {
            key: '7',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Переводы Prod',
            onClick: () => {
              history('/transfersProd');
            }
          },
          {
            key: '8',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Переводы Test',
            onClick: () => {
              history('/transfersTest');
            }
          },
          {
            key: '9',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Пополнения Prod',
            onClick: () => {
              history('/depositsProd');
            }
          },
          {
            key: '10',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Пополнения Test',
            onClick: () => {
              history('/depositsTest');
            }
          },
            {
                key: '11',
                // icon: <FontAwesomeIcon icon={faFolder} /> ,
                label: 'Ордера Prod',
                onClick: () => {
                    history('/ordersProd');
                }

            },
          {
            key: '12',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Ордера Test',
            onClick: () => {
              history('/ordersTest');
            }
          },
          {
            key: '13',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Транзакции',
            onClick: () => {
              history('/transactions');
            }
          },
          {
            key: '14',
            // icon: <FontAwesomeIcon icon={faFolder} /> ,
            label: 'Перевод новостей',
            onClick: () => {
              history('/newsTranslate');
            }
          },
          {
            key: 15,
            label: 'Отчеты',
            onClick: () => {
              history('/reports')
            }
          },
          {
            key: 16,
            label: 'Переводы V2',
            onClick: () => {
              history('/transfersV2')
            }
          },
          {
            key: 17,
            label: 'Пуллы',
            onClick: () => {
              history('/transferPulls')
            }
          }
        ]}
      />
    </Sider>
    <Layout>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <Row>
          <Col flex={'64px'}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          </Col>
          <Col flex={'auto'} style={{textAlign: "right"}}>
            <div style={{
              fontSize: '16px',
              display: "inline-block",
              marginRight: 20
            }}>
              <Row>
                <Col flex={'64px'}>
                  <ImagePreview src={`https://${Utils.apiDomain()}/rest_api/admin/documentFile/${userStore.userData?.profileData?.avatar}`}
                                width={40} height={40}
                                style={{borderRadius: 50, margin: 12}}
                  />
                </Col>
                <Col flex={'auto'} style={{
                  display: "flex",
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                  height: 64,
                  lineHeight: '31px',
                  padding: '5px 0'
                }}>
                  {userStore.userData?.profileData?.name && <>
                    <div style={{height: 22}}>{userStore.userData?.profileData?.name}</div>
                    <div style={{height: 31, fontSize: 12, color: 'rgb(136, 136, 136)'}}>
                      {userStore.userData?.email}
                    </div>
                  </>}
                  {!userStore.userData?.profileData?.name || userStore.userData?.profileData?.name === '' && <>
                    <div style={{height: 22}}>{userStore.userData?.email}2</div>
                  </>}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          borderTop: '1px solid #333',
          padding: '0 24px 24px',
          background: colorBgContainer,
          height: AppStore.height,
          overflowY: "auto"
        }}
      >
        <Outlet/>
      </Content>
    </Layout>
  </Layout>
}

export default observer(AdminLayout);