import { configure, makeAutoObservable } from "mobx";

configure({ enforceActions: "never" });

class AppStore {
  width = 0;
  height = 0;
  lang = localStorage.getItem("appLang")
    ? localStorage.getItem("appLang")
    : "ru";

  notification = false;

  constructor() {
    makeAutoObservable(this);
  }

  resize(width, height) {
    this.width = width;
    this.height = height;
  }

  disableScroll () {
    document.body.classList.add('scroll-disable')
  }

  enableScroll () {
    document.body.classList.remove('scroll-disable')
  }

  setLang(value) {
    this.lang = value;
    localStorage.setItem("appLang", value);
  }
  setNotificvisible(v) {
    this.notification = v;
  }

}

const clsAppStore = new AppStore();
export default clsAppStore;
